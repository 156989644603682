import React, { useEffect, useContext } from 'react';
import { StyleSheet } from 'react-native'

import { Context as AuthContext } from '../context/AuthContext';

import Loading from '../components/Loading'

const ResolveAuthScreen = ( { navigation } ) => {

  const { tryAlreadySignin } = useContext(AuthContext);

  useEffect(() => {

    try {
      tryAlreadySignin(navigation);
    } catch(e) {
      console.error("Error when tryng sign ", e)
    }
  }, []);

  return <Loading/>


};

const styles = StyleSheet.create({ })

export default ResolveAuthScreen;