import AsyncStorage from '@react-native-async-storage/async-storage';

import createDataContext from './createDataContext';

const themeReducer = (state, action) => {
    
    switch (action.type) {
        case 'set_theme':

            state = action.payload

            // A new object must be created, to triger rerendering
            return state

        default:
            return state;
    }
}

const setTheme = dispatch => theme => {

    // Do NOT use await, dispatch function must return a value, not a promise
    AsyncStorage.setItem('@e-noa.ro-theme', theme)

    dispatch({ type: 'set_theme', payload: theme })
}

export const { Provider, Context } = createDataContext(
    themeReducer,
    { setTheme },
    'light' 
);


