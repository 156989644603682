import AsyncStorage from '@react-native-async-storage/async-storage';

const getLanguageFromAsyncStorage = async defaultLanguage => {

    const value = await AsyncStorage.getItem('@e-noa.ro-language')

    console.log("Language: " + value)

    if(value !== null) {

      return value
    } 

    return defaultLanguage
}

export { getLanguageFromAsyncStorage }