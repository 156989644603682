import React, { useContext, useState, useRef, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, Text, FormControl, Input, Checkbox } from 'native-base'

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as GroupsContext } from '../../context/GroupsContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

import Spacer from '../Spacer';
import { applyGroupsFilters, validateGroup, validateGroupName } from '../../api/groups';
import { hasOwnProperties } from '../../api/object';
import { capitalizeFirstLetterFromWords } from '../../api/string';

const i18n = new I18n({
    ...AppTranslations
  });
  
i18n.enableFallback = true;

const GroupNameFilter = () => {

    const authCtx = useContext(AuthContext)
    const groupsCtx = useContext(GroupsContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [errors, setErrors] = useState({})
    const groupNameInputRef = useRef()

    return <Box>
        
        <FormControl isInvalid={'name' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('name')}
            </FormControl.Label>
            <Input placeholder={i18n.t('name')} 
                value={groupsCtx?.state?.filters?.name}                
                ref={groupNameInputRef}
                onSubmitEditing={() => {
                    applyGroupsFilters(groupsCtx)
                    groupNameInputRef.current.focus()
                }}
                onChangeText={value => {

                    const crtErrors = validateGroupName(value)

                    if (hasOwnProperties(errors)) {

                        setErrors({
                            ...errors,
                            ...crtErrors
                        })
                    } else {

                        delete errors.name
                        setErrors(errors)
                    }

                    groupsCtx.setFilters(
                        {
                            ...groupsCtx.state.filters, 
                            name: capitalizeFirstLetterFromWords(value)
                        }
                    )
                    
                }}
            />
            
            {
                'name' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.name) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgNameIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>


        <FormControl>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('msgOnlyDefaultGroups')}
            </FormControl.Label>
            <Checkbox.Group 
                value={groupsCtx?.state?.filters?.defaultGroups?.length ? groupsCtx?.state?.filters?.defaultGroups : []}
                 
                onChange={values => {

                    groupsCtx.setFilters({
                        ...groupsCtx.state.filters,
                        defaultGroups: values?.length === 2 ? [values[1]] : values
                    })
                }}
                aria-labelledby='defaultGroups-Group'
            >
                <Checkbox size="sm" value="onlyDefaultGroups" aria-labelledby='onlyDefaultGroups' 
                    // onChange={value => {
                    //     groupsCtx.setFilters({
                    //         ...groupsCtx.state.filters,
                    //         defaultGroups: value ? ['onlyDefaultGroups'] : []
                    //     })
                    // }}
                >
                    {i18n.t('msgOnlyDefaultGroups')}
                </Checkbox>
                <Checkbox size="sm" value="onlyNonDefaultGroups" aria-labelledby='onlyNonDefaultGroups' 
                    // onChange={value => {
                    //     groupsCtx.setFilters({
                    //         ...groupsCtx.state.filters,
                    //         defaultGroups: value ? ['onlyNonDefaultGroups'] : []
                    //     })
                    // }}
                >
                    {i18n.t('msgOnlyNonDefaultGroups')}
                </Checkbox>
            </Checkbox.Group>
        </FormControl>
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        borderWidth: 1
    },
    backgroundImage: {
        flex: 1
    }
})

export default GroupNameFilter;