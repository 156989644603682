import React, { useContext } from 'react'
import { Box, Button, useColorModeValue, useSafeArea } from 'native-base'
import { StackActions } from '@react-navigation/routers'

import LanguagePicker from '../components/LanguagePicker'
import ThemePicker from '../components/ThemePicker';
import Spacer from '../components/Spacer';

import { Context as LanguageContext } from '../context/LanguageContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
// Set the key-value pairs for the different languages you want to support.

const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const SettingsScreen = ( { navigation } ) => {

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const safeAreaProps = useSafeArea({
        safeArea: true,
        pt: 2
    });
    
    return (
        <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
            
            {/* <Text>Settings Screen</Text> */}

            <LanguagePicker/>
            <Spacer/>

            <ThemePicker/>
            <Spacer/> 

            <Button onPress={() =>navigation.dispatch(StackActions.pop(1))}>{i18n.t('back')}</Button>
        </Box>
    )
}

export default SettingsScreen