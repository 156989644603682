import React, { useContext } from 'react';
import { ActivityIndicator, ImageBackground, StyleSheet } from 'react-native';
import { Flex, Box, Text, Image, Button } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as RecaptchaContext } from '../context/RecaptchaContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
import { revokeAllSessions } from '../api/signout';
import { cloudLogger } from '../api/logger';

// Set the key-value pairs for the different languages you want to support.

const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const AppHeader = ({ hideSettings, hideSignout, navigation }) => {

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const authCtx = useContext(AuthContext)

    const recaptchaCtx = useContext(RecaptchaContext)

    return (
        <Flex flexDirection="row" alignItems="center">

            <Image size="xs" source={require("../../assets/icon.jpeg")} alt="e-noa.ro"></Image>

            <Box width="2"></Box>
            
            <Text color="#000000">e-noa.ro</Text>

            {!hideSettings ? <Button onPress={() => {
                navigation.dispatch(StackActions.push("Settings"))
            }}>
                {i18n.t('settings')}
            </Button> : null}

            {!hideSignout ? <Box style={styles.signoutContainer}>
                <Button onPress={ () => {

                    cloudLogger('info', 'signout ' + JSON.stringify(authCtx.state))

                    recaptchaCtx.setToken(null)
                    authCtx.signout()
                }}>
                    {i18n.t('signout')}
                </Button>
                <Button onPress={ () => {

                    cloudLogger('info', 'signout from all devices ' + JSON.stringify(authCtx.state))

                    revokeAllSessions()

                    recaptchaCtx.setToken(null)
                    authCtx.signout()
                }}>
                    {i18n.t('signoutFromAllDevices')}
                </Button> 
            </Box>
            : null}

        </Flex>
    )
}
const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#ffffff"
    },
    signoutContainer: {
        flexDirection: 'row'
    }
})

export default AppHeader;