
import createDataContext from './createDataContext';

const recaptchaReducer = (state, action) => {
    
    switch (action.type) {
        case 'set_token':

            state = action.payload

            // A new object must be created, to triger rerendering
            return state

        default:
            return state;
    }
}

const setToken = dispatch => token => {

    dispatch({ type: 'set_token', payload: token })
}

export const { Provider, Context } = createDataContext(
    recaptchaReducer,
    { setToken },
    null
);


