import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

const revokeAllSessions = async () => {

    // Region must be specified
    const functions = getFunctions(getApp(), 'europe-west3')

    const callRevokeAllSessions = httpsCallable(
        functions, 
        'revokeAllSessions', 
        {
            limitedUseAppCheckTokens: true
        } 
    )

    try {

        const result = await callRevokeAllSessions()

        return result.data

    } catch (err) {

        return false
    }
}

export { revokeAllSessions }