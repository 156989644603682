const hasOwnProperties = obj => {

    for(var prop in obj) {
        
        if (obj.hasOwnProperty(prop)) {

            return true
        }
    }

    return false
}

const getOwnPropertiesAsArray = obj => {

    return Object.entries(obj).reduce((acc, crt) => {

        return acc.concat([crt[0]])
    }, [])
}

const isObjectEqualByFields = (ref, obj, props) => {

    if (!ref || !obj) {

        return false
    }

    return props.reduce((acc, crtProp) => acc && ref[crtProp] === obj[crtProp], true)
}

export { hasOwnProperties, getOwnPropertiesAsArray, isObjectEqualByFields }