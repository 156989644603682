const sendEmailToGroups = async groupsIds => {

    // TODO Compute unique members
    console.log("Send Email to groups ids: ", groupsIds)
}

const sendSMSToGroups = async groupsIds => {

    // TODO Compute unique members
    console.log("Send SMS to groups ids: ", groupsIds)
}

const sendEmailToMembers = async membersIds => {

    console.log("Send Email to members ids: ", membersIds)
}

const sendSMSToMembers = async membersIds => {

    console.log("Send SMS to members ids: ", membersIds)
}

export {sendEmailToGroups, sendEmailToMembers, sendSMSToGroups, sendSMSToMembers}
