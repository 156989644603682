import { query, where, getDocs, getDoc, collection, getFirestore, updateDoc, doc, deleteField, deleteDoc, getCountFromServer, writeBatch, limit, orderBy, startAt, endAt, startAfter } from "firebase/firestore"

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

import { existsFieldValuesInCollection } from "./firestore";

import { hasOwnProperties, isObjectEqualByFields } from './object'
import { nextLexicographicString } from "./string";
import { fetchMemberByMemberId } from "./members";
import { cloudLogger } from "./logger";


const checkGroupUniqueFields = async (group, initialGroup) => {

    let errors = {}, res;

    if (hasOwnProperties(group)) {

        if (group.name !== initialGroup.name) {
    
            res = await existsFieldValuesInCollection('groups', ['name'], group)

            if (res === true ) {

                errors = {
                    ...errors,
                    name: "msgExistsInDatabase" 
                }
            }
        }
    }

    return errors
}

const createFirestoreGroup = async group => {

    try {
        const firestore = getFirestore()
        const batch = writeBatch(firestore)

        const groupRef = doc(collection(firestore, 'groups'))
        batch.set(groupRef, {
            ...group,
            id: groupRef.id
        })

        const uniqueKeyGroupRef = doc(firestore, 'unique_keys', 'groups', 'name', group.name)
        batch.set(uniqueKeyGroupRef, {
            value: groupRef.id
        })

        await batch.commit()
    } catch(error) {

        console.log("Create Group Errors: ", error)

        return error
    }

    console.log("Create Group Success")

    cloudLogger('info', `Group: ${JSON.stringify(group)} created`)

    return true
}

const updateFirestoreGroup = async group => {

    try {
        const firestore = getFirestore()
        const batch = writeBatch(firestore)

        const groupRef = doc(collection(firestore, 'groups'), group.id)
        const groupPrevData = await getDoc(groupRef).then(groupSnapshot => groupSnapshot.exists() ? groupSnapshot.data() : null)

        if (!groupPrevData) {

            return "No group found"
        }
        
        batch.set(groupRef, group)

        if (group.name !== groupPrevData.name) {

            const prevUniqueKeyGroupRef = doc(collection(firestore, 'unique_keys'),`groups/name/${groupPrevData.name}`)
            const uniqueKeyGroupRef = doc(collection(firestore, 'unique_keys'), `groups/name/${group.name}`)

            batch.delete(prevUniqueKeyGroupRef)
            batch.set(uniqueKeyGroupRef, {
                value: groupRef.id
            })
        }       

        await batch.commit()

    } catch(error) {

        console.log("Update Group Error: ", error)

        return error
    }

    console.log("Update Group Success")

    cloudLogger('info', `Group: ${JSON.stringify(group)} updated`)

    return true
}

const deleteMembersGroupsForGroupId = async (groupId, batch) => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, "members_groups")
    )
    q = query(
        q,
        where(
            "groupId",
            "==",
            groupId
        )
    )

    const snapshot = await getDocs(q)

    if (snapshot.empty) {
        
        // It should not be here, 
        // all members are in at least 2 groups
        return null
    }


    snapshot.forEach(doc => {

        batch.delete(doc)
    })        
}



const deleteFirestoreGroup = async groupId => {

    try {
        const firestore = getFirestore()
        const batch = writeBatch(firestore)

        const groupRef = doc(collection(firestore, 'groups'), groupId)
        const groupData = await getDoc(groupRef).then(groupSnapshot => groupSnapshot.exists() ? groupSnapshot.data() : null)

        if (!groupData) {

            return "No group found"
        }

        batch.delete(groupRef)

        const uniqueKeyGroupRef = doc(collection(firestore, 'unique_keys'),`groups/name/${groupData.name}`)
        batch.delete(uniqueKeyGroupRef)

        await deleteMembersGroupsForGroupId(groupId, batch)

        await batch.commit()

        cloudLogger('info', `Group: ${JSON.stringify(groupData)} deleted`)

    } catch(error) {

        console.log("Delete Group Error: ", error)

        return {
            error
        }

    }

    console.log("Success")

    return true
}

const fetchGroupByGroupId = async groupId => {

    const firestore = getFirestore()

    return getDoc(doc(firestore, 'groups', groupId))
        .then(groupSnapshot => groupSnapshot.exists() ? groupSnapshot.data() : {})
}

const getDefaultGroup = () => {

    return {
        name: "",
        default: false,
        iterable: true
    }
}

const isGroupEqual = (initialGroup, group) => {

    return isObjectEqualByFields(
        initialGroup, 
        group, 
        ['name']
    )
}

const validateGroupName = groupName => {

    if (!groupName) {

        return {
            name: 'msgNameNotFilledIn'
        }
    }

    // if (groupName.length < 3) {

    //     return {
    //         name: 'msgNameTooShort'
    //     }
    // }

    // if (/^[A-Za-z\s]*$/.test(groupName) === false) {

    //     return {
    //         name: 'msgNameMustContainOnlyLettersAndSpaces'
    //     }
    // }

    return {}
}

const validateGroup =  (group, setErrors) => {

    let errors = {}

    if (group) {

        errors = {
            ...errors,
            ...validateGroupName(group.name)
        }
    }

    setErrors(errors)

    return !hasOwnProperties(errors)
}

const filterGroups = async (filters, limitNumber, direction, itemRef) => {

    //console.log("Groups filters: ", JSON.stringify(filters))

    try {

        const firestore = getFirestore()

        let q =  query(
            collection(firestore, "groups"),
        )

        // By default order by name
        if (!hasOwnProperties(filters)) {
            filters = {
                name: "",
                defaultGroups: []
            }
        }

        for (const [filterName, filterValue] of Object.entries(filters)) {

            if (filterName === "name") {

                if (filterValue) {

                    const nextValue = nextLexicographicString(filterValue)

                    q = query(
                        q,
                        where(
                            filterName,
                            ">=",
                            filterValue.trim()
                        ),
                        where(
                            filterName,
                            "<",
                            nextValue.trim()
                        )
                    )
                }
            }

            if (filterName === 'defaultGroups') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "default",
                            "==",
                            filterValue[0] === "onlyDefaultGroups" ? true : false
                        )
                    )
                }
            }

            if (filterName === 'abbreviation') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "abbreviation",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'type') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "type",
                            "==",
                            filterValue
                        )
                    )
                }
            }
        }

        const cnt = await getCountFromServer(q)
        console.log("Count: ", cnt.data().count)

        if (direction !== "" && itemRef && itemRef.name && itemRef.id) {

            q = query(
                q, 
                orderBy("name", direction === "next" ? "asc" : "desc"),
                //orderBy("id", direction === "next" ? "asc" : "desc"),
                startAfter(itemRef.name)//, itemRef.id)
            )
        } else {

            q = query(
                q, 
                orderBy("name", "asc"),
                //orderBy("id", "asc")
            )
        }


        const snapshot = await getDocs(
            query(
                q,
                limit(limitNumber)
            )
        )

        if (snapshot.empty) {
            
            return {
                data: [],
                count: 0
            }
        }

        const result = snapshot.docs.map(doc => {

            console.log("Group: ", doc.data())

            return doc.data()
        })

        return {
            data: result,
            count: cnt.data().count
        }
    } catch(error) {

        console.error("Error: ", error)

        return {
            data: [],
            count: 0,
            error
        }
    }
}

const addMembersToGroup  = async (membersIds, groupId) => {

    const firestore = getFirestore()
    const batch = writeBatch(firestore)

    if (!groupId) {

        return false
    }

    try {
    
        membersIds.forEach(memberId => {

            if (!memberId) {
                
                return
            }

            const membersGroupsRef = doc(collection(firestore, 'members_groups'), `${memberId}-${groupId}`)

            batch.set(membersGroupsRef, {
                memberId,
                groupId
            })
        })

        await batch.commit()

        cloudLogger('info', `Members ${membersIds} added to Groups ${groupId}`)

        return true
    } catch (error) {

        console.log("Error: ", error)

        return false
    }
}

const fetchMembersIdsForGroupId = async groupId => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, "members_groups"),
    )

    try {

        q = query(
            q,
            where(
                "groupId",
                "==",
                groupId
            )
        )


        const snapshot = await getDocs(
            query(
                q
            )
        )

        if (snapshot.empty) {
            
            return []
        }

        const result = snapshot.docs.map(doc => {

            console.log("Group Member: ", doc.data())

            return doc.data()
        })

        return result.map(gm => gm.memberId)
    } catch (error) {

        console.log("Error while fetching groupId: ", groupId, " members")
        
        return []
    }
}

const fetchMembersForGroupId = async groupId => {

    try {

        const result = await fetchMembersIdsForGroupId(groupId)

        return Promise.all(result.map(memberId => fetchMemberByMemberId(memberId)))
    } catch (error) {

        console.log("Error while fetching groupId: ", groupId, " members")
        
        return []
    }
}


const addGroupsToMember  = async (memberId, groupsIds) => {

    if (!memberId) {

        return false
    }

    const firestore = getFirestore()
    const batch = writeBatch(firestore)

    try {
        

        groupsIds.forEach(groupId => {

            if (!groupId) {

                return
            }

            const membersGroupsRef = doc(collection(firestore, 'members_groups'), `${memberId}-${groupId}`)

            batch.set(membersGroupsRef, {
                memberId,
                groupId
            })
        })

        await batch.commit()

        cloudLogger('info', `Member ${memberId} added to Groups ${groupsIds}`)

        return true
    } catch (error) {

        console.log("Error: ", error)

        return false
    }
}


const fetchGroupsIdsForMemberId = async memberId => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, "members_groups"),
    )

    try {

        q = query(
            q,
            where(
                "memberId",
                "==",
                memberId
            )
        )


        const snapshot = await getDocs(
            query(
                q
            )
        )

        if (snapshot.empty) {
            
            return []
        }

        const result = snapshot.docs.map(doc => {

            console.log("Group Member: ", doc.data())

            return doc.data()
        })

        return result.map(gm => gm.groupId)
    } catch (error) {

        console.log("Error while fetching memberId: ", memberId, " groups ids")
        
        return []
    }
}

const fetchGroupsForMemberId = async memberId => {


    try {

        const result = await fetchGroupsIdsForMemberId(memberId)

        return Promise.all(result.map(groupId => fetchGroupByGroupId(groupId)))
    } catch (error) {

        console.log("Error while fetching memberId: ", memberId, " groups")
        
        return []
    }
}

const removeMemberFromGroup = async (groupId, memberId) => {

    const firestore = getFirestore()

    const memberGroupRef = doc(collection(firestore, "members_groups"), `${memberId}-${groupId}`)

    await deleteDoc(memberGroupRef)

    return true
}

const applyGroupsFilters = async (groupsCtx, direction) => {

    if (!groupsCtx) {

        return
    }

    cloudLogger('info', 'Apply filters: ' + JSON.stringify(groupsCtx?.state?.filters))
    
    let newFilteredGroups
    
    if (!direction) {

        newFilteredGroups= await filterGroups(
            groupsCtx.state.filters, 
            groupsCtx.state.pageSize
        )
    } else {

        newFilteredGroups= await filterGroups(
            groupsCtx.state.filters, 
            groupsCtx.state.pageSize, 
            direction,
            groupsCtx.state.filteredGroups[
                direction === 'next' ? groupsCtx.state.filteredGroups.length - 1 : 0
            ]
        )
    }

    if (!newFilteredGroups || newFilteredGroups.error) {

        groupsCtx.setCurrentPage(1)
        groupsCtx.setFilteredGroups([])
        groupsCtx.setError(!newFilteredGroups && !newFilteredGroups.error ? "msgUnkonwnError" : newFilteredGroups.error)

        return
    }

    groupsCtx.setFilteredGroupsCount(newFilteredGroups.count)

    if (direction !== 'previous') {

        groupsCtx.setFilteredGroups(newFilteredGroups.data)
        
    } else {

        groupsCtx.setFilteredGroups(newFilteredGroups.data.reverse())
    }

    if (direction === undefined) {

        groupsCtx.setCurrentPage(1)
    }

    if (direction === 'next') {
    
        groupsCtx.setCurrentPage(groupsCtx.state.currentPage + 1)
    }

    if (direction === 'previous') {

        groupsCtx.setCurrentPage(groupsCtx.state.currentPage - 1)
    }
}

export { 
    createFirestoreGroup, 
    updateFirestoreGroup, 
    deleteFirestoreGroup,
    fetchGroupByGroupId,
    getDefaultGroup,
    validateGroup, 
    validateGroupName,
    isGroupEqual,
    checkGroupUniqueFields,
    filterGroups,
    applyGroupsFilters,
    addMembersToGroup,
    fetchMembersForGroupId,
    fetchMembersIdsForGroupId,
    addGroupsToMember,
    fetchGroupsIdsForMemberId,
    fetchGroupsForMemberId,
    removeMemberFromGroup
}