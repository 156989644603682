import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, Text, FormControl, Input, FlatList, Select } from 'native-base'

import { filterLocalitiesArrayByPrefix, fetchLocalitiesFromCounty } from '../../api/localities';
import { fetchFirestoreCountiesNames } from '../../api/counties';

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as MembersContext } from '../../context/MembersContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

import { hasOwnProperties } from '../../api/object';
import { fetchFunctions } from '../../api/members';

const i18n = new I18n({
    ...AppTranslations
  });
  
i18n.enableFallback = true;

const MemberFunctionFilter = () => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [errors, setErrors] = useState({})
    const [enoaFunctions, setEnoaFunctions] = useState([])


    const [counties, setCounties] = useState([])
    const [localities, setLocalities] = useState({})

    const [inputLocalityText, setInputLocalityText] = useState('')
    const [showLocalityList, setShowLocalityList] = useState(false)

    useEffect(() => {

        (async() => {
            setCounties(await fetchFirestoreCountiesNames())
        })()
    }, [])


    useEffect(() => {
        (async() => {

            if (!membersCtx?.state?.filters?.county || localities[membersCtx.state.filters.county]) {

                return
            }

            setLocalities({
                ...localities,
                [membersCtx.state.filters.county]: await fetchLocalitiesFromCounty(membersCtx.state.filters.county)
            })

        })()
    }, [membersCtx?.state?.filters?.county])

    const filteredLocalities = useMemo(() => filterLocalitiesArrayByPrefix(
        localities[membersCtx.state.filters.county],
        inputLocalityText
    ), [membersCtx.state.filters.county, inputLocalityText])

    useEffect(() => {

        (async() => {
            setEnoaFunctions(await fetchFunctions())      
        })()
    }, [])
    
    return <Box>

        {/* <Text>Errors: {JSON.stringify(errors)} </Text> */}
        
        <FormControl isInvalid={'function' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('function')}
            </FormControl.Label>
            
            <Select selectedValue={membersCtx.state.filters.function} 
                placeholder={i18n.t('function')}
                onValueChange={ (enoaFunction) => {
                   
                    membersCtx.setFilters({
                        ...membersCtx.state.filters,
                        function: enoaFunction
                    })
                }}
            >
                {
                    
                    enoaFunctions.map((enoaFunction, enoaFunctionIndex) => (
                        <Select.Item key={enoaFunctionIndex} label={enoaFunction ? i18n.t(enoaFunction) : ""} value={enoaFunction} />
                    ))
                    
                }
            </Select>
            
            {
                'function' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.function) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgFunctionIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isInvalid={'county' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('county')}
            </FormControl.Label>

            <Select selectedValue={membersCtx?.state?.filters?.county} 
                placeholder={i18n.t('county')}
                onValueChange={ county => {                   
                    membersCtx.setFilters({
                        ...membersCtx?.state?.filters,
                        county,
                        locality: county === '' ? '' : membersCtx.state.filters.locality
                    })
                    setInputLocalityText('')
                }}
            >
                {                    

                    [''].concat(counties).map((county, countyIndex) => (
                        <Select.Item key={countyIndex} label={county} value={county} />
                    ))                    
                }
            </Select>
            
            {
                // county is optional in filters, it must not be in errors
                'county' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.county) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgCountyIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl  isInvalid={'locality' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('locality')}
            </FormControl.Label>
            
            <Input value={inputLocalityText}
                isDisabled={!membersCtx?.state?.filters?.county}
                onChangeText={value => {
                    setInputLocalityText(value)

                    if (filteredLocalities.includes(value)) {

                        membersCtx.setFilters({
                            ...membersCtx.state.filters,
                            locality: value
                        })
                    } else {

                        membersCtx.setFilters({
                            ...membersCtx.state.filters,
                            locality: ""
                        })
                    }

                    setShowLocalityList(true)
                }} 
                onFocus={() => {

                    setInputLocalityText("")
                    setShowLocalityList(true)
                }}
                placeholder={i18n.t('locality')}
            />

            {
                membersCtx?.state?.filters?.county && filteredLocalities && filteredLocalities.length && showLocalityList ?
                    <FlatList data={[''].concat(filteredLocalities)}
                        renderItem={({item, index}) => (
                            <Text onPress={() => {
                                membersCtx.setFilters({
                                    ...membersCtx.state.filters,
                                    locality: item
                                })
                                setInputLocalityText(item)
                                setShowLocalityList(false)
                            }}>
                                {item ? item : ' '}
                            </Text>
                        )} 
                        keyExtractor={item => item}
                    /> : 
                    null
            }
            
            {
                // locality is optional in filters, it must not be in errors
                'locality' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.locality) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgLocalityIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        borderWidth: 1
    },
    backgroundImage: {
        flex: 1
    }
})

export default MemberFunctionFilter;