import {  query, where, getDocs, getDoc, collection, getFirestore, updateDoc, doc, deleteField, getCountFromServer, writeBatch, limit, orderBy, startAt, endAt, startAfter } from "firebase/firestore"

import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

import { isNormalizedPhoneNumber, isValidPhoneNumber, normalizePhoneNumber } from "./signin/phone"
import { isValidEmailAddress } from "./signin/emailLink"

import { existsFieldValuesInCollection  } from "./firestore";
import { isObjectEqualByFields, hasOwnProperties, getOwnPropertiesAsArray } from "./object";

import { fetchFirestoreCounties, fetchFirestoreCountyByName } from "./counties";
import { nextLexicographicString } from "./string";
import { fetchFirestoreFunctionByName } from "./functions";
import { filterGroups } from "./groups";
import { cloudLogger } from "./logger";

const fetchMemberByFirebaseUid = async firebaseUid => {

    const firestore = getFirestore()

    try {
        
        return await getDoc(doc(collection(firestore, 'firebase_members'), firebaseUid)).then(
            firebaseMembersSnapshot => {

                if (!firebaseMembersSnapshot.exists()) {

                    return null
                }

                const memberId = firebaseMembersSnapshot.data().memberId

                return getDoc(doc(collection(firestore, 'members'), memberId)).then(
                    memberSnapshot => memberSnapshot.exists() ? memberSnapshot.data() : null
                )
            }
        )
    } catch (error) {

        console.log(error)

        return null
    }

    /*let q =  query(
        collection(firestore, "members")
    )
    q = query(
        q,
        where(
            authDataType,
            "==",
            authData
        )
    )

    try {

        const snapshot = await getDocs(q)

        if (snapshot.empty) {
            
            return null
        }

        const result = new Promise((resolve, reject) => {

            snapshot.forEach(doc => {

                resolve(doc.data())
            })        
        })

        return result
    } catch(error) {

        return null
    }*/
}

const fetchSpecializations = async () => {

    const firestore = getFirestore()

    return getDocs(collection(firestore, "static/members/specializations")).then(specializationsSnapshot => Promise.all(
        specializationsSnapshot.docs.map(specializationDoc => specializationDoc.data().name)
    ))
}

const fetchFunctions = async () => {

    const firestore = getFirestore()

    return getDocs(collection(firestore, "static/members/functions")).then(functionsSnapshot => Promise.all(
        functionsSnapshot.docs.map(functionDoc => functionDoc.data().name)
    ))
}

const fetchRoles = async () => {

    const firestore = getFirestore()

    return getDocs(collection(firestore, "static/app/roles")).then(rolesSnapshot => Promise.all(
        rolesSnapshot.docs.map(roleDoc => roleDoc.data().name)
    ))
}

const fetchMonthlyPayments = async () => {

    const firestore = getFirestore()

    return getDocs(collection(firestore, "static/payments/monthlyPayments")).then(monthlyPaymentsSnapshot => Promise.all(
        monthlyPaymentsSnapshot.docs.map(monthlyPaymentDoc => monthlyPaymentDoc.data().amount)
    ))
}


const fetchMemberByMemberId = async memberId => {

    const firestore = getFirestore()

    return getDoc(doc(firestore, 'members', memberId))
        .then(memberSnapshot => memberSnapshot.exists() ? memberSnapshot.data() : {})
}

const fetchNextRegistrationNumber = async county => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, "members")
    )
    q = query(
        q,
        where(
            'county',
            "==",
            county
        ), 
        orderBy("registrationNumber", "desc"),
        limit(1)
    )

    const counties = await fetchFirestoreCounties()

    const selectedCounty = counties.filter(c => c.name === county)

    if (selectedCounty.length !== 1) {

        throw "Unable to fetch counties"
    }

    try {

        const snapshot = await getDocs(q)

        if (snapshot.empty) {
            
            return selectedCounty[0].id + "-00001"
        }

        const leadingZeros = (num, size) => {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        }

        const result = new Promise((resolve, reject) => {

            snapshot.forEach(doc => {

                const nextIndex = parseInt(doc.data().registrationNumber.substr(3)) + 1
                resolve(selectedCounty[0].id + "-" + leadingZeros(nextIndex, 5))
            })        
        })

        return result
    } catch(error) {

        console.error(error)

        return null
    }
}

const computeNextPayDate = () => {
    
    const nextPayDate = new Date()
    nextPayDate.setMonth(nextPayDate.getDate() > 10 ? nextPayDate.getMonth() + 1 : nextPayDate.getMonth())
    nextPayDate.setDate(nextPayDate.getDate() > 10 ? Math.max(1, nextPayDate.getDate() - 15) : nextPayDate.getDate() + 15)

    const startPayDate = new Date('2024-01-10')
    if (nextPayDate < startPayDate) {

        return startPayDate
    }

    return nextPayDate
}

const getDefaultMember = () => {

    // TODO: Remove it when create member is done
    // return {

    //     name: "Modiga Arsu",
    //     surname: "Mihai",
    //     county: "Brasov",
    //     locality: "Brasov",
    //     phone: "0762602467",
    //     email: "monitorizare.animale@gmail.com",
    //     politicalExperience: false,        
    //     specialization: "",        
    //     function: "localPresident",

    //     role: "member",                         // changed only by admin

    //     // Computed on upload
    //     registrationNumber: "",                  // computed, read-noly
    //     nextPayDate: computeNextPayDate()        // computed, read-only
    // }

    return {

        name: "",
        surname: "",
        county: "",
        locality: "",
        phone: "",
        email: "",
        politicalExperience: false,        
        specialization: "",        
        function: "",

        role: "member",                         // changed only by admin

        // Computed on upload
        registrationNumber: "",                 // computed, read-noly
        nextPayDate: computeNextPayDate()       // computed, read-only
    }
}

const fetchMemberPrivateByMemberPrivateId = async memberPrivateId => {

    const firestore = getFirestore()

    try {
        const memberPrivateDoc = doc(collection(firestore, "members_private"), memberPrivateId)

        const memberPrivate = await getDoc(memberPrivateDoc).then(memberPrivateSnapshot => memberPrivateSnapshot.exists() ? memberPrivateSnapshot.data() : null)

        return memberPrivate
    } catch (error) {

        console.log("Error: ", error)

        return null
    }

    /*let q =  query(
        collection(firestore, "members_private")
    )
    q = query(
        q,
        where(
            "memberId",
            "==",
            memberId
        )
    )

    try {

        const snapshot = await getDocs(q)

        if (snapshot.empty) {
            
            return null
        }

        const result = new Promise((resolve, reject) => {

            snapshot.forEach(doc => {

                resolve(doc.data())
            })        
        })

        return result
    } catch(error) {

        return null
    }*/
}

const getDefaultMemberPrivate = () => {
    
    // Private
    // TODO: Remove it when create member is done
    // return {
    //     CNP: "1821003410081",
    //     icSeries: "RD",
    //     icNumber: "910400",
    //     monthlyPayment: 20   // editable only by member
    // } 

    return {
        CNP: "",
        icSeries: "",
        icNumber: "",
        monthlyPayment: 20   // editable only by member
    }                   
}

const validateMember =  async (member, memberPrivate, setErrors) => {

    let errors = {}

    if (member) {

        errors = {
            ...errors,
            ...validateMemberName(member.name)
        }

        errors = {
            ...errors,
            ...validateMemberSurname(member.surname)
        }

        errors = {
            ...errors,
            ...validatePhone(member.phone)
        }

        errors = {
            ...errors,
            ...validateEmail(member.email)
        }

        errors = {
            ...errors,
            ...validateCounty(member.county, member.id, member.function)
        }

        errors = {
            ...errors,
            ...validateLocality(member.locality, member.county, member.id, member.function)
        }

        errors = {
            ...errors,
            ...(await validateFunction(member.function, member.id, member.county, member.locality))
        }
    }

    if (hasOwnProperties(memberPrivate)) {

        errors = {
            ...errors,
            ...validateCNP(memberPrivate.CNP)
        }
    
        errors = {
            ...errors,
            ...validateMemberIcSeries(memberPrivate.icSeries)
        }
    
        errors = {
            ...errors,
            ...validateMemberIcNumber(memberPrivate.icNumber)
        }
    
        errors = {
            ...errors,
            ...validateMemberMonthlyPayment(memberPrivate.monthlyPayment)
        }        
    }

    setErrors(errors)

    return !hasOwnProperties(errors)
}

const validateMemberName = memberName => {

    if (!memberName) {

        return {
            name: 'msgNameNotFilledIn'
        }
    }

    if (memberName.length < 3) {

        return {
            name: 'msgNameTooShort'
        }
    }

    if (/^[A-Za-z\s]*$/.test(memberName) === false) {

        return {
            name: 'msgNameMustContainOnlyLettersAndSpaces'
        }
    }

    return {}
}

const validateMemberSurname = memberSurname => {

    if (!memberSurname) {

        return {
            surname: 'msgSurnameNotFilledIn'
        }
    }

    if (memberSurname.length < 3) {

        return {
            surname: 'msgSurnameTooShort'
        }
    }

    if (/^[A-Za-z\s]*$/.test(memberSurname) === false) {

        return {
            surname: 'msgSurnameMustContainOnlyLettersAndSpaces'
        }
    }

    return {}
}

const validatePhone  = phone => {

    if (!phone) {
        return {
            phone: 'msgPhoneNotFilledIn'
        }
    }

    if (!isValidPhoneNumber(phone) && !isNormalizedPhoneNumber(phone)) {
        return {
            phone: 'msgInvalidPhoneNumber'
        }
    }

    return {}
}

const validateEmail  = email => {

    if (!email) {
        return {
            email: 'msgEmailNotFilledIn'
        }
    }

    if (!isValidEmailAddress(email)) {
        return {
            email: 'msgInvalidEmailAddress'
        }
    }

    return {}
}

const validateCounty  = async (county, memberId, enoaFunction) => {

    if (!county) {
        return {
            county: 'msgCountyNotSelected'
        }
    }

    return {}
}

const validateLocality  = async (locality, county, memberId, enoaFunction) => {

    if (!locality) {
        return {
            locality: 'msgLocalityNotSelected'
        }
    }


    return {}
}


const validateFunction = async (enoaFunction, memberId, county, locality) => {

    if (enoaFunction === "") {

        return {}
    }

    // TODO: Try to move it in firestore rules and then check it in checkMemberConstraints
    if (enoaFunction === "vicePresident") {

        const members = await filterMembers(
            {
                "function": enoaFunction
            },
            1
        )

        if (members?.data?.length >= 7) {

            return {
                function: "msgVicePresidentAreAready7"
            }
        }
    }

    return {}
}

const validateMemberIcSeries = icSeries => {

    if (!icSeries) {

        return {
            icSeries: 'msgIcSeriesNotFilledIn'
        }
    }

    if (icSeries.length != 2) {

        return {
            icSeries: 'msgIcSeriesInvalidLength'
        }
    }

    if (/^[A-Z\s]*$/.test(icSeries) === false) {

        return {
            icSeries: 'msgIcSeriesMustContainOnlyCapitalLetters'
        }
    }

    return {}
}

const validateMemberIcNumber = icNumber => {

    if (!icNumber) {

        return {
            icNumber: 'msgIcNumberNotFilledIn'
        }
    }

    if (icNumber.length != 6) {

        return {
            icNumber: 'msgIcNumberInvalidLength'
        }
    }

    if (/^[0-9\s]*$/.test(icNumber) === false) {

        return {
            icNumber: 'msgIcNumberMustContainOnlyDigits'
        }
    }

    return {}
}

const validateMemberMonthlyPayment = monthlyPayment => {

    if (!monthlyPayment) {

        return {
            monthlyPayment: 'msgMonthlyPaymentNotSelected'
        }
    }

    return {}
}

function isValidCNP( CNP ) {

    var i=0 , year=0 , month=0, date=0, hashResult=0 , crtCNP=[] , hashTable=[2,7,9,1,4,6,3,5,8,2,7,9];

    // Already checked
    if( CNP.length !== 13 ) { return 'msgCNPInvalidNumberOfDigits'; }

    for( i=0 ; i<13 ; i++ ) {
        crtCNP[i] = parseInt( CNP.charAt(i) , 10 );
        if( isNaN( crtCNP[i] ) ) { return 'msgCNPInvalidCharacter'; }
        if( i < 12 ) { hashResult = hashResult + ( crtCNP[i] * hashTable[i] ); }
    }

    hashResult = hashResult % 11;

    if( hashResult === 10 ) { hashResult = 1; }

    year = (crtCNP[1]*10)+crtCNP[2];
    month = (crtCNP[3]*10)+crtCNP[4];
    date = (crtCNP[5]*10)+crtCNP[6];

    switch( crtCNP[0] ) {
        case 1  : case 2 : { year += 1900; } break;
        case 3  : case 4 : { year += 1800; } break;
        case 5  : case 6 : { year += 2000; } break;
        case 7  : case 8 : case 9 : { year += 2000; if( year > ( parseInt( new Date().getYear() , 10 ) - 14 ) ) { year -= 100; } } break;
        default : { return 'msgCNPInvalidFirstCharacter'; }
    }

    if( year < 1800 || year > 2099 ) { return 'msgCNPInvalidYear'; }

    const crtDate = new Date();
    const dob = new Date(year, month, date);
    const diffTime = Math.abs(crtDate - dob);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    if (diffDays < 18 * 365) {
        return 'msgCNPMemberTooYoung'
    }

    if ( crtCNP[12] !== hashResult ) {
        return 'msgCNPControlDigitIsNotValid'
    }

    return true
}

const validateCNP = CNP => {

    if (!CNP) {
        return {
            CNP: 'msgCNPNotFilledIn'
        }
    }

    if(CNP.length !== 13) {
        return {
            CNP: 'msgCNPInvalidNumberOfDigits'
        }
    }

    const res = isValidCNP(CNP)

    if (res != true) {
        return {
            CNP: res
        }
    }

    return {}
}

const logAccessToMemberPrivate = async (member, enoaUser) => {

    if (!enoaUser) {

        return false
    }
  
    // Region must be specified
    const functions = getFunctions(getApp(), 'europe-west3')

    const callLogAccessToMemberPrivate = httpsCallable(
        functions, 
        'logAccessToMemberPrivate', 
        {
            limitedUseAppCheckTokens: true
        } 
    )

    try {

        // Auth data are fetched from firebase user provider data in cloud function
        const result = await callLogAccessToMemberPrivate({
            member,
            enoaUser
        })

        return result.data

    } catch (err) {

        return false
    }
}

const checkMemberConstraints = async (member, memberPrivate, initialMember, initialMemberPrivate) => {

    let errors = {}, res;

    if (hasOwnProperties(member)) {

        if (member.email != initialMember.email) {

            res = await existsFieldValuesInCollection('members', ['email'], member)

            if (res === true ) {

                errors = {
                    ...errors,
                    email: "msgExistsInDatabase"  
                }
            }
        }

        if (member.phone != initialMember.phone) {
            
            res = await existsFieldValuesInCollection('members', ['phone'], member)
            if (res === true ) {

                errors = {
                    ...errors,
                    phone: "msgExistsInDatabase"  
                }
            }
        }

        if (member.registrationNumber != initialMember.registrationNumber) {
            
            res = await existsFieldValuesInCollection('members', ['registrationNumber'], member)
            if (res === true ) {

                errors = {
                    ...errors,
                    registrationNumber: "msgExistsInDatabase"  
                }
            }
        }

        if (member.function != initialMember.function && member.function == "president") {
            
            const members = await filterMembers(
                {
                    "function": member.function
                },
                1
            )
    
            if (members?.data?.length ) {
    
                errors = {
                    ...errors,
                    function: "msgPresidentIsAreadySet"
                }
            }
        }

        
        if (
            (member.function != initialMember.function || member.county != initialMember.county) && 
            member.function == "countyPresident"
        ) {

               const members = await filterMembers(
                {
                    "function": member.function,
                    county: member.county
                },
                1
            )
        
            if (members?.data?.length && members.data[0].id !== member.id) {
        
                errors = {
                    ...errors,
                    function: "msgCountyPresidentIsAlreadySet",
                    county: "msgCountyPresidentIsAlreadySet"
                }
            }
        }

        if (
            (member.function != initialMember.function || member.county != initialMember.county || member.locality != initialMember.locality) && 
            member.function == "localPresident"
        ) {

            const members = await filterMembers(
                {
                    "function": member.function,
                    county: member.county,
                    locality: member.locality
                },
                1
            )
    
            if (members?.data?.length && members.data[0].id !== member.id) {
    
                errors = {
                    ...errors,
                    function: "msgLocalOfficePresidentIsAlreadySet",
                    locality: "msgLocalOfficePresidentIsAlreadySet"
                }
            }
        }
    }

    if (hasOwnProperties(memberPrivate)) {

        if (memberPrivate.CNP != initialMemberPrivate.CNP) {
        
            res = await existsFieldValuesInCollection('members_private', ['CNP'], memberPrivate)
            if (res === true ) {

                errors = {
                    ...errors,
                    CNP: "msgExistsInDatabase"  
                }
            }
        }

        if (memberPrivate.icSeries + memberPrivate.icNumber != initialMemberPrivate.icSeries + initialMemberPrivate.icNumber) {
            
            res = await existsFieldValuesInCollection('members_private', ['icSeries', 'icNumber'], memberPrivate)
            if (res === true ) {

                errors = {
                    ...errors,
                    ic: "msgExistsInDatabase"  
                }
            }
        }
    }

    return errors
}

const getGroupByAbbreviation = async (abbreviation, groupType) => {

    const groups = await filterGroups({
        type: groupType
    })

    if (groups?.data?.length === 0) {

        return null
    }

    const selectedGroup = groups.data.filter(g => g.abbreviation === abbreviation)

    if (selectedGroup.length === 0) {

        console.error("No item found: ", abbreviation)

        return null
    }

    return selectedGroup[0]
}

//items: array of counties or function, 
//itemName: county or function name
//groupType: to filter groups ["county" | "function"]
const getGroupByItemName = async (items, itemName, groupType) => { 

    const item = items.filter(f => f.name === itemName)

    if (item.length === 0) {

        console.error("No item found: ", itemName)

        return null
    }

    return await getGroupByAbbreviation(item[0].id, groupType)
}

const createMemberFunctionUniqueKey = (firestore, batch, member, memberRef) => {

    if (member.function === 'president') {

        const uniqueKeyFunctionRef = doc(firestore, 'unique_keys', 'members', 'function', member.function)
        batch.set(uniqueKeyFunctionRef, {
            value: memberRef.id
        })

        return
    }

    if (member.function === 'countyPresident') {

        const uniqueKeyFunctionRef = doc(firestore, 'unique_keys', 'members', 'function', member.function + "-" + member.county)
        batch.set(uniqueKeyFunctionRef, {
            value: memberRef.id
        })

        return
    }

    if (member.function === 'localPresident') {

        const uniqueKeyFunctionRef = doc(firestore, 'unique_keys', 'members', 'function', member.function + "-" + member.county + "-" + member.locality)
        batch.set(uniqueKeyFunctionRef, {
            value: memberRef.id
        })

        return
    }
}

const createFirestoreMember = async (member, memberPrivate) => {

    // Check on client, not to call the cloud function when the result is already known
    try {

        const firestore = getFirestore()
        const batch = writeBatch(firestore)

        const memberRef = doc(collection(firestore, 'members'))
        const memberPrivateRef = doc(collection(firestore, 'members_private'))

        batch.set(memberRef, {
            ...member,
            id: memberRef.id,
            memberPrivateId: memberPrivateRef.id
        })

        batch.set(memberPrivateRef, {
            ...memberPrivate,
            id: memberPrivateRef.id,
            memberId: memberRef.id
        })

        const uniqueKeyEmailRef = doc(firestore, 'unique_keys', 'members', 'email', member.email)
        batch.set(uniqueKeyEmailRef, {
            value: memberRef.id
        })

        const uniqueKeyPhoneRef = doc(firestore, 'unique_keys', 'members', 'phone', member.phone)
        batch.set(uniqueKeyPhoneRef, {
            value: memberRef.id
        })

        const uniqueKeyRegistrationNumberRef = doc(firestore, 'unique_keys', 'members', 'registrationNumber', member.registrationNumber)
        batch.set(uniqueKeyRegistrationNumberRef, {
            value: memberRef.id
        })

        if (member.function !== '') {

            createMemberFunctionUniqueKey(firestore, batch, member, memberRef)
        }

        const uniqueKeyCNPRef = doc(firestore, 'unique_keys', 'members_private', 'CNP', memberPrivate.CNP)
        batch.set(uniqueKeyCNPRef, {
            value: memberPrivateRef.id
        })

        const uniqueKeyICRef = doc(firestore, 'unique_keys', 'members_private', 'IC', memberPrivate.icSeries + memberPrivate.icNumber)
        batch.set(uniqueKeyICRef, {
            value: memberPrivateRef.id
        })

        const nationalGroupId = '0-RO'
        const membersGroupsNationalRef = doc(collection(firestore, 'members_groups'), `${memberRef.id}-${nationalGroupId}`)
        batch.set(membersGroupsNationalRef, {
            memberId: memberRef.id,
            groupId: nationalGroupId
        })

        const county = await fetchFirestoreCountyByName(member.county)
        const countyGroup = await filterGroups({
            abbreviation: county.id
        })

        if (countyGroup.data.length !== 0) {

            const membersGroupsRef = doc(collection(firestore, 'members_groups'), `${memberRef.id}-${countyGroup.data[0].id}`)

            batch.set(membersGroupsRef, {
                memberId: memberRef.id,
                groupId: countyGroup.data[0].id
            })
        }

        if (member.function === 'vicePresident' || member.function === 'countyPresident' || member.function === 'localPresident') {

            const enoaFunction = await fetchFirestoreFunctionByName(member.function)
            const functionGroup = await filterGroups({
                abbreviation: enoaFunction.id
            })

            if (functionGroup.data.length !== 0) {

                const membersGroupsRef = doc(collection(firestore, 'members_groups'), `${memberRef.id}-${functionGroup.data[0].id}`)

                batch.set(membersGroupsRef, {
                    memberId: memberRef.id,
                    groupId: functionGroup.data[0].id
                })
            }
        }
       

        await batch.commit()

    } catch(error) {

        console.log("Create Member Error: ", error)

        return error
    }

    console.log("Create Member Success")

    cloudLogger('info', `Member: ${JSON.stringify(member)}, member private: ${JSON.stringify(memberPrivate)} created`)

    return true

    // TODO find errors in res
}

const updateMemberFunctionUniqueKey = (firestore, batch, member, memberPrevData) => {


    if (memberPrevData.function === 'president') {

        const uniqueKeyFunctionRef = doc(collection(firestore, 'unique_keys'),`members/function/${memberPrevData.function}`)
        batch.delete(uniqueKeyFunctionRef)   
    }

    if (memberPrevData.function === 'countyPresident') {

        const uniqueKeyFunctionRef = doc(collection(firestore, 'unique_keys'),`members/function/${memberPrevData.function}-${memberPrevData.county}`)
        batch.delete(uniqueKeyFunctionRef)   
    }

    if (memberPrevData.function === 'localPresident') {

        const uniqueKeyFunctionRef = doc(collection(firestore, 'unique_keys'),`members/function/${memberPrevData.function}-${memberPrevData.county}-${memberPrevData.locality}`)
        batch.delete(uniqueKeyFunctionRef)   
    }

    if (member.function === 'president') {

        const uniqueKeyFunctionRef = doc(firestore, 'unique_keys', 'members', 'function', member.function)
        batch.set(uniqueKeyFunctionRef, {
            value: member.id
        })

        return
    }

    if (member.function === 'countyPresident') {

        const uniqueKeyFunctionRef = doc(firestore, 'unique_keys', 'members', 'function', member.function + "-" + member.county)
        batch.set(uniqueKeyFunctionRef, {
            value: member.id
        })

        return
    }

    if (member.function === 'localPresident') {

        const uniqueKeyFunctionRef = doc(firestore, 'unique_keys', 'members', 'function', member.function + "-" + member.county + "-" + member.locality)
        batch.set(uniqueKeyFunctionRef, {
            value: member.id
        })

        return
    }
}

const doUpdateMember = async (firestore, batch, member) => {

    const memberRef = doc(collection(firestore, 'members'), member.id)
    const memberPrevData = await getDoc(memberRef).then(memberSnapshot => memberSnapshot.exists() ? memberSnapshot.data() : null)

    if (!memberPrevData) {

        return "No group found"
    }

    // Update registration number (BEFORE setting member) if county is changed
    // member county has already been validated
    if (member.county !== memberPrevData.county) {

        member.registrationNumber = await fetchNextRegistrationNumber(member.county)

        const prevUniqueKeyRegistrationNumberRef = doc(collection(firestore, 'unique_keys'),`members/registrationNumber/${memberPrevData.registrationNumber}`)
        const uniqueKeyRegistrationNumberRef = doc(collection(firestore, 'unique_keys'), `members/registrationNumber/${member.registrationNumber}`)

        batch.delete(prevUniqueKeyRegistrationNumberRef)
        batch.set(uniqueKeyRegistrationNumberRef, {
            value: memberRef.id
        })
    }

    batch.set(memberRef, member)

    if (member.email !== memberPrevData.email) {

        const prevUniqueKeyEmailRef = doc(collection(firestore, 'unique_keys'),`members/email/${memberPrevData.email}`)
        const uniqueKeyEmailRef = doc(collection(firestore, 'unique_keys'), `members/email/${member.email}`)

        batch.delete(prevUniqueKeyEmailRef)
        batch.set(uniqueKeyEmailRef, {
            value: memberRef.id
        })
    }

    if (member.phone !== memberPrevData.phone) {

        const prevUniqueKeyPhoneRef = doc(collection(firestore, 'unique_keys'),`members/phone/${memberPrevData.phone}`)
        const uniqueKeyPhoneRef = doc(collection(firestore, 'unique_keys'), `members/phone/${member.phone}`)

        batch.delete(prevUniqueKeyPhoneRef)
        batch.set(uniqueKeyPhoneRef, {
            value: memberRef.id
        })
    }

    if (member.county !== memberPrevData.county) {

        // Change county group
        const prevCounty = await fetchFirestoreCountyByName(memberPrevData.county)
        const prevCountyGroup = await filterGroups({
            abbreviation: prevCounty.id
        })

        if (prevCountyGroup.data.length !== 0) {

            const prevMembersGroupsRef = doc(collection(firestore, 'members_groups'), `${member.id}-${prevCountyGroup.data[0].id}`)

            batch.delete(prevMembersGroupsRef)
        }

        const county = await fetchFirestoreCountyByName(member.county)
        const countyGroup = await filterGroups({
            abbreviation: county.id
        })

        if (countyGroup.data.length !== 0) {

            const membersGroupsRef = doc(collection(firestore, 'members_groups'), `${member.id}-${countyGroup.data[0].id}`)

            batch.set(membersGroupsRef, {
                memberId: member.id,
                groupId: countyGroup.data[0].id
            })
        }
    }

    if (member.function !== memberPrevData.function) {
        
        // Change county function
        if (memberPrevData.function !== "") {

            const prevEnoaFunction = await fetchFirestoreFunctionByName(memberPrevData.function)
            const prevFunctionGroup = await filterGroups({
                abbreviation: prevEnoaFunction.id
            })

            if (prevFunctionGroup.data.length !== 0) {

                const prevMembersGroupsRef = doc(collection(firestore, 'members_groups'), `${member.id}-${prevFunctionGroup.data[0].id}`)

                batch.delete(prevMembersGroupsRef)
            }
            
        }

        if (member.function !== "") {

            const enoaFunction = await fetchFirestoreFunctionByName(member.function)
            const functionGroup = await filterGroups({
                abbreviation: enoaFunction.id
            })

            if (functionGroup.data.length !== 0) {

                const membersGroupsRef = doc(collection(firestore, 'members_groups'), `${member.id}-${functionGroup.data[0].id}`)

                batch.set(membersGroupsRef, {
                    memberId: member.id,
                    groupId: functionGroup.data[0].id
                })
            }
        }
    }

    if (member.function !== memberPrevData.function || member.county !== memberPrevData.county || member.locality !== memberPrevData.locality) {

        updateMemberFunctionUniqueKey(
            firestore,
            batch,
            member, 
            memberPrevData
        )
    }

    return true

}

const doUpdateMemberPrivate = async (firestore, batch, memberPrivate) => {
    
    const memberPrivateRef = doc(collection(firestore, 'members_private'), memberPrivate.id)
    const memberPrivatePrevData = await getDoc(memberPrivateRef).then(memberPrivateSnapshot => memberPrivateSnapshot.exists() ? memberPrivateSnapshot.data() : null)

    if (!memberPrivatePrevData) {

        return "No member private found"
    }

    batch.set(memberPrivateRef, memberPrivate)

    if (memberPrivate.CNP !== memberPrivatePrevData.CNP) {

        const prevUniqueKeyCNPRef = doc(collection(firestore, 'unique_keys'),`members_private/CNP/${memberPrivatePrevData.CNP}`)
        const uniqueKeyCNPRef = doc(collection(firestore, 'unique_keys'), `members_private/CNP/${memberPrivate.CNP}`)

        batch.delete(prevUniqueKeyCNPRef)
        batch.set(uniqueKeyCNPRef, {
            value: memberPrivateRef.id
        })
    }

    if (memberPrivate.icSeries + memberPrivate.icNumber !== memberPrivatePrevData.icSeries + memberPrivatePrevData.icNumber) {

        const prevUniqueKeyICRef = doc(collection(firestore, 'unique_keys'),`members_private/IC/${memberPrivatePrevData.icSeries + memberPrivatePrevData.icNumber}`)
        const uniqueKeyICRef = doc(collection(firestore, 'unique_keys'), `members_private/IC/${memberPrivate.icSeries + memberPrivate.icNumber}`)

        batch.delete(prevUniqueKeyICRef)
        batch.set(uniqueKeyICRef, {
            value: memberPrivateRef.id
        })
    }

    return true

}

const updateFirestoreMember = async (member, memberPrivate) => {

    // Check on client, not to call the cloud function when the result is already known
    try {
        const firestore = getFirestore()
        const batch = writeBatch(firestore)

        if (member) {

            const res = await doUpdateMember(firestore, batch, member)

            if (res !== true) {

                 return {
                    error: res
                 }
            }
        }

        if (memberPrivate) {

            const res = await doUpdateMemberPrivate(firestore, batch, memberPrivate)

            if (res !== true) {

                return {
                   error: res
                }
           }
        }

        await batch.commit()

    } catch(error) {

        console.log("Update Member Error: ", error)

        return error
    }

   console.log("Update Member Success")

   cloudLogger('info', `Member: ${JSON.stringify(member)}, member private: ${JSON.stringify(memberPrivate)} updated`)

   return true
}

const deleteMembersGroupsForMemberId = async (memberId, batch) => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, "members_groups")
    )
    q = query(
        q,
        where(
            "memberId",
            "==",
            memberId
        )
    )

    const snapshot = await getDocs(q)

    if (snapshot.empty) {
        
        // It should not be here, 
        // all members are in at least 2 groups
        return null
    }


    snapshot.forEach(doc => {

        batch.delete(doc.ref)
    })        
}

const deleteFirebaseMembersForMemberId = async memberId => {

    if (!memberId) {

        return false
    }
  
    // Region must be specified
    const functions = getFunctions(getApp(), 'europe-west3')

    const callDeleteFirebaseMembersForMemberId = httpsCallable(
        functions, 
        'deleteFirebaseMembersForMemberId', 
        {
            limitedUseAppCheckTokens: true
        } 
    )

    try {

        // Auth data are fetched from firebase user provider data in cloud function
        const result = await callDeleteFirebaseMembersForMemberId({
            memberId
        })

        return result.data

    } catch (err) {

        return false
    }
}

const deleteMemberFunctionUniqueKey = (firestore, batch, memberData) => {

    
    if (memberData.function === 'president') {

        const uniqueKeyFunctionRef = doc(collection(firestore, 'unique_keys'),`members/function/${memberData.function}`)
        batch.delete(uniqueKeyFunctionRef)   

        return
    }

    if (memberData.function === 'countyPresident') {

        const uniqueKeyFunctionRef = doc(collection(firestore, 'unique_keys'),`members/function/${memberData.function}-${memberData.county}`)
        batch.delete(uniqueKeyFunctionRef)   

        return
    }

    if (memberData.function === 'localPresident') {

        const uniqueKeyFunctionRef = doc(collection(firestore, 'unique_keys'),`members/function/${memberData.function}-${memberData.county}-${memberData.locality}`)
        batch.delete(uniqueKeyFunctionRef)   

        return
    }
}

const deleteFirestoreMember = async memberId => {

    if (!memberId) {
        return {
            error: "No member Id"
        }
    }

    try {
        const firestore = getFirestore()
        const batch = writeBatch(firestore)

        const memberRef = doc(collection(firestore, 'members'), memberId)
        const memberData = await getDoc(memberRef).then(memberSnapshot => memberSnapshot.exists() ? memberSnapshot.data() : null)

        if (!memberData) {

            return {
                error: "No member found"
            }
        }

        const memberPrivateRef = doc(collection(firestore, 'members_private'), memberData.memberPrivateId)
        const memberPrivateData = await getDoc(memberPrivateRef).then(memberPrivateSnapshot => memberPrivateSnapshot.exists() ? memberPrivateSnapshot.data() : null)

        if (!memberData) {

            return {
                error: "No member found"
            }
        }

        batch.delete(memberRef)
        batch.delete(memberPrivateRef)

        const uniqueKeyEmailRef = doc(collection(firestore, 'unique_keys'),`members/email/${memberData.email}`)
        batch.delete(uniqueKeyEmailRef)

        const uniqueKeyPhoneRef = doc(collection(firestore, 'unique_keys'),`members/phone/${memberData.phone}`)
        batch.delete(uniqueKeyPhoneRef)

        const uniqueKeyRegistrationNumberRef = doc(collection(firestore, 'unique_keys'),`members/registrationNumber/${memberData.registrationNumber}`)
        batch.delete(uniqueKeyRegistrationNumberRef)

        if (memberData.function) {

            deleteMemberFunctionUniqueKey(firestore, batch, memberData)
        }

        const uniqueKeyCNPRef = doc(collection(firestore, 'unique_keys'),`members_private/CNP/${memberPrivateData.CNP}`)
        batch.delete(uniqueKeyCNPRef)

        const uniqueKeyICRef = doc(collection(firestore, 'unique_keys'),`members_private/IC/${memberPrivateData.icSeries + memberPrivateData.icNumber}`)
        batch.delete(uniqueKeyICRef)

        await deleteMembersGroupsForMemberId(memberId, batch)

        await batch.commit()
        
        const result = await deleteFirebaseMembersForMemberId(memberId)

        if (result !== true) {

            throw "Unable to delete firebase information"
        }

        cloudLogger('info', `Member: ${JSON.stringify(memberData)}, member private: ${JSON.stringify(memberPrivateData)} deleted`)

    } catch(error) {

        console.log("Delete Member Error: ", error)
        
        return {error}
    }

    console.log("Delete Member Success")

    return true
}

const isMemberEqual = (initialMember, member) => {

    // registrationNumber and nextPayDate can not be changed by user so they are not compared

    return isObjectEqualByFields(
        initialMember, 
        member, 
        ['name', 'surname', 'county', 'locality', 'phone', 'email', 'politicalExperience', 'specialization', 'function', 'role']
    )
}

const isMemberPrivateEqual = (initialMemberPrivate, memberPrivate) => {

    return isObjectEqualByFields(
        initialMemberPrivate, 
        memberPrivate,
        ['CNP', 'icSeries', 'icNumber', 'monthlyPayment']
    )
}

// Filters from members_private have 0 or 1 result
const filterMembersPrivate = async filters => {

    try {

        const firestore = getFirestore()

        let q = query(
            collection(firestore, "members_private"),
        )

        // By default order by name
        if (!hasOwnProperties(filters)) {
            filters = {
                name: ""
            }
        }

        for (const [filterName, filterValue] of Object.entries(filters)) {

            if (filterName === 'CNP') {

                if (filterValue) {

                    q = query(
                        q,
                        where(
                            "CNP",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'icSeries') {

                if (filterValue && filters.icNumber) {

                    q = query(
                        q,
                        where(
                            "icSeries",
                            "==",
                            filterValue
                        ),
                        where(
                            "icNumber",
                            "==",
                            filters.icNumber
                        )
                    )
                } else {

                    return {
                        data: [],
                        count: 0
                    }
                }

            }
        }



        const snapshot = await getDocs(
            query(
                q,
                // It should provide at most one result
                // CNP and IC Series/Number
                limit(1)
            )
        )

        if (snapshot.empty) {
            
            return {
                data: [],
                count: 0
            }
        }

        const result = snapshot.docs.map(doc => {

            console.log("Member: ", doc.data())

            return doc.data()
        })

        // TODO Fetch from members based on memberId
        return {
            data: [await fetchMemberByMemberId(result[0].memberId)],
            count: 1
        }
    } catch(error) {

        console.error("Error: ", error)

        return {
            data: undefined,
            count: 0
        }
    }

}

const filterMembers = async (filters, limitNumber, direction, itemRef) => {

    try {

        const firestore = getFirestore()

        let q = query(
            collection(firestore, "members"),
        )

        //console.log("Filters: ", filters)

        // By default order by name
        if (!hasOwnProperties(filters)) {
            filters = {
                name: ""
            }
        }

        for (const [filterName, filterValue] of Object.entries(filters)) {

            if (filterName === "name") {

                if (filterValue) {

                    const nextValue = nextLexicographicString(filterValue)

                    q = query(
                        q,
                        where(
                            filterName,
                            ">=",
                            filterValue.trim()
                        ),
                        where(
                            filterName,
                            "<",
                            nextValue.trim()
                        )
                    )
                }
            }

            if (filterName === 'surname') {

                if (filterValue) {

                    // surname must be equal
                    q = query(
                        q,
                        where(
                            "surname",
                            "==",
                            filterValue.trim()
                        )
                    )
                }
            }

            if (filterName === 'county') {

                if (filterValue) {

                    q = query(
                        q,
                        where(
                            "county",
                            "==",
                            filterValue.trim()
                        )
                    )
                }
            }

            if (filterName === 'locality') {

                if (filterValue) {

                    q = query(
                        q,
                        where(
                            "locality",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'email') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "email",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'phone') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "phone",
                            "==",
                            !isNormalizedPhoneNumber(filterValue) ? normalizePhoneNumber(filterValue) : filterValue
                        )
                    )
                }
            }

            if (filterName === 'registrationNumber') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "registrationNumber",
                            "==",
                            filters.registrationCounty + "-" + filterValue
                        )
                    )
                }
            }

            if (filterName === 'function') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "function",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'role') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "role",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'specialization') {

                if (filterValue && filterValue.length) {

                    q = query(
                        q,
                        where(
                            "specialization",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'politicalExperience') {

                if (filterValue && filterValue) {

                    q = query(
                        q,
                        where(
                            "politicalExperience",
                            "==",
                            filterValue
                        )
                    )
                }
            }

            if (filterName === 'payDateOffset') {

                const getStartOffset = filterValue => {
                    // The array is 1, 2, 3, 6, 12, 24
                    // the return value is increased  
                    // with the distance to the following
                    switch (filterValue) {
                        case 1: return 1
                        case 2: return 2
                        case 3: return 5
                        case 6: return 11
                        case 12: return 23
                        default: return 0
                    }
                }

                if (filterValue) {

                    if (filterValue !== 24) {

                        let startDate = new Date()
                        startDate.setMonth(startDate.getMonth() - getStartOffset(filterValue))
                        startDate.setDate(1)

                        let endDate = new Date()
                        endDate.setMonth(endDate.getMonth() - (filterValue - 1))
                        endDate.setDate(1)

                        q = query(
                            q,
                            where(
                                "nextPayDate",
                                ">=",
                                startDate
                            ),
                            where(
                                "nextPayDate",
                                "<",
                                endDate
                            )
                        )
                    } else {

                        let endDate1 = new Date()
                        endDate1.setMonth(endDate1.getMonth() - (filterValue - 1))
                        endDate1.setDate(1)

                        q = query(
                            q,
                            where(
                                "nextPayDate",
                                "<",
                                endDate1
                            )
                        )
                    }
                    
                    
                }
            }
        }

        const cnt = await getCountFromServer(q)
        console.log("Count: ", cnt.data().count)

        const orderField = getOrderField(filters)
        const ascOrder = orderField?.direction === "asc" ? "asc" : "desc"
        const descOrder = orderField?.direction === "asc" ? "desc" : "asc"

        if (orderField) {

            if (direction !== "" && itemRef && itemRef.name && itemRef.id) {        

                q = query(
                    q, 
                    orderBy(orderField.name, direction === "next" ? ascOrder : descOrder),
                    orderBy("id", direction === "next" ? ascOrder : descOrder),
                    startAfter(itemRef.name)//, itemRef.id)
                )
            } else {

                q = query(
                    q, 
                    orderBy(orderField.name, ascOrder),
                    orderBy("id", ascOrder)
                )
            }
        }


        const snapshot = await getDocs(
            query(
                q,
                limit(limitNumber)
            )
        )

        if (snapshot.empty) {
            
            return {
                data: [],
                count: 0
            }
        }

        const result = snapshot.docs.map(doc => {

            console.log("Member: ", doc.data())

            return doc.data()
        })

        return {
            data: result,
            count: cnt.data().count
        }
    } catch(error) {

        debugger

        console.error("Error: ", error)

        return {
            data: [],
            count: 0,
            error
        }
    }
}

const getOrderField = filters => {

    for (const [filterName, filterValue] of Object.entries(filters)) {

        if (filterName === "name" || 
            filterName === "function" ||
            filterName === "role" ||
            filterName === "specialization"
        ) {

            return {
                name: filterName,
                direction: "asc"
            }
        }
    }

    if (filters.payDateOffset) {

        return {
            name: "nextPayDate",
            direction: "desc"
        }
    }

    return null
}

const applyMembersFilters = async (membersCtx, direction) => {

    if (!membersCtx) {

        return 
    }

    membersCtx.resetFiltersError()

    cloudLogger('info', 'Apply filters: ' + JSON.stringify(membersCtx?.state?.filters))

    let newFilteredMembers

    if (membersCtx?.state?.filters?.CNP || membersCtx?.state?.filters?.icSeries) {

        newFilteredMembers = await filterMembersPrivate(membersCtx?.state?.filters)
    } else {

        if (!direction) {

            newFilteredMembers = await filterMembers(
                membersCtx?.state?.filters, 
                membersCtx?.state?.pageSize
            )
        } else {
    
            newFilteredMembers = await filterMembers(
                    membersCtx?.state?.filters, 
                    membersCtx?.state?.pageSize,
                    direction,
                    membersCtx.state.filteredMembers[
                        direction === 'next' ? membersCtx.state.filteredMembers.length - 1 : 0
                    ]
                )
        }
    }
    
    if (!newFilteredMembers || newFilteredMembers.error) {

        membersCtx.setCurrentPage(1)
        membersCtx.setFilteredMembers([])
        membersCtx.setFiltersError(!newFilteredMembers && !newFilteredMembers.error ? "msgUnkonwnError" : newFilteredMembers.error)

        return
    }

    membersCtx.setFilteredMembersCount(newFilteredMembers.count)

    if (direction !== 'previous') {

        membersCtx.setFilteredMembers(newFilteredMembers.data)
        
    } else {

        membersCtx.setFilteredMembers(newFilteredMembers.data.reverse())
    }

    if (direction === undefined) {

        membersCtx.setCurrentPage(1)
    }

    if (direction === 'next') {
    
        membersCtx.setCurrentPage(membersCtx.state.currentPage + 1)
    }

    if (direction === 'previous') {

        membersCtx.setCurrentPage(membersCtx.state.currentPage - 1)
    }
    
} 

export { 
    fetchMemberByFirebaseUid, 
    fetchMemberByMemberId, 
    fetchMemberPrivateByMemberPrivateId,
    fetchNextRegistrationNumber,
    fetchSpecializations, 
    fetchFunctions,
    fetchRoles,
    fetchMonthlyPayments,
    getDefaultMember, 
    getDefaultMemberPrivate,
    validateMember,
    validateMemberName,
    validateMemberSurname,
    validateEmail,
    validatePhone,
    validateCNP,
    validateMemberIcSeries,
    validateMemberIcNumber,
    logAccessToMemberPrivate,
    createFirestoreMember,
    updateFirestoreMember,
    deleteFirestoreMember,
    isMemberEqual,
    isMemberPrivateEqual,
    isObjectEqualByFields,
    checkMemberConstraints,
    filterMembers,
    filterMembersPrivate,
    applyMembersFilters
}





// Kept as a reference how to call Firebase Cloud Functions
// const isMember = async () => {
  
//     // Region must be specified
//     const functions = getFunctions(getApp(), 'europe-west3')

//     const callIsMember = httpsCallable(
//         functions, 
//         'isMember', 
//         {
//             limitedUseAppCheckTokens: true
//         } 
//     )

//     try {

//         // Auth data are fetched from firebase user provider data in cloud function
//         const result = await callIsMember()

//         if (result.data === true) {

//             return true
//         }

//         return false
//     } catch (err) {

//         return false
//     }
// }

// Example how to call a Firebase Https Function
// const result = await fetch(Constants.expoConfig.IsMemberURL, {
//     method: 'POST',
//     headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json'
//     },
//     body: JSON.stringify({
//         authDataType, 
//         authData
//     })
// });
//const responseText = await result.text()
//console.log("Result text: ", responseText)