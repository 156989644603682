import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, Text, FormControl, Input, FlatList, Select } from 'native-base'

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as MembersContext } from '../../context/MembersContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../i18n/i18n'

import { applyMembersFilters, validateMemberIcNumber, validateMemberIcSeries } from '../../api/members';
import { hasOwnProperties } from '../../api/object';
import { capitalizeWord } from '../../api/string';
import { fetchFirestoreCounties } from '../../api/counties';

const i18n = new I18n({
    ...AppTranslations
  });
  
i18n.enableFallback = true;

const MemberRegistrationNumberFilter = () => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [errors, setErrors] = useState({})

    const memberRegistrationNumberInputRef = useRef()

    const [countiesAbbreviations, setCountiesAbbreviations] = useState([])


    useEffect(() => {
        (async ()=> {
            try {

                const countiesAbbreviations = (await fetchFirestoreCounties()).map(c => c.id)
                setCountiesAbbreviations(countiesAbbreviations)
            } catch(error) {

                setCountiesAbbreviations([])
            }
            
        })()
    }, [])
    
    return <Box>
        
        <FormControl isInvalid={'registrationCounty' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('county')}
            </FormControl.Label>

            <Select selectedValue={membersCtx?.state?.filters?.registrationCounty} 
                placeholder={i18n.t('county')}
                onValueChange={ registrationCounty => {          

                    membersCtx.setFilters({
                        ...membersCtx?.state?.filters,
                        registrationCounty
                    })
                }}
            >
                {
                    countiesAbbreviations.map((county, countyIndex) => (
                        <Select.Item key={countyIndex} label={county} value={county} />
                    ))                    
                }
            </Select>
            
            {
                // county is optional in filters, it must not be in errors
                'registrationCounty' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.registrationCounty) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgCountyIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <Text> - </Text>

        <FormControl isInvalid={'registrationNumber' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('registrationNumber')}
            </FormControl.Label>
            <Input placeholder={i18n.t('registrationNumber')} 
                value={membersCtx?.state?.filters?.registrationNumber}                
                ref={memberRegistrationNumberInputRef}
                onSubmitEditing={() => {

                    if ( !hasOwnProperties(validateMemberRegistrationCounty(membersCtx?.state?.filters?.registrationCounty)) && 
                        !hasOwnProperties(validateMemberRegistrationNumber(membersCtx?.state?.filters?.registrationNumber))
                    ) {

                        applyMembersFilters(membersCtx)
                    }

                    memberRegistrationNumberInputRef.current.focus()
                }}
                onChangeText={value => {

                    const crtError = validateMemberRegistrationNumber(value)

                    if (hasOwnProperties(crtError)) {

                        setErrors({
                            ...errors,
                            ...crtError
                        })
                    } else {

                        delete errors.registrationNumber
                        setErrors(errors)
                    }

                    membersCtx.setFilters({
                        ...membersCtx.state.filters, 
                        registrationNumber: value
                    })    
                    
                }}
            />
            
            {
                'registrationNumber' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.registrationNumber) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgNameIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

    </Box>
};

const validateMemberRegistrationCounty = value => {

    if (!value || !value.length) {

        return {
            registrationCounty: "msgRegistrationCountyNotFilledIn"
        }
    }
}

const validateMemberRegistrationNumber = value => {

    if (!value) {

        return {
            registrationNumber: "msgRegistrationNumberNotFilledIn"
        }
    }

    if (value.length !== 5) {

        return {
            registrationNumber: "msgRegistrationNumberMustHave5Digits"
        }
    }

    if (!/^\d+$/.test(value)) {

        return {
            registrationNumber: "msgRegistrationNumberMustContainOnlyDigits"
        }
    }
}

const styles = StyleSheet.create({ 
    container: {
        borderWidth: 1
    },
    backgroundImage: {
        flex: 1
    },
    registrationNumberFilterContainer: {
        flexDirection: 'row'
    }
})

export default MemberRegistrationNumberFilter;