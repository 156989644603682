import { getLanguageFromAsyncStorage } from './language'
import { getThemeFromAsyncStorage } from './theme'

const setLanguageContext = languageCtx => {

    const doFetchStoredLanguage = async () => {
        console.log("\t\tdoFetchStoredLanguage")
        languageCtx.setLanguage(await getLanguageFromAsyncStorage('ro-RO'))
    }
    
    doFetchStoredLanguage()
}

const setThemeContext = (themeCtx, setColorMode) => {

    const doFetchStoredTheme = async () => {

        const storedTheme = await getThemeFromAsyncStorage('light')

        // Set Theme in Theme Context
        themeCtx.setTheme(storedTheme)
        
        // Set Application Color Mode
        setColorMode(storedTheme)
    }

    doFetchStoredTheme()
}

export {
    setLanguageContext,
    setThemeContext
}