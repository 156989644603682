import React, { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useColorModeValue, useSafeArea, Box, Button, Text } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as MembersContext } from '../context/MembersContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'

import Spacer from '../components/Spacer';

import MembersFilters from '../components/members/MembersFilters'

import { sendEmailToMembers, sendSMSToMembers } from '../api/actions';

// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const MembersScreen = ({ navigation }) => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    
    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>

        {/* <Text> Members Screen </Text> */}

        {["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? <Button onPress={() => {
            navigation.dispatch(
                StackActions.push(
                    "MemberDetails",
                    {
                        createMember: true
                    }
                )
            )
        }}>
            { i18n.t('newMember') }
        </Button> : null }

        <Spacer/>

        <Button onPress={() => {
                navigation.dispatch(StackActions.pop())
            }}>
                { i18n.t('back') }
        </Button>


        <MembersFilters
            showFilteredMembersDetails={true}
            navigation={navigation}
        />
           
        <Spacer/>

        <Button.Group isDisabled={!membersCtx?.state?.multipleSelectionMembersIds?.length}>
            
            <Button onPress={async () => {

                // TODO Open a confirmation window with costs computed
                sendEmailToMembers(multipleSelectionMembersIds)
            }}>
                {i18n.t('sendEmailToSelectedMembers')}
            </Button>

            <Button onPress={async () => {

                // TODO Open a confirmation window with costs computed
                sendSMSToMembers(multipleSelectionMembersIds)
            }}>
                {i18n.t('sendSMSToSelectedMembers')}
            </Button>
        </Button.Group>
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default MembersScreen;