import { query, where, getDocs, getDoc, collection, getFirestore, updateDoc, doc, deleteField, getCountFromServer } from "firebase/firestore"

const fetchLocalitiesFromCounty = async county => {

    const firestore = getFirestore()

    const q = query(collection(firestore, "static/locations/localities"), where("county", "==", county));

    return getDocs(q).then(localitiesSnapshot => Promise.all(
        localitiesSnapshot.docs.map(localityDoc => localityDoc.data().name)
    ))
}

const filterLocalitiesByCounty = (localities, county) => {
    if (!localities || !localities.filter || !county || !county.length) {
        return []
    }

    //console.log("Localities: " + JSON.stringify(localities))

    const filteredLocalities = localities
        .filter(locality => locality.county === county)
        .map(locality => locality.name)

    //console.log("Filtered localities by county: " + filteredLocalities)

    return filteredLocalities
}

const filterLocalitiesArrayByPrefix = (localitiesArray, prefix) => {

    if (!localitiesArray) {

        return []
    }

    if (!prefix) {

        return localitiesArray.slice(0, 10)
    }

    let filteredLocalities = []

    if (prefix && prefix.length > 0) {

        filteredLocalities = localitiesArray
            .filter(locality => locality.toLowerCase().startsWith(prefix.toLowerCase()))
            .slice(0, 10)
    } else {

        filteredLocalities = uniq(localitiesArray.slice(0, 10))
    }

    //console.log("Filtered localities by prefix: " + uniq(filteredLocalities))

    return uniq(filteredLocalities)
}

const  uniq = a => {
    return a.sort().filter(function(item, pos, ary) {
        return !pos || item != ary[pos - 1];
    });
}

export { filterLocalitiesByCounty, filterLocalitiesArrayByPrefix, fetchLocalitiesFromCounty}