import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, Text, FormControl, Input, FlatList, Select } from 'native-base'

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as MembersContext } from '../../context/MembersContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

import { applyMembersFilters, validatePhone } from '../../api/members';
import { hasOwnProperties } from '../../api/object';

const i18n = new I18n({
    ...AppTranslations
  });
  
i18n.enableFallback = true;

const MemberPhoneFilter = () => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [errors, setErrors] = useState({})

    const memberPhoneInputRef = useRef()
    
    return <Box>

        {/* <Text>Errors: {JSON.stringify(errors)} </Text> */}
        
        <FormControl isInvalid={'phone' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('phoneNumber')}
            </FormControl.Label>
            <Input placeholder={i18n.t('phoneNumber')} 
                value={membersCtx?.state?.filters?.phone}                
                ref={memberPhoneInputRef}
                onSubmitEditing={() => {
                    applyMembersFilters(membersCtx)
                    memberPhoneInputRef.focus()
                }}
                onChangeText={value => {

                    const crtError = validatePhone(value)

                    if (hasOwnProperties(crtError)) {

                        setErrors({
                            ...errors,
                            ...crtError
                        })
                    } else {

                        delete errors.phone
                        setErrors(errors)
                    }

                    membersCtx.setFilters({
                        ...membersCtx.state.filters, 
                        phone: value
                    })    
                    
                }}
            />
            
            {
                'phone' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.phone) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgNameIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        borderWidth: 1
    },
    backgroundImage: {
        flex: 1
    }
})

export default MemberPhoneFilter;