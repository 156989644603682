import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useColorModeValue, useSafeArea, Box, Button, Text } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as MembersContext } from '../context/MembersContext'
import { Context as GroupsContext } from '../context/GroupsContext'
import { Context as UIContext } from '../context/UIContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
import { addMembersToGroup } from '../api/groups';
import MembersFilters from '../components/members/MembersFilters';
import CustomAlert from '../components/CustomAlert';

// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const GroupAddMembersScreen = ({ navigation }) => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    const groupsCtx = useContext(GroupsContext)
    const uiCtx = useContext(UIContext)

    const selectedGroupInitialMembersIds = useRef([...groupsCtx.state.multipleSelectionMembersIds])

    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const cancelRefConfirmationWindow = useRef()
    const messageConfirmationWindow = useRef()
    const onCancelConfirmationWindow = useRef()
    const onAcceptConfirmationWindow = useRef()
    const [isOpenConfirmationWindow, setIsOpenConfirmationWindow] = useState(false)
    

    return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
        {/* <Text> Group Add Members Screen </Text>

        <Text>Initial Members: {JSON.stringify(selectedGroupInitialMembersIds?.current)}</Text> */}

        <MembersFilters
            showFilteredMembersDetails={false}
            useGroupsContextOnMultipleSelection={true}
            selectedGroupInitialMembersIds={selectedGroupInitialMembersIds}
            navigation={navigation}
        />

        <Button isDisabled={selectedGroupInitialMembersIds.current.length === groupsCtx.state.multipleSelectionMembersIds.length}
            onPress={async () => {

                messageConfirmationWindow.current = 'msgAreYouWantToAddSelectedMembersToGroup'
                uiCtx.setDisableCustomAlertButtons(false)

                onCancelConfirmationWindow.current = () => {

                    setIsOpenConfirmationWindow(false)
                }

                onAcceptConfirmationWindow.current = async () => {

                    uiCtx.setDisableCustomAlertButtons(true)

                    // Skip initial groups
                    const res = await addMembersToGroup(
                        groupsCtx.state.multipleSelectionMembersIds.filter(
                            memberId => !selectedGroupInitialMembersIds.current.includes(memberId)
                        ),
                        groupsCtx.state.selectedGroupId
                    )

                    if (res === true) {

                        selectedGroupInitialMembersIds.current = groupsCtx.state.multipleSelectionMembersIds
                    }

                    // Last step to updated selectedGroupInitialMembersIds
                    setIsOpenConfirmationWindow(false)
                }

                // Last step should be use state, to render the updated references
                setIsOpenConfirmationWindow(true)
            }}
        >
        { i18n.t('addSelectedMembers') }
        </Button>

        <Button onPress={() => {
                navigation.dispatch(StackActions.pop())
            }}>
                { i18n.t('back') }
        </Button>

        <Button onPress={() => {
                navigation.dispatch(StackActions.popToTop())
            }}>
                { i18n.t('home') }
        </Button>

        <CustomAlert 
            cancelRef={cancelRefConfirmationWindow}
            type="confirmation"
            isOpen={isOpenConfirmationWindow}
            title='confirmationWindow'
            message={messageConfirmationWindow.current}
            onCancel={onCancelConfirmationWindow.current}
            onAccept={onAcceptConfirmationWindow.current}
        />
    </Box>
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default GroupAddMembersScreen;