
import createDataContext from './createDataContext';

const uiReducer = (state, action) => {
    
    switch (action.type) {
        case 'setDisableCustomAlertButtons':

            return {
                ...state,
                disableCustomAlertButtons: action.payload
            }

        default:
            return state;
    }
}

const setDisableCustomAlertButtons = dispatch => isDisabled => {

    dispatch({ type: 'setDisableCustomAlertButtons', payload: isDisabled })
}

export const { Provider, Context } = createDataContext(
    uiReducer,
    { setDisableCustomAlertButtons },
    {
        disableCustomAlertButtons: false
    }
);


