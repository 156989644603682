import React, { useState, useContext, useEffect, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Button, Checkbox } from 'native-base'
import GroupPreview from './GroupPreview';
import GroupNameFilter from '../filters/GroupNameFilter';

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as GroupsContext } from '../../context/GroupsContext'
import { Context as MembersContext } from '../../context/MembersContext'

import CustomAlert from '../CustomAlert';

import { StackActions } from '@react-navigation/routers'

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

import Spacer from '../Spacer';
import { applyGroupsFilters } from '../../api/groups';

const i18n = new I18n({
    ...AppTranslations
});
  
i18n.enableFallback = true;



const GroupsFilters = ({
    showFilteredGroupsDetails,
    useMembersContextOnMultipleSelection,
    selectedMemberInitialGroupsIds, 
    navigation
}) => {

    const cancelRefAlert = useRef()

    const authCtx = useContext(AuthContext)
    const groupsCtx = useContext(GroupsContext)
    const membersCtx = useContext(MembersContext)

    const multipleSelectionContext = useMembersContextOnMultipleSelection === true ? membersCtx : groupsCtx

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state; 

    useEffect(() => {
        (async ()=> {
            if (!groupsCtx?.state?.filteredGroups?.length) {

                applyGroupsFilters(groupsCtx)
            }            
        })()
    }, [])

    return <Box>
        {/* <Text> Groups Filters </Text>

        <Text> Filters : {JSON.stringify(groupsCtx?.state?.filters)} </Text> */}

        {/* <Text> Filtered Groups : {JSON.stringify(groupsCtx?.state?.filteredGroups)} </Text> */}

        {
            groupsCtx?.state?.filteredGroupsCount && groupsCtx?.state?.pageSize ? 
                <Text>
                    {groupsCtx?.state?.currentPage} / {Math.ceil(groupsCtx?.state?.filteredGroupsCount / groupsCtx?.state?.pageSize)} 
                </Text> : 
                null
        }

        <GroupNameFilter />

        <Button onPress={ () => applyGroupsFilters(groupsCtx) }>
            { i18n.t('applyFilters') }
        </Button>

        <Button isDisabled={
                !groupsCtx?.state?.filteredGroups?.length ||
                groupsCtx?.state?.currentPage >= Math.ceil(groupsCtx?.state?.filteredGroupsCount / groupsCtx?.state?.pageSize)
            } 
            onPress={async() => {

                applyGroupsFilters(groupsCtx, "next")

            }}
        >
            {i18n.t('next')}
        </Button>

        <Button isDisabled={
                !groupsCtx?.state?.filteredGroups?.length || 
                groupsCtx?.state?.currentPage <= 1
            } 
            onPress={async () => {

                applyGroupsFilters(groupsCtx, "previous")
            }}
        >
            {i18n.t('previous')}
        </Button>

        <Spacer/>

        <Button isDisabled={!multipleSelectionContext?.state?.multipleSelectionGroupsIds?.length} onPress={() => {
            multipleSelectionContext.setMultipleSelectionGroupsIds([])
        }}>
            {i18n.t('clearSelection')}
        </Button>

        <Spacer/>

        {
            groupsCtx?.state?.filteredGroups?.map((group, groupIndex) => {

                const isGroupChecked = multipleSelectionContext?.state?.multipleSelectionGroupsIds && multipleSelectionContext.state.multipleSelectionGroupsIds.includes(group.id)

                // is disabled only when picking groups for a member
                const isGroupSelectionDisabled = useMembersContextOnMultipleSelection === true && (selectedMemberInitialGroupsIds?.current?.includes(group.id) || group.id.length < 5)

                return <Box key={'group_' + groupIndex.toString()} style={styles.groupPreviewContainer}>

                    <Checkbox size="sm" aria-labelledby='onlyDefaultGroups' 
                        isChecked={isGroupChecked}                        
                        isDisabled={isGroupSelectionDisabled}
                        onChange={value => {

                            if (value) {

                                multipleSelectionContext.setMultipleSelectionGroupsIds(
                                    [group.id].concat(multipleSelectionContext.state.multipleSelectionGroupsIds)
                                ) 
                            } else {

                                multipleSelectionContext.setMultipleSelectionGroupsIds(
                                    multipleSelectionContext.state.multipleSelectionGroupsIds.filter(id => id !== group.id)
                                )
                            }
                        }}
                    />

                    <GroupPreview group={group}/>

                    {
                        showFilteredGroupsDetails ? <Button onPress={() => {
                
                            groupsCtx.setSelectedGroupId(group.id)

                            navigation.dispatch(
                                StackActions.push(
                                    "GroupDetails"
                                )
                            )
                            
                        }}>
                    
                            {i18n.t('showDetails')}
                        </Button> : null
                    }
                </Box>
            })
        }

        {
            groupsCtx?.state?.filteredGroups?.length ? <Text>{i18n.t('noResult')}</Text> : null
        }

        <CustomAlert
            type='information'
            cancelRef={cancelRefAlert}
            isOpen={groupsCtx?.state?.error}
            title="error"
            message="msgGroupsFilterError"
            details={groupsCtx?.state?.error === "msgUnkonwnError" ? i18n.t('msgUnkonwnError'): groupsCtx?.state?.error }
            onAccept={() => {
                groupsCtx.resetError()
            }}
        />

    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    },
    groupPreviewContainer: {
        flexDirection: 'row'
    }
})

export default GroupsFilters;