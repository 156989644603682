//import * as firebase from 'firebase'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { collection, doc, setDoc, getFirestore, addDoc } from "firebase/firestore"

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'
// Set the key-value pairs for the different languages you want to support.

const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

// Returns error or falsy value on success
// Used just for signin, not for registration
const emailCodeSignin = async (authAction, email) => {

    console.log("1. emailCode: " + email + "; authAction: " + authAction)

    // let oldPersonId = null

    // if (authAction === 'register') {

    //     oldPersonId = await fetchCurrentPersonId()
    // }

    // console.log("2. Fetch current personId: " + oldPersonId)

    // // Anonymous authentification is always successful. Email from Signin in data needed 
    // // when updating RTDB after

    // // email code authentication is allowed just for signin NOW. But in the future...
    // This is why registeredCredential registerCredential is checked, even it is not used


    console.log("3. Saved signin data")

    const firebaseAuthenticationResult = await firebaseAnonymousSignin()

    console.log("4. EmailCode firebaseAuthenticationResult: " + JSON.stringify(firebaseAuthenticationResult))

    if (!firebaseAuthenticationResult || firebaseAuthenticationResult.error) {

        error = "Unable to register Anonymous Authentication to Firebase. "  + JSON.stringify(firebaseRegistrationResult ? firebaseRegistrationResult.error : "Account disabled")

        console.log(error)

        return error
    }
    
    console.log("Anonymous Successfuly authenticated")

    return null
}

const firebaseAnonymousSignin = async () => {
    const auth = getAuth()
    return await signInAnonymously(auth)
}


const sendCodeToEmail = async (code, email) => {

    doSendEmail(email, `${i18n.t('email_code_content')} ${code}`, `${i18n.t('email_code_title')}`)
}

const doSendEmail = async (emailAddress, content, title) => {

    const firestore = getFirestore()

    const emailData = {
        message: {
            subject: title,
            text: content
        },
        to: emailAddress
    }

    let emailDoc = doc(collection(firestore, 'mail'))

    // /debugger

    // TODO: Test firestore.collection("mail").add(emailData)
    // It shoulds automatically generate the id.
    
    await setDoc(emailDoc, emailData);
}

const generateCode = codeSize => {

    let code = Math.floor(Math.random() * Math.pow(10, codeSize)).toString()
    const prefixLength = codeSize - code.length
    code = "0".repeat(prefixLength) + code

    console.log("Generate code: " + code)
    
    return code

    //return "123456"
}

const isEmail = email => /\S+@\S+\.\S{2,}/.test(email)

export { emailCodeSignin, sendCodeToEmail, generateCode, isEmail }