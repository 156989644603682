import React, { useContext, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { useColorModeValue, useSafeArea, Box, Text, Button } from 'native-base'
import { isSignInWithEmailLink, getAuth } from 'firebase/auth'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as MembersContext } from '../context/MembersContext'
import { Context as GroupsContext } from '../context/GroupsContext'

import { StackActions } from '@react-navigation/routers'

import Spacer from '../components/Spacer'

// import { isMember } from '../api/members'
// import { query, where, getDocs, getDoc, setDoc, collection, getFirestore, updateDoc, doc, deleteField, getCountFromServer } from "firebase/firestore"

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'


// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const HomeScreen = ( { navigation }) => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    const groupsCtx = useContext(GroupsContext)
    
    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    if (isSignInWithEmailLink(getAuth(), window.location.href)) {

        return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
            <Text>
                {i18n.t('msgSuccessfulAuthentication')}
            </Text>
        </Box>
    }

    useEffect(() => {
        membersCtx.resetFilters()
        groupsCtx.resetFilters()
    }, [])

    return (
        <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
            
            {/* <Text>Home Screen</Text>
            <Text>{authCtx.state.enoaUser ? authCtx.state.enoaUser.email : ""}</Text>
            <Text>{authCtx.state.enoaUser ? authCtx.state.enoaUser.phone : ""}</Text> */}

            <Spacer/>

            {["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? <Button onPress={() => {
                navigation.dispatch(StackActions.push("Members"))
            }}>
                { i18n.t('members') }
            </Button> : null}
            
            <Spacer/>

            {["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? <Button onPress={() => {
                navigation.dispatch(StackActions.push("Groups"))
            }}>
                { i18n.t('groups') }
            </Button> : null}

            <Spacer/>

            <Button onPress={() => {
                navigation.dispatch(StackActions.push("MyData"))
            }}>
                { i18n.t('myData') }
            </Button>

            <Spacer/>

            <Button onPress={() => {
                navigation.dispatch(StackActions.push("Payments"))
            }}>
                { i18n.t('payments') }
            </Button>

            <Spacer/>

            <Button onPress={() => {
                navigation.dispatch(StackActions.push("Polls"))
            }}>
                { i18n.t('polls') }
            </Button>

            {/* <MemberDetails memberId={authCtx?.state?.enoaUser?.id}/> */}

        </Box>
    )
}

const styles = StyleSheet.create({ })

export default HomeScreen;