import AsyncStorage from '@react-native-async-storage/async-storage';

const getThemeFromAsyncStorage = async defaultTheme => {

    const value = await AsyncStorage.getItem('@e-noa.ro-theme')

    if(value !== null) {

      return value
    } 

    return defaultTheme
}

export { getThemeFromAsyncStorage }