import React, { useContext, useEffect, useRef, useState  } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, Button, Checkbox, Radio } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import MemberPreview from './MemberPreview';
import MemberNameFilter from '../filters/MemberNameFilter';

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as MembersContext } from '../../context/MembersContext'
import { Context as GroupsContext } from '../../context/GroupsContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

const i18n = new I18n({
    ...AppTranslations
});

i18n.enableFallback = true;

import Spacer from '../Spacer';
import { applyMembersFilters, filterMembers, filterMembersPrivate } from '../../api/members';
import MemberEmailFilter from '../filters/MemberEmailFilter';
import MemberPhoneFilter from '../filters/MemberPhoneFilter';
import MemberPaymentFilter from '../filters/MemberPaymentFilter';
import MemberFunctionFilter from '../filters/MemberFunctionFilter';
import MemberRoleFilter from '../filters/MemberRoleFilter';
import MemberCNPFilter from '../filters/MemberCNPFilter';
import MemberICFilter from '../filters/MemberICFilter';
import MemberRegistrationNumberFilter from '../filters/MemberRegistrationNumberFilter';
import MemberSpecializationFilter from '../filters/MemberSpecializationFilter';
import CustomAlert from '../CustomAlert';


const MembersFilters = ({
    showFilteredMembersDetails,
    useGroupsContextOnMultipleSelection,
    selectedGroupInitialMembersIds,
    navigation
}) => {

    const cancelRefAlert = useRef()

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    const groupsCtx = useContext(GroupsContext)

    const multipleSelectionContext = useGroupsContextOnMultipleSelection === true ? groupsCtx : membersCtx

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [selectedFilter, setSelectedFilter] = useState('name')

    useEffect(() => {
        (async ()=> {
            if (!membersCtx?.state?.filteredMembers?.length) {
                applyMembersFilters(membersCtx)
            }            
        })()
    }, [])

    return <Box>
        {/* <Text> Members Filters </Text>

        <Text> Filters : {JSON.stringify(membersCtx?.state?.filters)} </Text> */}

        {/* <Text> Filtered Members : {JSON.stringify(membersCtx?.state?.filteredMembers)} </Text> */}

        {/* <Text>Current page: {JSON.stringify(membersCtx?.state?.currentPage)}</Text> */}

        {
            membersCtx?.state?.filteredMembersCount && membersCtx?.state?.pageSize ? 
                <Text>
                    {membersCtx?.state?.currentPage} / {Math.ceil(membersCtx?.state?.filteredMembersCount / membersCtx?.state?.pageSize)} 
                </Text> : 
                null
        }

        <Radio.Group name="filtersGroup" accessibilityLabel="filters" value={selectedFilter} 
            onChange={nextValue => {
                setSelectedFilter(nextValue);
                membersCtx.resetFilters()
            }}
            style={styles.filtersOptionsContainer}
        >
            <Radio value="name">{i18n.t('name')}</Radio>
            <Radio value="email">{i18n.t('emailAddress')}</Radio>
            <Radio value="phone">{i18n.t('phoneNumber')}</Radio>
            <Radio value="registrationNumber">{i18n.t('registrationNumber')}</Radio>
            <Radio value="payment">{i18n.t('payment')}</Radio>
            <Radio value="function">{i18n.t('function')}</Radio>
            <Radio value="specialization">{i18n.t('specialization')}</Radio>
            <Radio value="role">{i18n.t('role')}</Radio>
            
            <Radio value="CNP">{i18n.t('CNP')}</Radio>
            <Radio value="IC">{i18n.t('identityCard')}</Radio>
        </Radio.Group>

        {selectedFilter == "name" ? <MemberNameFilter /> : null}
        {selectedFilter == "email" ? <MemberEmailFilter /> : null}
        {selectedFilter == "phone" ? <MemberPhoneFilter /> : null}
        {selectedFilter == "registrationNumber" ? <MemberRegistrationNumberFilter /> : null}
        {selectedFilter == "payment" ? <MemberPaymentFilter /> : null}
        {selectedFilter == "function" ? <MemberFunctionFilter /> : null}
        {selectedFilter == "specialization" ? <MemberSpecializationFilter /> : null}
        {selectedFilter == "role" ? <MemberRoleFilter /> : null}

        {selectedFilter == "CNP" ? <MemberCNPFilter /> : null}
        {selectedFilter == "IC" ? <MemberICFilter /> : null}

        {
            (membersCtx?.state?.currentPage && membersCtx?.state?.pageSize && membersCtx?.state?.maxIterableMembers && membersCtx.state.currentPage + 1) * membersCtx.state.pageSize > membersCtx.state.maxIterableMembers ? 
                <Text> 
                    {i18n.t('msgMaxIterableMembersInAFilterIs')} {membersCtx.state.maxIterableMembers}
                </Text> : 
                null
        }

        <Button onPress={() => applyMembersFilters(membersCtx)}>
            {i18n.t('applyFilters')}
        </Button>

        <Button isDisabled={
                !membersCtx?.state?.filteredMembers?.length || 
                membersCtx?.state?.currentPage >= Math.ceil(membersCtx?.state?.filteredMembersCount / membersCtx?.state?.pageSize) || 
                (membersCtx?.state?.currentPage + 1) * membersCtx?.state?.pageSize > membersCtx?.state?.maxIterableMembers
            } 
            onPress={async() => {

                applyMembersFilters(membersCtx, 'next')
            }}
        >
            {i18n.t('next')}
        </Button>

        <Button isDisabled={
                !membersCtx?.state?.filteredMembers?.length || 
                membersCtx?.state?.currentPage <= 1
            } 
            onPress={async () => {

                applyMembersFilters(membersCtx, 'previous')
            }}
        >
            {i18n.t('previous')}
        </Button>

        <Spacer/>

        <Button isDisabled={!multipleSelectionContext?.state?.multipleSelectionMembersIds?.length} onPress={() => {
            membersCtx.setMultipleSelectionMembersIds([])
        }}>
            {i18n.t('clearSelection')}
        </Button>

        <Spacer/>

        {/* <Text>Selected Initial Members: {JSON.stringify(selectedGroupInitialMembersIds?.current)}</Text> */}

        {
            membersCtx?.state?.filteredMembers?.map((member, memberIndex) => {

                const isMemberChecked = multipleSelectionContext?.state?.multipleSelectionMembersIds && multipleSelectionContext.state.multipleSelectionMembersIds.includes(member.id)

                const isMemberSelectionDisabled = useGroupsContextOnMultipleSelection === true && selectedGroupInitialMembersIds.current.includes(member.id)

                return <Box key={'member_' + memberIndex.toString()} style={styles.memberPreviewContainer}>

                    <Checkbox size="sm" aria-labelledby='onlyDefaultMembers' 
                        isChecked={isMemberChecked}
                        isDisabled={isMemberSelectionDisabled}
                        onChange={value => {

                            if (value) {

                                multipleSelectionContext.setMultipleSelectionMembersIds(
                                    [member.id].concat(multipleSelectionContext.state.multipleSelectionMembersIds)
                                ) 
                            } else {

                                multipleSelectionContext.setMultipleSelectionMembersIds(
                                    multipleSelectionContext.state.multipleSelectionMembersIds.filter(id => id !== member.id)
                                )
                            }
                        }}
                    />

                    <MemberPreview member={member}/>

                    {showFilteredMembersDetails ? <Button onPress={() => {
                
                        membersCtx.setSelectedMemberId(member.id)

                        navigation.dispatch(
                            StackActions.push(
                                "MemberDetails"
                            )
                        )
                    }}>
                        {i18n.t('showDetails')}
                    </Button> : null}
                </Box>
            })
        }

        {
            !membersCtx?.state?.filteredMembers?.length ? <Text>{i18n.t('noData')}</Text> : null
        }

        <CustomAlert
            type='information'
            cancelRef={cancelRefAlert}
            isOpen={membersCtx?.state?.error}
            title="error"
            message="msgMembersFilterError"
            details={membersCtx?.state?.error === "msgUnkonwnError" ? i18n.t('msgUnkonwnError'): membersCtx?.state?.error }
            onAccept={() => {
                membersCtx.resetError()
            }}
        />

    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    },
    filtersOptionsContainer: {
        flex: 1,
        flexDirection: 'row'
    },
    memberPreviewContainer: {
        flexDirection: 'row'
    }
})

export default MembersFilters;