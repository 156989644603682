import { query, where, getDocs, getDoc, collection, getFirestore, updateDoc, doc, deleteField, getCountFromServer } from "firebase/firestore"


const existsFieldValuesInCollection = async (collectionName, fieldNames, data) => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, collectionName)
    )

    console.log("Check FieldNames: ", fieldNames)
    
    fieldNames.forEach(fieldName => {

        if (fieldName === undefined || data[fieldName] === undefined) {

            return true
        }

        q = query(
            q,
            where(
                fieldName,
                "==",
                data[fieldName]
            )
        )
    })    
    
    try {

        const snapshot = await getDocs(q)

        if (!snapshot.empty) {
           
            return true
        } else {

            return false
        }

        // const result = new Promise((resolve, reject) => {

            // snapshot.forEach(doc => {

            //     if (doc.data().id !== data.id) {

            //         resolve("Not unique")
            //     }                
            // })
           
        //     resolve(true)
        // })

        // return result
    } catch(error) {

        return error
    }
}



export { existsFieldValuesInCollection }