import React, { useState, useEffect, useRef, useContext } from 'react';
import { StyleSheet } from 'react-native';
import { AlertDialog, Box, Text, Button, FormControl, Input, Select, FlatList, Checkbox } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as GroupsContext } from '../../context/GroupsContext'
import { Context as UIContext } from '../../context/UIContext'

import { 
    createFirestoreGroup, 
    deleteFirestoreGroup, 
    fetchGroupByGroupId, 
    getDefaultGroup, 
    updateFirestoreGroup, 
    validateGroup, 
    isGroupEqual, 
    checkGroupUniqueFields,
    fetchMembersForGroupId,
    removeMemberFromGroup,
    applyGroupsFilters
} from '../../api/groups';

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

import Spacer from '../Spacer';

import { capitalizeFirstLetterFromWords } from '../../api/string';
import { hasOwnProperties } from '../../api/object';
import MemberPreview from '../members/MemberPreview';
import CustomAlert from '../CustomAlert';
import { cloudLogger } from '../../api/logger';


const i18n = new I18n({
    ...AppTranslations
  });
  
i18n.enableFallback = true;

const GroupDetails = ({createGroup, editInProgress, setEditInProgress, navigation}) => {

    const authCtx = useContext(AuthContext)
    const groupsCtx = useContext(GroupsContext)
    const uiCtx = useContext(UIContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const initialGroup = useRef({})
    const [group, setGroup] = useState({})
    const [fetchGroupMembers, setFetchGroupMembers] = useState(new Date())

    const [errors, setErrors] = useState({})

    const [groupMembers, setGroupMembers] = useState([])
   
    const alertTitle = useRef('confirmationWindow')
    const cancelRefAlert = useRef()
    const messageAlert = useRef('')
    const onCancelAlert = useRef()
    const onAcceptAlert = useRef()

    const errorDetails = useRef()

    const [isOpenConfirmationWindow, setIsOpenConfirmationWindow] = useState(false)
    const [isOpenInformationWindow, setIsOpenInformationWindow] = useState(false)

    const updateGroupMembers = async () => {

        if (group?.iterable !== true) {

            return
        }

        try {

            const groupMembers = await fetchMembersForGroupId(groupsCtx.state.selectedGroupId)
            setGroupMembers(groupMembers)

            groupsCtx.setMultipleSelectionMembersIds(
                groupMembers.map(gm => gm.id)
            )
        } catch(error) {

            setGroupMembers([])

            alertTitle.current = "error"
            messageAlert.current = "msgUnableToFetchGroupMembers"
            errorDetails.current = error

            onAcceptAlert.current = () => {

                setIsOpenInformationWindow(false)
                navigation.dispatch(StackActions.pop())
            }

            setIsOpenInformationWindow(true)
        }
    }

    // If members are deleted or when group is fetched
    useEffect(() => {

        updateGroupMembers()
    }, [fetchGroupMembers, group])

    useEffect(() => {

        const unsubscribe = navigation.addListener('focus', () => {

            // group is stil undefined and can not check iterable property
            // updateGroupMembers()

            setFetchGroupMembers(new Date())
        });
      
         // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
        
    }, [navigation])


    useEffect(() => {

        if (groupsCtx?.state?.selectedGroupId) {

            (async() => {

                try {

                    const group = await fetchGroupByGroupId(groupsCtx?.state?.selectedGroupId)

                    cloudLogger('info', `View Groups Details: ${JSON.stringify(group)}`)

                    setGroup(group)

                    initialGroup.current = group
                } catch(error) {

                    alertTitle.current = "error"
                    messageAlert.current = "msgUnableToFetchGroupDetails"
                    errorDetails.current = error

                    onAcceptAlert.current = () => {

                        setIsOpenInformationWindow(false)
                        navigation.dispatch(StackActions.pop())
                    }

                    setIsOpenInformationWindow(true)
                }
            })()
        } else {

            setGroup(getDefaultGroup())
        }

    }, [])

    return <Box>
        {/* <Text> Group Details </Text>
        
        <Text>Create: {JSON.stringify(createGroup)} </Text>
        <Text>GroupId: {JSON.stringify(groupsCtx?.state?.selectedGroupId)} </Text>
        
        <Text>Group Members Ids: {JSON.stringify(groupsCtx?.state?.multipleSelectionMembersIds)}</Text>

        <Spacer/> */}

        <FormControl isRequired={(createGroup || editInProgress) && ["admin", "leader"].includes(authCtx?.state?.enoaUser?.role) ? true : false} 
            isInvalid={'name' in errors}
        >
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('name')}
            </FormControl.Label>
            <Input placeholder={i18n.t('name')} 
                isDisabled={(!createGroup && !editInProgress) || !["admin", "leader"].includes(authCtx?.state?.enoaUser?.role) ? true : false}
                value={group.name}
                onChangeText={value => setGroup({...group, name: capitalizeFirstLetterFromWords(value)})}
            />
            
            {
                'name' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.name) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgNameIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        {/* <Text>Group Members: {JSON.stringify(groupMembers)}</Text> */}

        {
            !createGroup && groupMembers?.length > 0 ? 
                groupMembers.map(member => <Box style={styles.groupMemberContainer} key={member.id}>
                    <MemberPreview member={member}/>
                    <Button onPress={async () => {

                        if (await removeMemberFromGroup(group.id, member.id) === true) {

                            cloudLogger('warn', `Member ${member.id} removed from Group ${group.id}`)

                            setFetchGroupMembers(new Date())
                        }
                    }}>
                        {i18n.t('delete')}
                    </Button>
                </Box>) :
                null
        }

        <Spacer/>

        { createGroup === true ? <Button onPress={async () => {
            
            const saveGroup = async () => {

                group.name = group.name.trim()

                console.log("Save group: ", group)

                const res = await createFirestoreGroup(group)

                if (res !== true) {

                    const errors = await checkGroupUniqueFields(group, initialGroup)

                    if (hasOwnProperties(errors)) {

                        console.log("Create group errors: ", errors)

                        setErrors(errors)
                        return errors
                    }
                }

                return res
            }

            const runPostSaveGroup = () => {

                console.log("Update post save group")

                applyGroupsFilters(groupsCtx)

                navigation.dispatch(StackActions.pop())
            }

            if (validateGroup(group, setErrors)) {

                messageAlert.current = 'msgAreYouSureYouWantToSaveTheNewGroup'
                alertTitle.current = 'confirmationWindow'
                errorDetails.current = null
                uiCtx.setDisableCustomAlertButtons(false)

                onAcceptAlert.current = async () => {

                    uiCtx.setDisableCustomAlertButtons(true)

                    const result = await saveGroup()

                    uiCtx.setDisableCustomAlertButtons(false)
                    setIsOpenConfirmationWindow(false)

                    if (result === true) {

                        alertTitle.current = 'success'
                        messageAlert.current = 'msgGroupCreated'

                        onAcceptAlert.current = () => {

                            setIsOpenInformationWindow(false)

                            // Update application data
                            runPostSaveGroup()
                        }

                        setIsOpenInformationWindow(true)
                    } else {

                        alertTitle.current = 'error'
                        messageAlert.current = 'msgUnableToSaveNewGroup'
                        errorDetails.current = JSON.stringify(result)

                        onAcceptAlert.current = () => {

                            setIsOpenInformationWindow(false)    
                        }

                        setIsOpenInformationWindow(true)
                    }
                }
    
                onCancelAlert.current = () => {
    
                    setIsOpenConfirmationWindow(false)
                }
    
                // Last step should be use state, to render the updated references
                setIsOpenConfirmationWindow(true)
            }
        }}>
            {i18n.t('save')}
        </Button>: null}

        { ["admin", "leader"].includes(authCtx?.state?.enoaUser?.role) && !createGroup && !editInProgress && !group.default ? 
            <Button onPress={() => {
                setEditInProgress(true)
            }}>
                {i18n.t('edit')}
            </Button>
            : null
        }

        {["admin", "leader"].includes(authCtx?.state?.enoaUser?.role) && !createGroup && !group.default ? <Button isDisabled={editInProgress} onPress={() => {

            const deleteGroup = async () => {

                console.log("Delete group: ", group)

                const res = await deleteFirestoreGroup(group.id)

                // TODO: Delete all links between members and group

                return res
            }

            const runPostDeleteGroup = () => {

                console.log("Update post delete group")

                applyGroupsFilters(groupsCtx)

                groupsCtx.setSelectedGroupId(null)

                navigation.dispatch(StackActions.pop())
            }

            messageAlert.current = 'msgAreYouSureYouWantToDeleteThisMember'
            alertTitle.current = 'confirmationWindow'
            errorDetails.current = null
            uiCtx.setDisableCustomAlertButtons(false)

            onAcceptAlert.current = async () => {

                uiCtx.setDisableCustomAlertButtons(true)
                const result = await deleteGroup()

                uiCtx.setDisableCustomAlertButtons(false)
                setIsOpenConfirmationWindow(false)

                if (result === true) {

                    alertTitle.current = 'success'
                    
                    messageAlert.current = 'msgGroupDeleted'

                    onAcceptAlert.current = () => {

                        setIsOpenInformationWindow(false)

                        // Update application data
                        runPostDeleteGroup()
                    }

                    setIsOpenInformationWindow(true)
                } else {

                    alertTitle.current = 'error'
                    messageAlert.current = 'msgUnableToDeleteGroup'
                    errorDetails.current = JSON.stringify(result)
                    
                    onAcceptAlert.current = () => {

                        setIsOpenInformationWindow(false)    
                    }

                    setIsOpenInformationWindow(true)
                }

            }

            onCancelAlert.current = () => {

                setIsOpenConfirmationWindow(false)    
            }

            // Last step should be use state, to render the updated references
            setIsOpenConfirmationWindow(true)
        }}>
            { i18n.t('delete')}
        </Button> : null }

        { createGroup === false && editInProgress ? <Button isDisabled={isGroupEqual(initialGroup.current, group)} 
            onPress={async () => {

                const updateGroup = async () => {

                    group.name = group.name.trim()

                    console.log("Update group: ", group)

                    const res = await updateFirestoreGroup(group)

                    if (res !== true) {

                        const errors = await checkGroupUniqueFields(group, initialGroup.current)

                        if (hasOwnProperties(errors)) {

                            console.log("Update group errors: ", errors)

                            setErrors(errors)
                            return errors
                        }
                    }

                    return res
                }

                const runPostUpdateGroup = async () => {

                    console.log("Update post update group")

                    setEditInProgress(false)

                    const group = await fetchGroupByGroupId(groupsCtx?.state?.selectedGroupId)
                    setGroup(group)

                    initialGroup.current = group

                    groupsCtx.resetFilters()                
                    applyGroupsFilters(groupsCtx)
                }

                if (validateGroup(group, setErrors)) {

                    messageAlert.current = 'msgAreYouSureYouWantToUpdateThisGroup'
                    alertTitle.current = 'confirmationWindow'
                    errorDetails.current = null
                    uiCtx.setDisableCustomAlertButtons(false)

                    onAcceptAlert.current = async () => {

                        uiCtx.setDisableCustomAlertButtons(true)

                        const result = await updateGroup()
                        
                        uiCtx.setDisableCustomAlertButtons(false)
                        setIsOpenConfirmationWindow(false)
    
                        if (result === true) {
    
                            alertTitle.current = 'success'
    
                            messageAlert.current = 'msgGroupUpdated'
                        
                            onAcceptAlert.current = () => {
    
                                setIsOpenInformationWindow(false)
    
                                // Update application data
                                runPostUpdateGroup()
                            }
    
                            setIsOpenInformationWindow(true)
                        } else {
    
                            alertTitle.current = 'error'                        
                            messageAlert.current = 'msgUnableToUpdateGroup'
                            errorDetails.current = JSON.stringify(result)
                        
                            onAcceptAlert.current = () => {
    
                                setIsOpenInformationWindow(false)    
                            }
    
                            setIsOpenInformationWindow(true)
                        }
                    }
        
                    onCancelAlert.current = () => {
        
                        setIsOpenConfirmationWindow(false)    
                    }
        
                    // Last step should be use state, to render the updated references
                    setIsOpenConfirmationWindow(true)
                }
            }}
        >
            {i18n.t('update')}
        </Button> : null}

        <Button isDisabled={!createGroup && !editInProgress} onPress={() => {

            if (createGroup === false) {

                messageAlert.current = 'msgAreYouSureYouCancelGroupEdit'
                alertTitle.current = 'confirmationWindow'

                onAcceptAlert.current = () => {

                    setEditInProgress(false)
                    setGroup(initialGroup.current)
                }            
            } else {

                messageAlert.current = 'msgAreYouSureYouCancelGroupCreate'
                alertTitle.current = 'confirmationWindow'

                onAcceptAlert.current = () => {

                    navigation.dispatch(StackActions.pop())
                }
            }

            onCancelAlert.current = () => {

                setIsOpenConfirmationWindow(false)
            }

            // Last step should be use state, to render the updated references
            setIsOpenConfirmationWindow(true)
            
        }}>
            {i18n.t('cancel')}
        </Button>

        <CustomAlert
            type='confirmation'
            cancelRef={cancelRefAlert}
            isOpen={isOpenConfirmationWindow}
            title={alertTitle.current}
            message={messageAlert.current}
            onCancel={onCancelAlert.current}
            onAccept={onAcceptAlert.current}
        />

        <CustomAlert
            type='information'
            cancelRef={cancelRefAlert}
            isOpen={isOpenInformationWindow}
            title={alertTitle.current}
            message={messageAlert.current}
            details={errorDetails.current}
            onAccept={onAcceptAlert.current}
        />
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    },
    groupMemberContainer: {
        flexDirection: 'row'
    }
})

export default GroupDetails;