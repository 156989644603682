import { query, where, getDocs, getDoc, collection, getFirestore, updateDoc, doc, deleteField, getCountFromServer } from "firebase/firestore"

const fetchFirestoreCountiesNames = async () => {

    const counties = await fetchFirestoreCounties()
    
    return counties.map(c => c.name)
}

const fetchFirestoreCounties = async () => {

    const firestore = getFirestore()

    // const querySnapshot = await getDocs(collection(db, "cities"));
    // querySnapshot.forEach((doc) => {
    //     // doc.data() is never undefined for query doc snapshots
    //     console.log(doc.id, " => ", doc.data());
    // });

    return getDocs(collection(firestore, "static/locations/counties"))
        .then(countiesSnapshot => Promise.all(
            countiesSnapshot.docs.map(countyDoc => countyDoc.data())
        ))
}

const fetchFirestoreCountyByName = async county => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, "static/locations/counties")
    )
    q = query(
        q,
        where(
            "name",
            "==",
            county
        )
    )

    try {

        const snapshot = await getDocs(q)

        if (snapshot.empty) {
            
            return null
        }

        const result = snapshot.docs.map(doc => {

            return doc.data()
        })

        // county names are unique, only one result
        return result[0]

    } catch(error) {

        return null
    }
}

export { fetchFirestoreCountiesNames, fetchFirestoreCounties, fetchFirestoreCountyByName }