
import { getAuth, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';


// Returns error or falsy value on success
const phoneSignin = async authDetails => {

    // console.log("Phone signin in progress: ", authDetails)

    const credential = getFirebasePhoneAuthCredential(authDetails)
       
    // console.log("Phone Auth Credential: " + JSON.stringify(credential))

    try {    
        
        const firebaseAuthenticationResult = await firebasePhoneSignin(credential);    

        // console.log("Phone firebaseAuthenticationResult: " + JSON.stringify(firebaseAuthenticationResult))

        if (!firebaseAuthenticationResult || firebaseAuthenticationResult.error) {

            error = "Unable to register Phone Authentication to Firebase. "  + JSON.stringify(firebaseRegistrationResult ? firebaseRegistrationResult.error : "Account disabled")

            console.error(error)

            return {error}
        }
    } catch(error) {

        throw ("Not a member")
    }

    console.log("Phone Successfuly authenticated")

    return null
}

const getFirebasePhoneAuthCredential = ({ phoneVerificationId, phoneVerificationCode }) => {

    console.log("phoneVerificationId: " + phoneVerificationId + "; phoneVerificationCode: " + phoneVerificationCode)

    return PhoneAuthProvider.credential(
        phoneVerificationId,
        phoneVerificationCode
    );   
}

const firebasePhoneSignin = async (credential) => {

    return await signInWithCredential(getAuth(), credential);
}

const isValidPhoneNumber = phoneNumber => {

     if (!phoneNumber || !/^\d+$/.test(phoneNumber) || phoneNumber.length != 10 || phoneNumber.substr(0,2) !== '07') {

        return false
     }

     return true
}

const isNormalizedPhoneNumber = phoneNumber => {

    if (!isValidPhoneNumber(phoneNumber.substr(2)) || phoneNumber.substr(0,2) !== '+4') {

        return false;
    }

    return true
}

const normalizePhoneNumber = phoneNumber => {

    if (!isValidPhoneNumber(phoneNumber)) {

        return phoneNumber
    }

    return "+4" + phoneNumber
}

export { phoneSignin, isValidPhoneNumber, isNormalizedPhoneNumber, normalizePhoneNumber }