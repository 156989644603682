
import createDataContext from './createDataContext';

const membersReducer = (state, action) => {
    
    switch (action.type) {

        case 'resetFilters':

            // A new object must be created, to triger rerendering
            return {
                ...state, 
                filters: {},
                filteredMembers: [],
                currentPage: 1
            }
        case 'setFilters': 

            const filters = action.payload

            return {
                ...state,
                filters
            }
        
        case 'setFilteredMembers': 

            const filteredMembers = action.payload

            return {
                ...state,
                filteredMembers
            }

        case 'setFilteredMembersCount': 

            const filteredMembersCount = action.payload

            return {
                ...state,
                filteredMembersCount
            }
        
        case 'setSelectedMemberId': 

            const selectedMemberId = action.payload

            return {
                ...state,
                selectedMemberId
            }

            
        case 'setMultipleSelectionMembersIds': 

            const multipleSelectionMembersIds = action.payload

            return {
                ...state,
                multipleSelectionMembersIds
            }

        case 'addMemberIdToMultipleSelectionMembersIds': 

            return {
                ...state,
                multipleSelectionMembersIds: state.multipleSelectionMembersIds.concat([action.payload])
            }

        case 'addMemberIdToMultipleSelectionMembersIds': 

            return {
                ...state,
                multipleSelectionMembersIds: state.multipleSelectionMembersIds.filter(memberId => memberId != action.payload)
            }

            
        case 'setMultipleSelectionGroupsIds': 

            const multipleSelectionGroupsIds = action.payload

            return {
                ...state,
                multipleSelectionGroupsIds
            }

        case 'setCurrentPage': 

            const currentPage = action.payload
            return {
                ...state,
                currentPage
            }

        case 'setFiltersError': 

            return {
                ...state,
                filtersError: action.payload
            }

        case 'resetFiltersError': 

            delete state.filtersError
            
            return {
                ...state
            }
            
        default:
            return state;
    }
}

const resetFilters = dispatch => () => {

    dispatch({ type: 'resetFilters' })
}

const setFilters = dispatch => filters => {

    dispatch({ type: 'setFilters', payload: filters })
}

const setFilteredMembers = dispatch => filteredMembers => {

    dispatch({ type: 'setFilteredMembers', payload: filteredMembers })
}

const setFilteredMembersCount = dispatch => filteredMembersCount => {

    dispatch({ type: 'setFilteredMembersCount', payload: filteredMembersCount })
}

const setSelectedMemberId = dispatch => selectedMemberId => {

    dispatch({ type: 'setSelectedMemberId', payload: selectedMemberId })
}

const setMultipleSelectionMembersIds = dispatch => multipleSelectionMembersIds => {

    dispatch({ type: 'setMultipleSelectionMembersIds', payload: multipleSelectionMembersIds })
}

const addMemberIdToMultipleSelectionMembersIds = dispatch => memberId => {

    dispatch({ type: 'addMemberIdToMultipleSelectionMembersIds', payload: memberId })
}

const removeMemberIdToMultipleSelectionMembersIds = dispatch => memberId => {

    dispatch({ type: 'removeMemberIdToMultipleSelectionMembersIds', payload: memberId })
}

const setMultipleSelectionGroupsIds = dispatch => multipleSelectionGroupsIds => {

    dispatch({ type: 'setMultipleSelectionGroupsIds', payload: multipleSelectionGroupsIds })
}

const setCurrentPage = dispatch => currentPage => {

    dispatch({ type: 'setCurrentPage', payload: currentPage })
}

const setFiltersError = dispatch => error => {

    dispatch({ type: 'setFiltersError', payload: error})
}

const resetFiltersError = dispatch => () => {

    dispatch({ type: 'resetFiltersError'})
}

const setMemberDetailsError = dispatch => error => {

    dispatch({ type: 'setMemberDetailsError', payload: error})
}

const resetMemberDetailsError = dispatch => () => {

    dispatch({ type: 'resetMemberDetailsError'})
}

export const { Provider, Context } = createDataContext(
    membersReducer,
    { 
        resetFilters, 
        setFilters, 
        setFilteredMembers, 
        setFilteredMembersCount, 
        setSelectedMemberId,
        setMultipleSelectionMembersIds,
        addMemberIdToMultipleSelectionMembersIds,
        removeMemberIdToMultipleSelectionMembersIds,
        setMultipleSelectionGroupsIds,
        setCurrentPage,
        setFiltersError,
        resetFiltersError
    },
    {
        filters: {},
        filteredMembers: [],
        filteredMembersCount: 0,
        selectedMemberId: null,
        multipleSelectionMembersIds: [],
        multipleSelectionGroupsIds: [],
        pageSize: 10,
        currentPage: 1,
        maxIterableMembers: 40
    }
);


