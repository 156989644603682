import React, { useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useColorModeValue, useSafeArea, Box, Button, Text } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as GroupsContext } from '../context/GroupsContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
import GroupDetails from '../components/groups/GroupDetails';
import { sendEmailToGroups, sendSMSToGroups } from '../api/actions';

// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const GroupDetailsScreen = ({ navigation, route }) => {

    const authCtx = useContext(AuthContext)
    const groupsCtx = useContext(GroupsContext)
    
    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [editInProgress, setEditInProgress] = useState(route?.params?.createGroup === true ? true : false)

    return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>

        {/* <Text> Group Details Screen </Text>

        <Text>Group Initial members (Group details screen) : {JSON.stringify(groupsCtx?.state?.multipleSelectionMembersIds)}</Text> */}

        <GroupDetails 
            createGroup={route?.params?.createGroup === true ? true : false} 
            editInProgress={editInProgress} setEditInProgress={setEditInProgress}
            navigation={navigation}
        />

        {route?.params?.createGroup ? null : <Button.Group>

            <Button isDisabled={editInProgress} onPress={() => {

                groupsCtx.setSelectedGroupId(null)
                navigation.dispatch(StackActions.pop())
            }}>
                    { i18n.t('back') }
            </Button>

            <Button isDisabled={editInProgress} onPress={() => {
                
                groupsCtx.setSelectedGroupId(null)
                navigation.dispatch(StackActions.popToTop())
            }}>
                    { i18n.t('home') }
            </Button>

            { groupsCtx?.state?.selectedGroupId?.length > 5 ? <Button isDisabled={editInProgress} onPress={ () => {

                navigation.dispatch(
                    StackActions.push(
                        "GroupAddMembers"
                    )
                )
            }}>
                {i18n.t('addMembers')}
            </Button> : null}

            <Button onPress={async () => {

                // TODO Open a confirmation window with costs computed
                sendEmailToGroups([groupsCtx.state.selectedGroupId])
            }}>
                {i18n.t('sendEmailToGroup')}
            </Button>

            <Button onPress={async () => {

                // TODO Open a confirmation window with costs computed
                sendSMSToGroups([groupsCtx.state.selectedGroupId])
            }}>
                {i18n.t('sendSMSToGroup')}
            </Button>
        </Button.Group> }
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default GroupDetailsScreen;