export default {
    theme: "Tema",
    lightTheme: "Tema deschisa",
    darkTheme: "Tema inchisa",
    back: "Inapoi",
    home: "Acasa",
    edit: "Editeaza",
    update: "Actualizeaza",
    save: "Salveaza",
    delete: "Sterge",
    details: "Detalii",
    showPrivate: "Arata datele private",
    hidePrivate: "Ascunde datele private",
    newMember: "Membru Nou",
    settings: "Setari",
    romanian: "Romana",
    hungarian: "Maghiara",
    authentication: "Autentificare",
    emailAddress: 'Adresa de email',
    or: "sau",
    phoneNumber: "Numar de Telefon",
    signout: "Delogare",
    signoutFromAllDevices: "Deconectare de pe toate dispozitivele",
    verificationCode: "Cod de Verificare",
    verify: "Verifica",
    cancel: "Anuleaza",
    msgSuccessfulAuthentication: "Te-ai autentificat cu succes. Inchide acest tab si continua in tab-ul initial",
    closeThisTab: 'Inchide acest tab',
    emailAuthenticationFailed: "Autentificarea cu email a esuat",
    error: "Eroare",
    success: "Succes",
    wait: "Asteapta",
    smsNotSent: "SMS-ul un a putut fi trimis",
    emailNotSent: "email-ul nu a putut fi trimis",
    invalidAuthentication: "Autentificare nereusita",
    invalidAuthenticationData: "Email sau numar de telefon incorect",
    msgUpdateRecaptcha: "Te poti autentifica dupa ce urmezi pasii reCAPTCHA",
    msgAuthEmailJustSent: "A fost trimis un email pentru autentificare. Verifica inclusiv in Spam",
    msgAuthSMSJustSent: "A fost trimis un SMS cu codul pentru autentificare.",
    msgNotAMember: "Nu te poti autentifica cu metoda folosita. Daca esti membru NOA contacteaza filiala de care apartii. Aceasta incercare de autentificare a fost inregistrata!",
    members: "Membrii",
    groups: "Grupuri",
    payments: "Cotizatie",
    polls: "Sondaje",
    myData: "Datele mele",

    msgNameNotFilledIn: "Numele nu este completat",
    msgNameTooShort: "Numele este prea scurt",
    msgNameMustContainOnlyLettersAndSpaces: "Numele trebuie sa contina doar litere si spatii",
    msgSurnameNotFilledIn: "Preumele nu este completat",
    msgSurnameTooShort: "Prenumele este prea scurt",
    msgSurnameMustContainOnlyLettersAndSpaces: "Preumele trebuie sa contina doar litere si spatii",
    msgPhoneNotFilledIn: "Numarul de telefon nu este completat",
    msgInvalidPhoneNumber: "Numarul de terlefon este invalid",
    msgEmailNotFilledIn: "Adresa de email nu este completata",
    msgInvalidEmailAddress: "Adresa de email este invalida",
    msgCountyNotSelected: "Judetul nu este selectat",
    msgLocalityNotSelected: "Localitatea nu este selectata",
    msgIcSeriesNotFilledIn: "Seria cartii de identitate nu este completata",
    msgIcSeriesInvalidLength: "Seria cartii de identitate are prea multe caractere",
    msgIcSeriesMustContainOnlyCapitalLetters: "Seria cartii de identitate trebuie sa contina doar litere majuscule",
    msgIcNumberNotFilledIn: "Numarul cartii de identitate nu este completat",
    msgIcNumberInvalidLength: "Numarul cartii de identitate trebuie sacontna 6 cifre",
    msgIcNumberMustContainOnlyDigits: "Numarul cartii de identitate trebuie sa contina doar cifre",
    msgMonthlyPaymentNotSelected: "Valoarea cotizatiei lunare nu este selectata",
    msgCNPInvalidNumberOfDigits: "CNP-ul trebuie sa contina 13 cifre",
    msgCNPInvalidCharacter: "CNP-ul contine caractere care nu sunt cifre",
    msgCNPInvalidFirstCharacter: "Prima cifra a CNP-ului este invalida",
    msgCNPInvalidYear: "CNP-ul contine un an invalid",
    msgCNPMemberTooYoung: "CNP-ul apartine unei persoane minore",
    msgCNPControlDigitIsNotValid: "Cifra de control a CNP-ului este invalida",
    msgCNPNotFilledIn: "CNP-ul nu este completat",
    
    president: "Presedinte",
    vicePresident: "Vice Presedinte",
    countyPresident: "Presedinte Filiala Judeteana",
    localPresident: "Presedinte Birou Local",
    admin: "Administrator",
    leader: "Lider",
    secretary: "Secretar",
    member: "Membru",
    engineer: "Inginer",
    lawyer: "Jurist",
    economist: "Economist",
    doctor: "Medic",
    professor: "Profesor",
    veterinary: "Veterinar",
    forester: "Silvicultor",
    environmentalist: "Ecologist",
    artist: "Artist",
    psychologist: "Psiholog",
    sociologist: "Sociolog",
    sportsman: "Sportiv",
    mechanic: "Meserias",

    name: "Nume",
    surname: "Prenume",
    county: "Judet",
    locality: " Localitate",
    specialization: "Specializare",
    function: "Functie",
    role: "Rol",
    politicalExperience: "Experienta Politica",
    registrationNumber: "Numar de inregistrare",
    nextPayDate: "Urmatoarea plata a cotizatiei",
    CNP: "Cod Numeric Personal(CNP)",
    icSeries: "Seria cartii de identitate",
    icNumber: "Numarul cartii de identitate",
    monthlyPayment: "Cotizatie lunara",

    msgUnableToLogPrivateDataAccess: "Nu s-a reusit salvarea solicitarii de acces la datele private.",
    msgAreYouSureYouWantToSaveTheNewMember: "Esti sigur ca vrei sa salvezi datele noului membru?",
    msgUnableToSaveNewMember: "Datele membrului nu au putut fi salvate",
    msgMemberCreated: "Datele membrului au fost adaugate in baza de date",
    msgAreYouSureYouWantToUpdateThisMember: "Esti sigur ca vrei sa actualizezi datele acestui membru?",
    msgUnableToUpdateMember: "Datele membrului nu au putut fi actualizate",
    msgMemberUpdated: "Datele membrului au fost actualizate in baza de date",
    msgAreYouSureYouWantToDeleteThisMember: "Esti sigur ca vrei sa stergi datele acestui membru?",
    msgUnableToDeleteMember: "Datele membrului nu au putut fi sterse",
    msgMemberDeleted: "Datele membrului au fost sterse in baza de date",
    msgAreYouSureYouCancelMemberCreate: "Esti sigur ca vrei sa renunti la crearea unui membru?",
    msgAreYouSureYouCancelMemberEdit: "Esti sigur ca vrei sa renunti la editatea datelor unui membru?",
    msgPresidentIsAreadySet: "Presedintele este deja setat",
    msgVicePresidentAreAready7: "Sunt deja 7 vicepresedinti",
    msgCountyPresidentIsAlreadySet: "Presedintele organizatiei judetene este deja setat",
    msgLocalOfficePresidentIsAlreadySet: "Presedintele biroului local este deja setat",

    msgAreYouSureYouWantToSaveTheNewGroup: "Esti sigur ca vrei sa salvezi datele noului grup?",
    msgUnableToSaveNewGroup: "Datele grupului nu au putut fi salvate",
    msgGroupCreated:"Datele grupului au fost adaugate in baza de date",
    msgAreYouSureYouWantToUpdateThisGroup: "Esti sigur ca vrei sa actualizezi datele acestui grup?",
    msgUnableToUpdateGroup: "Datele grupului nu au putut fi actualizate",
    msgGroupUpdated: "Datele grupului au fost actualizate in baza de date",
    msgAreYouSureYouWantToDeleteThisGroup: "Esti sigur ca vrei sa stergi datele acestui grup?",
    msgUnableToDeleteGroup: "Datele grupului nu au putut fi sterse",
    msgGroupDeleted: "Datele grupului au fost sterse in baza de date",
    msgAreYouSureYouCancelGroupCreate: "Esti sigur ca vrei sa renunti la crearea unui grup?",
    msgAreYouSureYouCancelGroupEdit: "Esti sigur ca vrei sa renunti la editatea datelor unui grup?",
    msgAreYouWantToAddMemberToSelectedGroups: 'Esti sigur ca vrei sa adaugi membrul la grupurile selectate?',
    msgAreYouWantToAddSelectedMembersToGroup: 'Esti sigur ca vrei sa adaugi membrii selectati la grup?',

    confirmationWindow: "Fereastra de Confirmare",
    msgExistsInDatabase: "Exista in baza de date",
    msgRegistrationNumberIsDuplicatedTryAgain: "Numarul de inregistrare se afla in baza de date. Mai salveaza odata. Daca nu reusesti sa salvezi, contacteaza echipa de suport",
    errorDetails: "Detalii eroare",

    applyFilters: "Aplica Filtre",
    next: "Inainte",
    previous: "Inapoi",
    clearSelection: "Deselecteaza tot",
    showDetails: "Arata Detalii",
    hideDetails: "Ascunde Detaliile",
    noResult: "Niciun rezultat",
    msgMaxIterableMembersInAFilterIs: "Numarul maxim de membri care este afisat in urma unei filtrari este de",
    msgMembersFilterError: "Eroare in la filtrarea membrilor",
    msgGroupsFilterError: "Eroare la filtrarea grupurilor",
    msgUnknownError: "Eroare necunoscuta",
    msgUnableToFetchMemberData: "Eroare la descarcarea datelor membrului",
    msgUnableToFetchMemberPrivateData: "Eroare la descarcarea datelor private ale membrului",
    msgUnableToFetchGroupMembers: "Eroare la descarcarea membrilor grupului",
    msgUnableToFetchGroupDetails: "Eroare la descarcarea detaliilor grupului",
    msgPaymentNotDone: "Cotizatia neplatita",

    newGroup: "Grup Nou",
    addMembers: "Adauga Membri",
    addSelectedMembers: "Adauga Membrii Selectati",
    removeGroup: "Sterge Grup",
    removeSelectedGroups: "Sterge Grupurile Selectate",
    sendEmailToGroup: "Trimite email catre grup",
    sendEmailToSelectedGroups: "Trimite email catre grupurile selectate",
    sendSMSToGroup: "Trimite SMS catre grup",
    sendSMSToSelectedGroups: "Trimite SMS catre grupurile selectate",

    addSelectedMembers: "Adauga membrii selectati",
    msgOnlyDefaultGroups: "Doar grupuri implicite(National, Judetene si Functii)",
    msgOnlyNonDefaultGroups: "Doar grupuri create manual",

    newMember: "Membru Nou",
    addGroups: "Adauga Grupuri",
    addSelectedGroups: "Adauga Grupurile Selectate",
    sendEmailToMember: "Trimite-i email",
    sendEmailToSelectedMembers: "Trimite email catre membrii selectati",
    sendSMSToMember: "Trimite-i SMS",
    sendSMSToSelectedMembers: "Trimite SMS catre membrii selectati",

    payment: "Cotizatie",
    identityCard: "Carte de Identitate",
    msgPaymentWithDelayGreaterThan: "Intarziere la plata cotizatiei mai mare ca",
    payDateOffset: "Intarziere plata cotizatie"
}