import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, Text, FormControl, Input, FlatList, Select } from 'native-base'

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as MembersContext } from '../../context/MembersContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

import { hasOwnProperties } from '../../api/object';
import { fetchRoles } from '../../api/members';

const i18n = new I18n({
    ...AppTranslations
  });
  
i18n.enableFallback = true;

const MemberRoleFilter = () => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [errors, setErrors] = useState({})
    const [roles, setRoles] = useState([])

    useEffect(() => {

        (async() => {
            setRoles(await fetchRoles())      
        })()
    }, [])
    
    return <Box>

        {/* <Text>Errors: {JSON.stringify(errors)} </Text> */}
        
        <FormControl isInvalid={'role' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('role')}
            </FormControl.Label>
            
            <Select
                selectedValue={membersCtx?.state?.filters?.role} 
                placeholder={i18n.t('role')}
                onValueChange={ (role) => {                   
                    membersCtx.setFilters({
                        ...membersCtx.state.filters,
                        role
                    })
                }}
            >
                {
                    
                    roles.map((role, roleIndex) => (
                        <Select.Item key={roleIndex} label={i18n.t(role)} value={role} />
                    ))
                    
                }
            </Select>
            
            {
                'role' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.role) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgRoleIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        borderWidth: 1
    },
    backgroundImage: {
        flex: 1
    }
})

export default MemberRoleFilter;