import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

const cloudLogger = async (level, message) => {

    if (!level || !message) {

        return false
    }
  
    // Region must be specified
    const functions = getFunctions(getApp(), 'europe-west3')

    const callCloudLogger = httpsCallable(
        functions, 
        'cloudLogger', 
        {
            limitedUseAppCheckTokens: true
        } 
    )

    try {

        // Auth data are fetched from firebase user provider data in cloud function
        const result = await callCloudLogger({
            level,
            message
        })

        return result.data

    } catch (err) {

        return false
    }
}

export { cloudLogger }
