
import createDataContext from './createDataContext';

const groupsReducer = (state, action) => {
    
    switch (action.type) {

        case 'resetFilters':

            // A new object must be created, to triger rerendering
            return {
                ...state, 
                filters: {},
                filteredGroups: [],
                currentPage: 1
            }
        case 'setFilters': 

            const filters = action.payload

            return {
                ...state,
                filters
            }
        
        case 'setFilteredGroups': 

            const filteredGroups = action.payload

            return {
                ...state,
                filteredGroups
            }

        case 'setFilteredGroupsCount': 

            const filteredGroupsCount = action.payload

            return {
                ...state,
                filteredGroupsCount
            }
        
        case 'setSelectedGroupId': 

            const selectedGroupId = action.payload

            return {
                ...state,
                selectedGroupId
            }

            
        case 'setMultipleSelectionGroupsIds': 

            const multipleSelectionGroupsIds = action.payload

            return {
                ...state,
                multipleSelectionGroupsIds
            }

        case 'addGroupIdToMultipleSelectionGroupsIds': 

            return {
                ...state,
                multipleSelectionGroupsIds: state.multipleSelectionGroupsIds.concat([action.payload])
            }

        case 'addGroupIdToMultipleSelectionGroupsIds': 

            return {
                ...state,
                multipleSelectionGroupsIds: state.multipleSelectionGroupsIds.filter(groupId => groupId != action.payload)
            }

        case 'setMultipleSelectionMembersIds':

            const multipleSelectionMembersIds = action.payload
            
            return {
                ...state,
                multipleSelectionMembersIds
            }

        case 'setCurrentPage': 

            const currentPage = action.payload
            return {
                ...state,
                currentPage
            }

        case 'setError': 

            return {
                ...state,
                error: action.payload
            }

        case 'resetError': 

            delete state.error
            
            return {
                ...state
            }
            

            
        default:
            return state;
    }
}

const resetFilters = dispatch => () => {

    dispatch({ type: 'resetFilters' })
}

const setFilters = dispatch => filters => {

    dispatch({ type: 'setFilters', payload: filters })
}

const setFilteredGroups = dispatch => filteredGroups => {

    dispatch({ type: 'setFilteredGroups', payload: filteredGroups })
}

const setFilteredGroupsCount = dispatch => filteredGroupsCount => {

    dispatch({ type: 'setFilteredGroupsCount', payload: filteredGroupsCount })
}

const setSelectedGroupId = dispatch => selectedGroupId => {

    dispatch({ type: 'setSelectedGroupId', payload: selectedGroupId })
}

const setMultipleSelectionGroupsIds = dispatch => multipleSelectionGroupsIds => {

    dispatch({ type: 'setMultipleSelectionGroupsIds', payload: multipleSelectionGroupsIds })
}

const addGroupIdToMultipleSelectionGroupsIds = dispatch => groupId => {

    dispatch({ type: 'addGroupIdToMultipleSelectionGroupsIds', payload: groupId })
}

const removeGroupIdToMultipleSelectionGroupsIds = dispatch => groupId => {

    dispatch({ type: 'removeGroupIdToMultipleSelectionGroupsIds', payload: groupId })
}

const setMultipleSelectionMembersIds = dispatch => multipleSelectionMembersIds => {

    dispatch({ type: 'setMultipleSelectionMembersIds', payload: multipleSelectionMembersIds })
}

const setCurrentPage = dispatch => currentPage => {

    dispatch({ type: 'setCurrentPage', payload: currentPage })
}

const setError = dispatch => error => {

    dispatch({ type: 'setError', payload: error})
}

const resetError = dispatch => () => {

    dispatch({ type: 'resetError'})
}

export const { Provider, Context } = createDataContext(
    groupsReducer,
    { 
        resetFilters, 
        setFilters, 
        setFilteredGroups, 
        setFilteredGroupsCount, 
        setSelectedGroupId,
        setMultipleSelectionGroupsIds,
        addGroupIdToMultipleSelectionGroupsIds,
        removeGroupIdToMultipleSelectionGroupsIds,
        setMultipleSelectionMembersIds,
        setCurrentPage,
        setError,
        resetError 
    },
    {
        filters: {},
        filteredGroups: [],
        filteredGroupsCount: 0,
        selectedGroupId: null,
        multipleSelectionGroupsIds: [],
        multipleSelectionMembersIds: [],
        pageSize: 10,
        currentPage: 1
    }
);


