import React from 'react';
import { ActivityIndicator, ImageBackground, StyleSheet } from 'react-native';
import { Box, Text } from 'native-base'

const Loading = () => {
    return <ImageBackground source={require('../../assets/splash.png')} style={styles.backgroundImage}>
        <Box style={styles.container}>
                <Text>Loading</Text>
                <ActivityIndicator size="large" color="#1979a9" />
        </Box>
    </ImageBackground>;
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default Loading;