import { initializeAuth, /*getReactNativePersistence*/ } from 'firebase/auth';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import ReactNativeAsyncStorage from '@react-native-async-storage/async-storage';

import firebase from 'firebase/compat/app'
import * as Device from 'expo-device'

import Constants from 'expo-constants'

const initApp = () => {


    console.log("Firebase config: ", 
        Constants.expoConfig.FirebaseConfig.projectId
    )

    let app

    if (Device.brand) {
        
        /*initializeApp(
            Constants.expoConfig.FirebaseConfig
        )

        app = getApp();
        try{
            const auth = initializeAuth(app, {
                persistence: getReactNativePersistence(ReactNativeAsyncStorage)
            });
        } catch (e) {

        }*/
    } else {

        app = firebase.initializeApp(
            Constants.expoConfig.FirebaseConfig
        )
    }

    // TODO: Only in debug
    //self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

    initializeAppCheck(
        app,
        {
            provider: new ReCaptchaV3Provider(
                Constants.expoConfig.FirebaseConfig.projectId === 'e-noa-ee0b4'
                ? '6LftfRQpAAAAAM1h1llLVVH35tuyuG7sCKLNasBh'
                : '6LeyJpMoAAAAAGnc1acD8Pv1cQEWAkqk2Aai68EI'
            ), 

            // Optional argument. If true, the SDK automatically refreshes App Check
            // tokens as needed.
            isTokenAutoRefreshEnabled: true

        } // ReCaptchaV3Provider or CustomProvider
    );
}

export default initApp