import React, { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useColorModeValue, useSafeArea, Box, Button, Text } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import Spacer from '../components/Spacer';

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as GroupsContext } from '../context/GroupsContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'


import GroupsFilters from '../components/groups/GroupsFilters';

import { sendEmailToGroups,  sendSMSToGroups } from '../api/actions';

// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const GroupsScreen = ({ navigation }) => {

    const authCtx = useContext(AuthContext)
    const groupsCtx = useContext(GroupsContext)
    
    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>
        
        {/* <Text> Groups Screen </Text> */}

        {["admin", "leader"].includes(authCtx?.state?.enoaUser?.role)? <Button onPress={() => {
            navigation.dispatch(
                StackActions.push(
                    "GroupDetails",
                    {
                        createGroup: true
                    }
                )
            )
        }}>
            { i18n.t('newGroup') }
        </Button> : null }

        <Spacer/>

        <Button onPress={() => {
                navigation.dispatch(StackActions.pop())
            }}>
                { i18n.t('back') }
        </Button>

        <Spacer/>

        <GroupsFilters 
            showFilteredGroupsDetails={true}
            navigation={navigation}
        />


        <Spacer/>

        <Button.Group isDisabled={!groupsCtx?.state?.multipleSelectionGroupsIds?.length}>
            
            <Button onPress={async () => {

                // TODO Open a confirmation window with costs computed
                sendEmailToGroups(groupsCtx.state.multipleSelectionGroupsIds)
            }}>
                {i18n.t('sendEmailToSelectedGroups')}
            </Button>

            <Button onPress={async () => {

                // TODO Open a confirmation window with costs computed
                sendSMSToGroups(groupsCtx.state.multipleSelectionGroupsIds)
            }}>
                {i18n.t('sendSMSToSelectedGroups')}
            </Button>
        </Button.Group>

    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default GroupsScreen;