
import { getAuth, signInWithPopup, OAuthProvider, signInWithCredential } from 'firebase/auth';


// Returns error or falsy value on success
const yahooSignin = async language => {

    const result = await doYahooSignin(language)

    //debugger

    if (!result) {

        return {
            error: "Canceled by user"
        }
    }

    if (result === "Not a member") {

        return "Not a member"
    }

    const credential = getFirebaseYahooAuthCredential(result)

    // console.log("Yahoo Auth Credential: " + JSON.stringify(credential))
    try {

        const firebaseAuthenticationResult = await firebaseYahooSignin(credential);

        console.log("Yahoo firebaseAuthenticationResult: " + JSON.stringify(firebaseAuthenticationResult))

        if (!firebaseAuthenticationResult || firebaseAuthenticationResult.error) {

            error = "Unable to register Yahoo Authentication to Firebase. "  + JSON.stringify(firebaseRegistrationResult ? firebaseRegistrationResult.error : "Account disabled")

            console.log(error)

            throw ("Not a member")
        }
    } catch(error) {

        throw ("Not a member")
    }

    console.log("Yahoo Successfuly authenticated")

    return null
}

const getFirebaseYahooAuthCredential = result => {

    return OAuthProvider.credentialFromResult(result);
      
}

const firebaseYahooSignin = async (credential) => {

    console.log("Yahoo Credential: " + JSON.stringify(credential))

    return await signInWithCredential(getAuth(), credential)
}

const doYahooSignin = async language => {

    const provider = new OAuthProvider('yahoo.com');

    provider.setCustomParameters({
        // Prompt user to re-authenticate to Yahoo.
        prompt: 'login',
        language
      });
    
    return signInWithPopup(getAuth(), provider)
        .then(result => result)
        .catch(error => {

            //console.log("Error: ", error)

            if (error && error.message && error.message.includes("Not a member")) {

                return "Not a member"
            }

            return null
        })
}

export { yahooSignin }