import React, { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Box, useColorMode, useColorModeValue, Select } from 'native-base';

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
// Set the key-value pairs for the different languages you want to support.

const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

import { Context as ThemeContext } from '../context/ThemeContext'
import { Context as LanguageContext } from '../context/LanguageContext'

const ThemePicker = () => {

    const { state, setTheme } = useContext(ThemeContext)
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const { setColorMode } = useColorMode()

    const onChangeTheme = theme => {

        //console.log("Selected theme: " + theme)
        setTheme(theme)
        setColorMode(theme)        
    }

    return (
        <Box>
            <Box> {i18n.t('theme')} </Box> 
            <Select selectedValue={state} onValueChange={onChangeTheme}>
                <Select.Item label={i18n.t('darkTheme')} value="dark" />
                <Select.Item label= {i18n.t('lightTheme')} value="light" />
            </Select>
        </Box>
    )
};

const styles = StyleSheet.create({ 

})

export default ThemePicker;