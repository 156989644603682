export default {
    theme: "HU-Tema",
    lightTheme: "HU-Tema deschisa",
    darkTheme: "HU-Tema inchisa",
    back: "HU-Inapoi",
    home:"HU-Acasa",
    edit: "HU-Editeaza",
    update: "HU-Actualizeaza",
    save: "HU-Salveaza",
    delete: "HU-Sterge",
    details: "HU-Detalii",
    showPrivate: "HU-Arata datele private",
    hidePrivate: "HU-Ascunde datele private",
    newMember: "HU-Membru Nou",
    settings: "HU-Setari",
    romanian: "HU-Romana",
    hungarian: "HU-Maghiara",
    authentication: "HU-Autentificare",
    emailAddress: 'HU-Adresa de email',
    or: "vagy",
    phoneNumber: "HU-Numar de Telefon",
    signout: "HU-Delogare",
    signoutFromAllDevices: "HU-Deconectare de pe toate dispozitivele",
    verificationCode: "HU-Cod de Verificare",
    verify: "HU-Verifica",
    cancel: "HU-Anuleaza",
    msgSuccessfulAuthentication: "HU-Te-ai autentificat cu succes. Inchide acest tab si continua in tab-ul initial",
    closeThisTab: 'HU-Inchide acest tab',
    emailAuthenticationFailed: "HU-Autentificarea cu email a esuat",
    error: "HU-Eroare",
    success: "HU-Succes",
    wait: "HU-Asteapta",
    smsNotSent: "HU-SMS-ul un a putut fi trimis",
    emailNotSent: "HU-email-ul nu a putut fi trimis",
    invalidAuthentication: "HU-Autentificare nereusita",
    invalidAuthenticationData: "HU-Email sau numar de telefon incorect",
    msgUpdateRecaptcha: "HU-Te poti autentifica dupa ce urmezi pasii reCAPTCHA",
    msgAuthEmailJustSent: "HU-A fost trimis un email pentru autentificare. Verifica inclusiv in Spam",
    msgAuthSMSJustSent: "HU-A fost trimis un SMS cu codul pentru autentificare.",
    msgNotAMember: "HU-Nu te poti autentifica cu metoda folosita. Daca esti membru NOA contacteaza filiala de care apartii. Aceasta incercare de autentificare a fost inregistrata!",
    members: "HU-Membrii",
    groups: "HU-Grupuri",
    payments: "HU-Cotizatie",
    polls: "HU-Sondaje",
    myData: "HU-Datele mele",

    msgNameNotFilledIn: "HU-Numele nu este completat",
    msgNameTooShort: "HU-Numele este prea scurt",
    msgNameMustContainOnlyLettersAndSpaces: "HU-Numele trebuie sa contina doar litere si spatii",
    msgSurnameNotFilledIn: "HU-Preumele nu este completat",
    msgSurnameTooShort: "HU-Prenumele este prea scurt",
    msgSurnameMustContainOnlyLettersAndSpaces: "HU-Preumele trebuie sa contina doar litere si spatii",
    msgPhoneNotFilledIn: "HU-Numarul de telefon nu este completat",
    msgInvalidPhoneNumber: "HU-Numarul de terlefon este invalid",
    msgEmailNotFilledIn: "HU-Adresa de email nu este completata",
    msgInvalidEmailAddress: "HU-Adresa de email este invalida",
    msgCountyNotSelected: "HU-Judetul nu este selectat",
    msgLocalityNotSelected: "HU-Localitatea nu este selectata",
    msgIcSeriesNotFilledIn: "HU-Seria cartii de identitate nu este completata",
    msgIcSeriesInvalidLength: "HU-Seria cartii de identitate are prea multe caractere",
    msgIcSeriesMustContainOnlyCapitalLetters: "HU-Seria cartii de identitate trebuie sa contina doar litere majuscule",
    msgIcNumberNotFilledIn: "HU-Numarul cartii de identitate nu este completat",
    msgIcNumberInvalidLength: "HU-Numarul cartii de identitate trebuie sacontna 6 cifre",
    msgIcNumberMustContainOnlyDigits: "HU-Numarul cartii de identitate trebuie sa contina doar cifre",
    msgMonthlyPaymentNotSelected: "HU-Valoarea cotizatiei lunare nu este selectata",
    msgCNPInvalidNumberOfDigits: "HU-CNP-ul trebuie sa contina 13 cifre",
    msgCNPInvalidCharacter: "HU-CNP-ul contine caractere care nu sunt cifre",
    msgCNPInvalidFirstCharacter: "HU-Prima cifra a CNP-ului este invalida",
    msgCNPInvalidYear: "HU-CNP-ul contine un an invalid",
    msgCNPMemberTooYoung: "HU-CNP-ul apartine unei persoane minore",
    msgCNPControlDigitIsNotValid: "HU-Cifra de control a CNP-ului este invalida",
    msgCNPNotFilledIn: "HU-CNP-ul nu este completat",
    
    president: "HU-Presedinte",
    vicePresident: "HU-Vice Presedinte",
    countyPresident: "HU-Presedinte Filiala Judeteana",
    localPresident: "HU-Presedinte Birou Local",
    admin: "HU-Administrator",
    leader: "HU-Lider",
    secretary: "HU-Secretar",
    member: "HU-Membru",
    engineer: "HU-Inginer",
    lawyer: "HU-Jurist",
    economist: "HU-Economist",
    doctor: "HU-Medic",
    professor: "HU-Profesor",
    veterinary: "HU-Veterinar",
    forester: "HU-Silvicultor",
    environmentalist: "HU-Ecologist",
    artist: "HU-Artist",
    psychologist: "HU-Psiholog",
    sociologist: "HU-Sociolog",
    sportsman: "HU-Sportiv",
    mechanic: "HU-Meserias",

    name: "HU-Nume",
    surname: "HU-Prenume",
    county: "HU-Judet",
    locality: "HU-Localitate",
    specialization: "HU-Specializare",
    function: "HU-Functie",
    role: "HU-Rol",
    politicalExperience: "HU-Experienta Politica",
    registrationNumber: "HU-Numar de inregistrare",
    nextPayDate: "HU-Urmatoarea plata a cotizatiei",
    CNP: "HU-Cod Numeric Personal(CNP)",
    icSeries: "HU-Seria cartii de identitate",
    icNumber: "HU-Numarul cartii de identitate",
    monthlyPayment: "HU-Cotizatie lunara",

    msgUnableToLogPrivateDataAccess: "HU-Nu s-a reusit salvarea solicitarii de acces la datele private.",
    msgAreYouSureYouWantToSaveTheNewMember: "HU-Esti sigur ca vrei sa salvezi datele noului membru?",
    msgUnableToSaveNewMember: "HU-Datele membrului nu au putut fi salvate",
    msgMemberCreated: "HU-Datele membrului au fost adaugate in baza de date",
    msgAreYouSureYouWantToUpdateThisMember: "HU-Esti sigur ca vrei sa actualizezi datele acestui membru?",
    msgUnableToUpdateMember: "HU-Datele membrului nu au putut fi actualizate",
    msgMemberUpdated: "HU-Datele membrului au fost actualizate in baza de date",
    msgAreYouSureYouWantToDeleteThisMember: "HU-Esti sigur ca vrei sa stergi datele acestui membru?",
    msgUnableToDeleteMember: "HU-Datele membrului nu au putut fi sterse",
    msgMemberDeleted: "HU-Datele membrului au fost sterse in baza de date",
    msgAreYouSureYouCancelMemberCreate: "HU-Esti sigur ca vrei sa renunti la crearea unui membru?",
    msgAreYouSureYouCancelMemberEdit: "HU-Esti sigur ca vrei sa renunti la editatea datelor unui membru?",
    msgPresidentIsAreadySet: "HU-Presedintele este deja setat",
    msgVicePresidentAreAready7: "HU-Sunt deja 7 vicepresedinti",
    msgCountyPresidentIsAlreadySet: "HU-Presedintele organizatiei judetene este deja setat",
    msgLocalOfficePresidentIsAlreadySet: "HU-Presedintele biroului local este deja setat",
    
    msgAreYouSureYouWantToSaveTheNewGroup: "HU-Esti sigur ca vrei sa salvezi datele noului grup?",
    msgUnableToSaveNewGroup: "HU-Datele grupului nu au putut fi salvate",
    msgGroupCreated:"HU-Datele grupului au fost adaugate in baza de date",
    msgAreYouSureYouWantToUpdateThisGroup: "HU-Esti sigur ca vrei sa actualizezi datele acestui grup?",
    msgUnableToUpdateGroup: "HU-Datele grupului nu au putut fi actualizate",
    msgGroupUpdated: "HU-Datele grupului au fost actualizate in baza de date",
    msgAreYouSureYouWantToDeleteThisGroup: "HU-Esti sigur ca vrei sa stergi datele acestui grup?",
    msgUnableToDeleteGroup: "HU-Datele grupului nu au putut fi sterse",
    msgGroupDeleted: "HU-Datele grupului au fost sterse in baza de date",
    msgAreYouSureYouCancelGroupCreate: "HU-Esti sigur ca vrei sa renunti la crearea unui grup?",
    msgAreYouSureYouCancelGroupEdit: "HU-Esti sigur ca vrei sa renunti la editatea datelor unui grup?",
    msgAreYouWantToAddMemberToSelectedGroups: 'HU-Esti sigur ca vrei sa adaugi membrul la grupurile selectate?',
    msgAreYouWantToAddSelectedMembersToGroup: 'HU-Esti sigur ca vrei sa adaugi membrii selectati la grup?',

    confirmationWindow: "HU-Fereastra de Confirmare",
    msgExistsInDatabase: "HU-Exista in baza de date",
    msgRegistrationNumberIsDuplicatedTryAgain: "HU-Numarul de inregistrare se afla in baza de date. Mai salveaza odata. Daca nu reusesti sa salvezi, contacteaza echipa de suport",
    errorDetails: "HU-Detalii eroare",

    applyFilters: "HU-Aplica Filtre",
    next: "HU-Inainte",
    previous: "HU-Inapoi",
    clearSelection: "HU-Deselecteaza tot",
    showDetails: "HU-Arata Detalii",
    hideDetails: "HU-Ascunde Detaliile",
    noResult: "HU-Niciun rezultat",
    msgMaxIterableMembersInAFilterIs: "HU-Numarul maxim de membri care este afisat in urma unei filtrari este de",
    msgMembersFilterError: "HU-Eroare in la filtrarea membrilor",
    msgGroupsFilterError: "HU-Eroare la filtrarea grupurilor",
    msgUnknownError: "HU-Eroare necunoscuta",
    msgUnableToFetchMemberData: "HU-Eroare la descarcarea datelor membrului",
    msgUnableToFetchMemberPrivateData: "HU-Eroare la descarcarea datelor private ale membrului",
    msgUnableToFetchGroupMembers: "HU-Eroare la descarcarea membrilor grupului",
    msgUnableToFetchGroupDetails: "HU-Eroare la descarcarea detaliilor grupului",
    msgPaymentNotDone: "HU-Cotizatia neplatita",

    newGroup: "HU-Grup Nou",
    addMembers: "HU-Adauga Membrii",
    addSelectedMembers: "Adauga Membrii Selectati",
    removeGroup: "HU-Sterge Grup",
    removeSelectedGroups: "HU-Sterge Grupurile Selectate",
    sendEmailToGroup: "HU-Trimite email catre grup",
    sendEmailToSelectedGroups: "HU-Trimite email catre grupurile selectate",
    sendSMSToGroup: "HU-Trimite SMS catre grup",
    sendSMSToSelectedGroups: "HU-Trimite SMS catre grupurile selectate",

    addSelectedMembers: "HU-Adauga membrii selectati",
    msgOnlyDefaultGroups: "HU-Doar grupuri implicite(National, Judetene si Functii)",
    msgOnlyNonDefaultGroups: "HU-Doar grupuri create manual",

    newMember: "HU-Membru Nou",
    addGroups: "HU-Adauga Grupuri",
    addSelectedGroups: "HU-Adauga Membrii Selectati",
    sendEmailToMember: "HU-Trimite-i email",
    sendEmailToSelectedMembers: "HU-Trimite email catre membrii selectati",
    sendSMSToMember: "HU-Trimite-i SMS",
    sendSMSToSelectedMembers: "HU-Trimite SMS catre membrii selectati",

    payment: "HU-Cotizatie",
    identityCard: "HU-Carte de Identitate",
    msgPaymentWithDelayGreaterThan: "HU-Intarziere la plata cotizatiei mai mare ca",
    payDateOffset: "HU-Intarziere plata cotizatie"
}