import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { AlertDialog, Box, Text, Button, FormControl, Input, Select, FlatList, Checkbox } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as MembersContext } from '../../context/MembersContext'
import { Context as UIContext } from '../../context/UIContext'


import GroupPreview from '../groups/GroupPreview'

import { 
    fetchMemberByMemberId, 
    fetchMemberPrivateByMemberPrivateId,
    fetchSpecializations, 
    fetchFunctions,
    fetchRoles,
    fetchMonthlyPayments,
    getDefaultMember, 
    getDefaultMemberPrivate,
    validateMember, 
    logAccessToMemberPrivate,
    createFirestoreMember,
    updateFirestoreMember,
    isMemberEqual,
    isMemberPrivateEqual,
    deleteFirestoreMember,
    fetchNextRegistrationNumber,
    checkMemberConstraints,
    applyMembersFilters
} from '../../api/members';

import {
    capitalizeFirstLetterFromWords,
    capitalizeWord,
    formatDate
} from '../../api/string'

import { filterLocalitiesByCounty, filterLocalitiesArrayByPrefix, fetchLocalitiesFromCounty } from '../../api/localities';
import { fetchFirestoreCountiesNames } from '../../api/counties';

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'
import { isNormalizedPhoneNumber, isValidPhoneNumber, normalizePhoneNumber } from '../../api/signin/phone';
import Spacer from '../Spacer';
import { hasOwnProperties } from '../../api/object';
import { fetchGroupsForMemberId, removeMemberFromGroup } from '../../api/groups';
import { useFocusEffect } from '@react-navigation/native';
import CustomAlert from '../CustomAlert';
import { DevToolsSettingsManager } from 'react-native';
import { cloudLogger } from '../../api/logger';


const i18n = new I18n({
    ...AppTranslations
  });
  
  i18n.enableFallback = true;

const MemberDetails = ( { createMember, editInProgress, setEditInProgress, navigation } ) => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    const uiCtx = useContext(UIContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [showPrivate, setShowPrivate] = useState(false)

    const [member, setMember] = useState({})
    const [memberPrivate, setMemberPrivate] = useState({})
    const [memberGroups, setMemberGroups] = useState([])

    const initialMember = useRef({})
    const initialMemberPrivate = useRef({})

    const [fetchMemberGroups, setFetchMemberGroups] = useState(new Date())

    const [errors, setErrors] = useState({})

    const [enoaFunctions, setEnoaFunctions] = useState([])
    const [specializations, setSpecializations] = useState([])
    const [roles, setRoles] = useState([])
    const [monthlyPayments, setMonthlyPayments] = useState ([])
    const [counties, setCounties] = useState([])
    const [localities, setLocalities] = useState({})

    const alertTitle = useRef('confirmationWindow')
    const cancelRefAlert = useRef()
    const messageAlert = useRef('')
    const onCancelAlert = useRef()
    const onAcceptAlert = useRef()

    const errorDetails = useRef()

    const [isOpenConfirmationWindow, setIsOpenConfirmationWindow] = useState(false)
    const [isOpenInformationWindow, setIsOpenInformationWindow] = useState(false)

    useEffect(() => {

        (async() => {
            setEnoaFunctions(await fetchFunctions())
            setSpecializations(await fetchSpecializations())
            setCounties(await fetchFirestoreCountiesNames())
            setRoles(await fetchRoles()) 
            setMonthlyPayments(await fetchMonthlyPayments())           
        })()
    }, [])

    useEffect(() => {
        (async() => {

            if (!member || !member.county || localities[member.county]) {

                return
            }

            setLocalities({
                ...localities,
                [member.county]: await fetchLocalitiesFromCounty(member.county)
            })

        })()
    }, [member.county])


    useEffect(() => {

        if (membersCtx?.state?.selectedMemberId) {

            (async() => {
                try {

                    const member = await fetchMemberByMemberId(membersCtx.state.selectedMemberId)

                    cloudLogger('info', `View Member Details: ${JSON.stringify(member)}`)

                    setMember(member)

                    initialMember.current = member
                } catch (error) {

                    alertTitle.current = "error"
                    messageAlert.current = "msgUnableToFetchMemberData"
                    errorDetails.current = error

                    onAcceptAlert.current = () => {

                        setIsOpenInformationWindow(false)                   
                        navigation.dispatch(StackActions.pop())
                    }

                    setIsOpenInformationWindow(true)
                }
            })()
        } else {

            setMember(getDefaultMember())
            setMemberPrivate(getDefaultMemberPrivate())
        }

    }, [membersCtx.state.selectedMemberId])

    const updateMemberGroups = async () => {

        const memberGroups = await fetchGroupsForMemberId(membersCtx.state.selectedMemberId)
        setMemberGroups(memberGroups)

        membersCtx.setMultipleSelectionGroupsIds(
            memberGroups.map(mg => mg.id)
        )
    }

    // If groups are deleted/updated 
    useEffect(() => {

        updateMemberGroups()
    }, [membersCtx.state.selectedMemberId, fetchMemberGroups])

    useEffect(() => {

        const unsubscribe = navigation.addListener('focus', () => {

            updateMemberGroups()
        });
      
         // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
        
    }, [navigation])

    const [inputLocalityText, setInputLocalityText] = useState('')
    const [showLocalityList, setShowLocalityList] = useState(false)

    const filteredLocalities = useMemo(() => filterLocalitiesArrayByPrefix(
        localities[member.county],
        inputLocalityText
    ), [member.county, inputLocalityText])

    useEffect(() => {
        setInputLocalityText(member.locality)
    }, [member.locality]) 

    return <Box>
        {/* <Text> Member Details </Text> */}

        {/* <Text>{JSON.stringify(errors)}</Text>*/}

        {/* <Text>{JSON.stringify(member)}</Text>  */}

        {/* <Text> {JSON.stringify(memberPrivate)} </Text> */}

        {/* <Text>{JSON.stringify(filteredLocalities)}</Text> */}

        {/* <Text>Member groups ids: {JSON.stringify(membersCtx?.state?.multipleSelectionGroupsIds)}</Text>

        <Text>Member equal: {JSON.stringify(isMemberEqual(initialMember.current, member))}</Text>
        <Text>Member Private equal: {JSON.stringify(isMemberPrivateEqual(initialMemberPrivate.current, memberPrivate))}</Text> */}


        <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} isInvalid={'name' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('name')}
            </FormControl.Label>
            <Input placeholder="Popescu" 
                isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false}
                value={member.name}
                onChangeText={value => setMember({...member, name: capitalizeFirstLetterFromWords(value)})}
            />
            
            {
                'name' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.name) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgNameIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role)? true : false} isInvalid={'surname' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('surname')}
            </FormControl.Label>
            <Input placeholder="Ion" 
                isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false}
                value={member.surname}
                onChangeText={value => setMember({...member, surname: capitalizeFirstLetterFromWords(value)})}
            />
            
            {
                'surname' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.surname) } 
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgNameIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} isInvalid={'county' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('county')}
            </FormControl.Label>

            <Select selectedValue={member.county} 
                isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false}
                placeholder={i18n.t('county')}
                onValueChange={ (county) => {                   
                    setMember({
                        ...member,
                        county,
                        locality: ''
                    })

                    setInputLocalityText('')
                }}
            >
                {
                    
                    counties.map((county, countyIndex) => (
                        <Select.Item key={countyIndex} label={county} value={county} />
                    ))
                    
                }
            </Select>
            
            {
                'county' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.county) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgCountyIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} isInvalid={'locality' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('locality')}
            </FormControl.Label>
           
            <Input value={inputLocalityText}
                isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false}
                onChangeText={value => {
                    setInputLocalityText(value)

                    if (filteredLocalities.includes(value)) {

                        setMember({
                            ...member,
                            locality: value
                        })
                    } else {

                        setMember({
                            ...member,
                            locality: ""
                        })
                    }

                    setShowLocalityList(true)
                }} 
                onFocus={() => {

                    setInputLocalityText("")
                    setShowLocalityList(true)
                    setMember({
                        ...member,
                        locality: ""
                    })
                }}
                placeholder={i18n.t('locality')}
            />

            {
                member?.county && filteredLocalities && filteredLocalities.length && showLocalityList ?
                    <FlatList data={filteredLocalities}
                        renderItem={({item, index}) => (
                            <Text onPress={() => {
                                setMember({
                                    ...member,
                                    locality: item
                                })
                                setInputLocalityText(item)
                                setShowLocalityList(false)
                            }}>
                                {item}
                            </Text>
                        )} 
                        keyExtractor={item => item}
                    /> : 
                    null
            }
            
            {
                'locality' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.locality) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgLocalityIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} isInvalid={'phone' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('phoneNumber')}
            </FormControl.Label>
            <Input placeholder="0712345678"
                keyboardType="numeric"
                value={member.phone}
                onChangeText={value => setMember({...member, phone: value})}
                isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false}
            />
            
            {
                'phone' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.phone) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgPhoneIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role)}  isInvalid={'email' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('emailAddress')}
            </FormControl.Label>
            <Input isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} placeholder="email@email.com" 
                value={member.email}
                onChangeText={value => setMember({...member, email: value})}
            />
            
            {
                'email' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.email) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgEmailIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isInvalid={'specialization' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('specialization')}
            </FormControl.Label>
            
            <Select isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} selectedValue={member.specialization} 
                placeholder={i18n.t('specialization')}
                onValueChange={ (specialization) => {
                   
                    setMember({
                        ...member,
                        specialization
                    })
                }}
            >
                {
                    
                    ([""].concat(specializations)).map((specialization, specializationIndex) => (
                        <Select.Item key={specializationIndex} label={specialization ? i18n.t(specialization) : ""} value={specialization} />
                    ))
                    
                }
            </Select>
            
            {
                'specialization' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.specialization) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgSpecializationIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isInvalid={'function' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('function')}
            </FormControl.Label>
            
            <Select isDisabled={(!createMember && !editInProgress) || ( !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role)) ? true : false} selectedValue={member.function} 
                placeholder={i18n.t('function')}
                onValueChange={ (enoaFunction) => {
                   
                    setMember({
                        ...member,
                        function: enoaFunction
                    })
                }}
            >
                {
                    
                    [""].concat(enoaFunctions).map((enoaFunction, enoaFunctionIndex) => (
                        <Select.Item key={enoaFunctionIndex} label={enoaFunction ? i18n.t(enoaFunction) : ""} value={enoaFunction} />
                    ))
                    
                }
            </Select>
            
            {
                'function' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.function) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgFunctionIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isRequired={(createMember || editInProgress) && authCtx?.state?.enoaUser?.role === "admin" ? true : false} isInvalid={'role' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('role')}
            </FormControl.Label>
            
            <Select isDisabled={(!createMember && !editInProgress) || authCtx?.state?.enoaUser?.role !== "admin" ? true : false} 
                selectedValue={member.role} 
                placeholder={i18n.t('role')}
                onValueChange={ (role) => {                   
                    setMember({
                        ...member,
                        role
                    })
                }}
            >
                {
                    
                    roles.map((role, roleIndex) => (
                        <Select.Item key={roleIndex} label={i18n.t(role)} value={role} />
                    ))
                    
                }
            </Select>
            
            {
                'role' in errors ? 
                <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                    { i18n.t(errors.role) }
                </FormControl.ErrorMessage> : null
                // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                //     {i18n.t('msgRoleIsMandatory')}
                // </FormControl.HelperText>
            }
        </FormControl>

        <FormControl isInvalid={'politicalExperience' in errors}>
            <FormControl.Label _text={{bold: true}}>
                {i18n.t('politicalExperience')}
            </FormControl.Label>
            <Checkbox.Group isDisabled={(!createMember && !editInProgress)  || ( !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role)) ? true : false} 
                value={member.politicalExperience ? ['politicalExperience'] : []} 
                onChange={values => {
                    setMember({
                        ...member,
                        politicalExperience: values.length ? true : false
                    })
                }}
                aria-labelledby='politicalExperience-Group'
            >
                <Checkbox size="sm" value="politicalExperience" aria-labelledby='politicalExperience'>
                    {/* {i18n.t('politicalExperience')} */}
                </Checkbox>
            </Checkbox.Group>
        </FormControl>

        {/* Computed data, not editable */}
        { !createMember ? <Box>

            <FormControl isRequired={false}>
                <FormControl.Label _text={{bold: true}}>
                    {i18n.t('registrationNumber')}
                </FormControl.Label>
                <Input isDisabled
                    value={member.registrationNumber}
                />
            </FormControl>

            <FormControl isRequired={false} 
                isInvalid={!createMember && member?.nextPayDate?.toDate && member?.nextPayDate?.toDate() < new Date()}
            >
                <FormControl.Label _text={{bold: true}}>
                    {i18n.t('nextPayDate')}
                </FormControl.Label>

                <Input isDisabled
                    value={member?.nextPayDate?.toDate ? formatDate(member?.nextPayDate?.toDate()) : null}
                />

                <FormControl.ErrorMessage>
                    {i18n.t('msgPaymentNotDone')}
                </FormControl.ErrorMessage>

            </FormControl>
            
        </Box>: null }


        {/* Private data */}
        {(createMember || showPrivate) ? <Box>

            <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} isInvalid={'CNP' in errors}>
                <FormControl.Label _text={{bold: true}}>
                    {i18n.t('CNP')}
                </FormControl.Label>
                <Input isDisabled={(!createMember && !editInProgress)  || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} placeholder="1234567890123" 
                    value={memberPrivate?.CNP}
                    onChangeText={value => setMemberPrivate({...memberPrivate, CNP: value})}
                />
                
                {
                    'CNP' in errors ? 
                    <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                        { i18n.t(errors.CNP) }
                    </FormControl.ErrorMessage> : null
                    // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                    //     {i18n.t('msgCNPIsMandatory')}
                    // </FormControl.HelperText>
                }
            </FormControl>

            <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role)? true : false} isInvalid={'icSeries' in errors}>
                <FormControl.Label _text={{bold: true}}>
                    {i18n.t('icSeries')}
                </FormControl.Label>
                <Input isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} placeholder="AB" 
                    value={memberPrivate?.icSeries}
                    onChangeText={value => setMemberPrivate({...memberPrivate, icSeries: capitalizeWord(value)})}
                />
                
                {
                    'icSeries' in errors ? 
                    <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                        { i18n.t(errors.icSeries) }
                    </FormControl.ErrorMessage> : null
                    // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                    //     {i18n.t('msgIcSeriesIsMandatory')}
                    // </FormControl.HelperText>
                }
            </FormControl>

            <FormControl isRequired={(createMember || editInProgress) && ["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role)? true : false} isInvalid={'icNumber' in errors}>
                <FormControl.Label _text={{bold: true}}>
                    {i18n.t('icNumber')}
                </FormControl.Label>
                <Input isDisabled={(!createMember && !editInProgress) || !["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? true : false} placeholder="123456" 
                    value={memberPrivate?.icNumber}
                    onChangeText={value => setMemberPrivate({...memberPrivate, icNumber: value})}
                />
                
                {
                    'icNumber' in errors ? 
                    <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                        { i18n.t(errors.icNumber) }
                    </FormControl.ErrorMessage> : null
                    // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                    //     {i18n.t('msgIcNumberIsMandatory')}
                    // </FormControl.HelperText>
                }
            </FormControl>

            <FormControl isRequired={(createMember || editInProgress) && authCtx?.state?.enoaUser?.id === memberPrivate?.memberId ? true : false} isInvalid={'monthlyPayment' in errors}>
                <FormControl.Label _text={{bold: true}}>
                    {i18n.t('monthlyPayment')}
                </FormControl.Label>
                
                <Select isDisabled={(!createMember && !editInProgress) || authCtx?.state?.enoaUser?.id !== memberPrivate?.memberId ? true : false} selectedValue={parseInt(memberPrivate?.monthlyPayment)} 
                    placeholder={i18n.t('monthlyPayment')}
                    onValueChange={ (monthlyPayment) => {
                    
                        setMemberPrivate({
                            ...memberPrivate,
                            monthlyPayment
                        })
                    }}
                >
                    {
                        
                        monthlyPayments.map((monthlyPayment, monthlyPaymentIndex) => (
                            <Select.Item key={monthlyPaymentIndex} label={monthlyPayment} value={monthlyPayment} />
                        ))
                        
                    }
                </Select>
                
                {
                    'monthlyPayment' in errors ? 
                    <FormControl.ErrorMessage _text={{fontSize: 'xs'}}>
                        { i18n.t(errors.monthlyPayment) }
                    </FormControl.ErrorMessage> : null
                    // <FormControl.HelperText _text={{fontSize: 'xs'}}>
                    //     {i18n.t('msgMonthlyPaymentIsMandatory')}
                    // </FormControl.HelperText>
                }
            </FormControl>

        </Box> : null }

        {
            !createMember ? <Box> {
                memberGroups.map(group => <Box key={group.id} style={styles.groupPreviewContainer}>
                    <GroupPreview group={group}/>
                    {["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role) ? <Button isDisabled={group.default}
                        onPress={async () => {

                            if (await removeMemberFromGroup(group.id, member.id) === true) {

                                cloudLogger('warn', `Member ${member.id} removed from Group ${group.id}`)

                                setFetchMemberGroups(new Date())
                            }
                        }}
                    >
                        {i18n.t('delete')}
                    </Button> : null}
                </Box>)
            }</Box> : null
        }

        <Spacer/>

        {
            !createMember && !showPrivate ?  <Button onPress={async () => {

                const logAccess = await logAccessToMemberPrivate(member, authCtx?.state?.enoaUser)

                if (logAccess === true) {

                    try {

                        const memberPrivate = await fetchMemberPrivateByMemberPrivateId(member.memberPrivateId)

                        setShowPrivate(true)
                        setMemberPrivate(memberPrivate)

                        initialMemberPrivate.current = memberPrivate
                    } catch(error) {

                        setShowPrivate(false)

                        alertTitle.current = "error"
                        messageAlert.current = "msgUnableToFetchMemberPrivateData"
                        errorDetails.current = error

                        onAcceptAlert.current = () => {

                            setIsOpenInformationWindow(false)    
                        }

                        setIsOpenInformationWindow(true)
                    }
                } else {

                    alertTitle.current = "error"
                    messageAlert.current = "msgUnableToLogPrivateDataAccess"

                    onAcceptAlert.current = () => {

                        setIsOpenInformationWindow(false)    
                    }

                    setIsOpenInformationWindow(true)
                }
                
            }}>
                {i18n.t('showPrivate')}
            </Button>: null
        }

        {
            !createMember && showPrivate ?  <Button onPress={async () => {
                setShowPrivate(false)
            }}>
                {i18n.t('hidePrivate')}
            </Button>: null
        }

        { createMember === true ? <Button onPress={async () => {
            
            const saveMember = async () => {

                try {

                    if (!isNormalizedPhoneNumber(member.phone)) {

                        member.phone = normalizePhoneNumber(member.phone)
                    }
    
                    member.name = member.name.trim()
                    member.surname = member.surname.trim()
    
                    console.log("Save member: ", member, "; member private: ", memberPrivate)
    
                    member.registrationNumber = await fetchNextRegistrationNumber(member.county)
    
                    const res = await createFirestoreMember(member, memberPrivate)
    
                    if (res !== true) {
                       
                        const errors = await checkMemberConstraints(member, memberPrivate, initialMember.current, initialMemberPrivate.current)
    
                        if (hasOwnProperties(errors)) {
    
                            console.log("Create member errors: ", errors)
                            setErrors(errors)
    
                            return errors
                        }
                    }
    
                    return res

                } catch (error) {

                    return error?.stack || error
                }
                
            }

            const runPostSaveMember = () => {

                console.log("Update post save member")

                applyMembersFilters(membersCtx)

                navigation.dispatch(StackActions.pop())
            }


            if (await validateMember(member, memberPrivate, setErrors)) {

                messageAlert.current = 'msgAreYouSureYouWantToSaveTheNewMember'
                alertTitle.current = 'confirmationWindow'
                errorDetails.current = null
                uiCtx.setDisableCustomAlertButtons(false)

                onAcceptAlert.current = async () => {

                    uiCtx.setDisableCustomAlertButtons(true)

                    const result = await saveMember()

                    uiCtx.setDisableCustomAlertButtons(false)
                    setIsOpenConfirmationWindow(false)

                    if (result === true) {

                        alertTitle.current = 'success'
                        messageAlert.current = 'msgMemberCreated'

                        onAcceptAlert.current = () => {

                            setIsOpenInformationWindow(false)


                            // Update application data
                            runPostSaveMember()
                        }

                        setIsOpenInformationWindow(true)
                    } else {

                        alertTitle.current = 'error'
                        messageAlert.current = 'msgUnableToSaveNewMember'
                        errorDetails.current = JSON.stringify(result)

                        onAcceptAlert.current = () => {

                            setIsOpenInformationWindow(false)    
                        }

                        setIsOpenInformationWindow(true)
                    }
                }
    
                onCancelAlert.current = () => {
    
                    setIsOpenConfirmationWindow(false)
                }
    
                // Last step should be use state, to render the updated references
                setIsOpenConfirmationWindow(true)
            }
            
        }}>
            {i18n.t('save')}
        </Button>: null}

        { !createMember && !editInProgress ? <Button onPress={() => {
            setEditInProgress(true)
        }}>
            {i18n.t('edit')}
        </Button>: null}

        {!createMember && member.id !== authCtx?.state?.enoaUser?.id ? <Button isDisabled={editInProgress} onPress={() => {            

            const deleteMember = async () => {

                try {

                    const res = await deleteFirestoreMember(member.id)

                    // TODO: Call a cloud function to delete all authentication
                    //          that fetches data from firebase_members
                    // TODO: Delete from firestore_members all authentications
                    // TODO: Delete from groups

                    return res
                } catch (error) {

                    return error?.stack || error
                }

                
            }

            const runPostDeleteMember = () => {

                console.log("Update post delete member")

                applyMembersFilters(membersCtx)

                membersCtx.setSelectedMemberId(null)

                navigation.dispatch(StackActions.pop())
            }

            messageAlert.current = 'msgAreYouSureYouWantToDeleteThisMember'
            alertTitle.current = 'confirmationWindow'
            errorDetails.current = null
            uiCtx.setDisableCustomAlertButtons(false)

            onAcceptAlert.current = async () => {

                uiCtx.setDisableCustomAlertButtons(true)

                const result = await deleteMember()

                uiCtx.setDisableCustomAlertButtons(false)
                setIsOpenConfirmationWindow(false)

                if (result === true) {

                    alertTitle.current = 'success'
                    
                    messageAlert.current = 'msgMemberDeleted'

                    onAcceptAlert.current = () => {

                        setIsOpenInformationWindow(false)

                        // Update application data
                        runPostDeleteMember()
                    }

                    setIsOpenInformationWindow(true)
                } else {

                    alertTitle.current = 'error'
                    messageAlert.current = 'msgUnableToDeleteMember'
                    errorDetails.current = JSON.stringify(result)
                    
                    onAcceptAlert.current = () => {

                        setIsOpenInformationWindow(false)    
                    }

                    setIsOpenInformationWindow(true)
                }

            }

            onCancelAlert.current = () => {

                setIsOpenConfirmationWindow(false)    
            }

            // Last step should be use state, to render the updated references
            setIsOpenConfirmationWindow(true)
            
        }}>
            { i18n.t('delete')}
        </Button> : null }

        { createMember === false && editInProgress ? <Button onPress={async () => {

            const updateMember = async () => {

                try {

                    if (!isNormalizedPhoneNumber(member.phone)) {

                        member.phone = normalizePhoneNumber(member.phone)
                    }
    
                    member.name = member.name.trim()
                    member.surname = member.surname.trim()
    
                    console.log("Update member: ", member, "; member private: ", memberPrivate)
    
                    const res = await updateFirestoreMember(
                        !isMemberEqual(initialMember.current, member) ? member : null, 
                        !isMemberPrivateEqual(initialMemberPrivate.current, memberPrivate) ? memberPrivate : null
                    )
    
                    if (res !== true) {
    
                        const errors = await checkMemberConstraints(member, memberPrivate, initialMember.current, initialMemberPrivate.current)
    
                        if (hasOwnProperties(errors)) {
    
                            console.log("Update client test errors: ", errors)
                            setErrors(errors)
    
                            return errors
                        }
                    }
    
                    return res
                } catch (error) {

                    return error?.stack || error
                }
            }

            const runPostUpdateMember = async () => {

                console.log("Update post update member")

                setEditInProgress(false)

                if (!isMemberEqual(initialMember.current, member)) {

                    const member = await fetchMemberByMemberId(membersCtx.state.selectedMemberId)
                    setMember(member)

                    initialMember.current = member
                }

                if (!isMemberPrivateEqual(initialMemberPrivate.current, memberPrivate)) {

                    const memberPrivate = await fetchMemberPrivateByMemberPrivateId(member.memberPrivateId)
                    setMemberPrivate(memberPrivate)

                    initialMemberPrivate.current = memberPrivate
                }


                // If member updates his monthlyPayment, do not filter memners
                if (["admin", "leader", "secretary"].includes(authCtx?.state?.enoaUser?.role)) {

                    applyMembersFilters(membersCtx)
                }
            }

            if (await validateMember(member, memberPrivate, setErrors)) {

                messageAlert.current = 'msgAreYouSureYouWantToUpdateThisMember'
                alertTitle.current = 'confirmationWindow'
                errorDetails.current = null
                uiCtx.setDisableCustomAlertButtons(false)

                onAcceptAlert.current = async () => {

                    uiCtx.setDisableCustomAlertButtons(true)

                    const result = await updateMember()

                    uiCtx.setDisableCustomAlertButtons(false)

                    setIsOpenConfirmationWindow(false)

                    if (result === true) {

                        alertTitle.current = 'success'

                        messageAlert.current = 'msgMemberUpdated'
                    
                        onAcceptAlert.current = () => {

                            setIsOpenInformationWindow(false)

                            // Update application data
                            runPostUpdateMember()
                        }

                        setIsOpenInformationWindow(true)
                    } else {

                        alertTitle.current = 'error'                        
                        messageAlert.current = 'msgUnableToUpdateMember'
                        errorDetails.current = JSON.stringify(result)
                    
                        onAcceptAlert.current = () => {

                            setIsOpenInformationWindow(false)    
                        }

                        setIsOpenInformationWindow(true)
                    }
                }
    
                onCancelAlert.current = () => {
    
                    setIsOpenConfirmationWindow(false)    
                }
    
                // Last step should be use state, to render the updated references
                setIsOpenConfirmationWindow(true)
            }
            
        }}
            isDisabled={isMemberEqual(initialMember.current, member) && isMemberPrivateEqual(initialMemberPrivate.current, memberPrivate)}
        >
            {i18n.t('update')}
        </Button> : null}

        { editInProgress === true ? <Button onPress={() => {
            
            messageAlert.current = 'msgAreYouSureYouCancelMemberEdit'
            alertTitle.current = 'confirmationWindow'

            onAcceptAlert.current = () => {

                setEditInProgress(false)
                setMember(initialMember.current)
                setMemberPrivate(initialMemberPrivate.current)

                setIsOpenConfirmationWindow(false)    
            }

            onCancelAlert.current = () => {

                setIsOpenConfirmationWindow(false)    
            }

            // Last step should be use state, to render the updated references
            setIsOpenConfirmationWindow(true)
            
        }}>
            {i18n.t('cancel')}
        </Button>: null}

        { createMember === true ? <Button onPress={() => {
            
            messageAlert.current = 'msgAreYouSureYouCancelMemberCreate'
            alertTitle.current = 'confirmationWindow'
            
            onAcceptAlert.current = () => {

                setIsOpenConfirmationWindow(false)

                navigation.dispatch(StackActions.pop())
            }

            onCancelAlert.current = () => {

                setIsOpenConfirmationWindow(false)    
            }

            // Last step should be use state, to render the updated references
            setIsOpenConfirmationWindow(true)
            
        }}>
            {i18n.t('cancel')}
        </Button>: null}


        <CustomAlert
            type='confirmation'
            cancelRef={cancelRefAlert}
            isOpen={isOpenConfirmationWindow}
            title={alertTitle.current}
            message={messageAlert.current}
            onCancel={onCancelAlert.current}
            onAccept={onAcceptAlert.current}
        />

        <CustomAlert
            type='information'
            cancelRef={cancelRefAlert}
            isOpen={isOpenInformationWindow}
            title={alertTitle.current}
            message={messageAlert.current}
            details={errorDetails.current}
            onAccept={onAcceptAlert.current}
        />
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    },
    groupPreviewContainer: {
        flexDirection: 'row'
    }
})

export default MemberDetails;