import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';

import { Select } from 'native-base';

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
// Set the key-value pairs for the different languages you want to support.

const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

import { Context as LanguageContext } from '../context/LanguageContext'


const LanguagePicker = () => {

    const { state, setLanguage } = useContext(LanguageContext)
    i18n.locale = state

    return (
        <View>
            <Select selectedValue={state} onValueChange={setLanguage}>
                <Select.Item label={i18n.t('romanian')} value="ro-RO" />
                <Select.Item label={i18n.t('hungarian')} value="hu-HU" />                
            </Select>
        </View>
    )
};

const styles = StyleSheet.create({ 

})

export default LanguagePicker;