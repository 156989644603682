import React from 'react'
import { StyleSheet } from 'react-native'
import { Box, Text } from 'native-base'

const LoadingScreen = ( { navigation, route }) => {

    return (
        <Box>
            <Text>Loading Screen</Text>
            {
                route.params && route.params.loadingScreenContent ? route.params.loadingScreenContent : null
            }
        </Box>
    )
}

const styles = StyleSheet.create({ })

export default LoadingScreen;