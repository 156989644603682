import React from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text } from 'native-base'

const Info = ({text}) => {

    return <Box>
        <Text> {text} </Text>
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default Info;