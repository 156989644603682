import AsyncStorage from '@react-native-async-storage/async-storage';

import createDataContext from './createDataContext';

const languageReducer = (state, action) => {
    
    switch (action.type) {
        case 'set_language':

            state = action.payload

            // A new object must be created, to triger rerendering
            return state

        default:
            return state;
    }
}

const setLanguage = dispatch => language => {

    // Do NOT use await, dispatch function must return a value, not a promise
    AsyncStorage.setItem('@e-noa.ro-language', language)

    dispatch({ type: 'set_language', payload: language })
}

export const { Provider, Context } = createDataContext(
    languageReducer,
    { setLanguage },
    'ro-RO' 
);


