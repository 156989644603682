import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, Text } from 'native-base'

import { Context as LanguageContext } from '../../context/LanguageContext'
import { Context as AuthContext } from '../../context/AuthContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../../src/i18n/i18n'

import Spacer from '../Spacer';

const i18n = new I18n({
    ...AppTranslations
});
  
i18n.enableFallback = true;

const GroupPreview = ({group}) => {

    const authCtx = useContext(AuthContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    return <Box style={styles.container}>
        <Text> {i18n.t('name')}: { group.name } </Text>
        {group.default ? <Text>default</Text> : null}
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        borderWidth: 1
    },
    backgroundImage: {
        flex: 1
    }
})

export default GroupPreview;