import React, { useContext, useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useColorModeValue, useSafeArea, Box, Button, Text } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as MembersContext } from '../context/MembersContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'

import MemberDetails from '../components/members/MemberDetails';


// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const MemberDetailsScreen = ({ navigation, route }) => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    
    const [editInProgress, setEditInProgress] = useState(false)

    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;


    return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>

        {/* <Text> Member Details Screen </Text> */}

        <MemberDetails 
            createMember={route?.params?.createMember === true ? true : false} 
            editInProgress={editInProgress} setEditInProgress={setEditInProgress}
            navigation={navigation}
        />

        {route?.params?.createMember ? null : <Button.Group>

        <Button isDisabled={editInProgress} onPress={() => {
            
            membersCtx.setSelectedMemberId(null)
            navigation.dispatch(StackActions.pop())
        }}>
            { i18n.t('back') }
        </Button>

        <Button isDisabled={editInProgress} onPress={() => {

            membersCtx.setSelectedMemberId(null)
            navigation.dispatch(StackActions.popToTop())
        }}>
                { i18n.t('home') }
        </Button>

        { <Button isDisabled={editInProgress} onPress={ () => {

            navigation.dispatch(
                StackActions.push(
                    "MemberAddGroups"
                )
            )            
        }}>
            {i18n.t('addGroups')}
        </Button> }

        <Button onPress={async () => {

            // TODO Open a confirmation window with costs computed
            sendEmailToMembers([membersCtx.state.selectedMemberId])
        }}>
            {i18n.t('sendEmailToMember')}
        </Button>

        <Button onPress={async () => {

            // TODO Open a confirmation window with costs computed
            sendSMSToMembers([membersCtx.state.selectedMemberId])
        }}>
            {i18n.t('sendSMSToMember')}
        </Button>
        </Button.Group> }
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default MemberDetailsScreen;