import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { useColorModeValue, useSafeArea, Box, Button, Text } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'

// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const PollsScreen = ({ navigation }) => {

    const authCtx = useContext(AuthContext)
    
    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;


    return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>

        <Text> Under Construction </Text>

        <Button onPress={() => {
                navigation.dispatch(StackActions.pop())
            }}>
                { i18n.t('back') }
        </Button>
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default PollsScreen;