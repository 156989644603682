import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useColorModeValue, useSafeArea, Box, Button, Text } from 'native-base'

import { StackActions } from '@react-navigation/routers'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as MembersContext } from '../context/MembersContext'
import { Context as GroupsContext } from '../context/GroupsContext'
import { Context as UIContext } from '../context/UIContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'
import GroupsFilters from '../components/groups/GroupsFilters';
import { applyGroupsFilters, addGroupsToMember } from '../api/groups';
import CustomAlert from '../components/CustomAlert';
import { cloudLogger } from '../api/logger';

// Set the key-value pairs for the different languages you want to support.
const i18n = new I18n({
  ...AppTranslations
});

i18n.enableFallback = true;

const MemberAddGroupsScreen = ({ navigation }) => {

    const authCtx = useContext(AuthContext)
    const membersCtx = useContext(MembersContext)
    const groupsCtx = useContext(GroupsContext)
    const uiCtx = useContext(UIContext)

    const selectedMemberInitialGroupsIds = useRef([...membersCtx.state.multipleSelectionGroupsIds])
    
    const safeAreaProps = useSafeArea({
        safeArea: true
    });

    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    useEffect(() => {

        groupsCtx.setFilters({
            ...groupsCtx.state.filters,
            defaultGroups: ['onlyNonDefaultGroups'] 
        })

        // To force this value in applyGroupFilters only once
        groupsCtx.state.filters = {
            ...groupsCtx.state.filters,
            defaultGroups: ['onlyNonDefaultGroups'] 
        }
        applyGroupsFilters(groupsCtx)
        
    }, [])

    const cancelRefConfirmationWindow = useRef()
    const onCancelConfirmationWindow = useRef()
    const onAcceptConfirmationWindow = useRef()
    const [isOpenConfirmationWindow, setIsOpenConfirmationWindow] = useState(false)


    return <Box bg={useColorModeValue("warmGray.200", "coolGray.500")} {...safeAreaProps}>

        {/* <Text> Member Add Groups Screen </Text>

        <Text>Initial Groups: {JSON.stringify(selectedMemberInitialGroupsIds?.current)}</Text> */}

        <GroupsFilters 
            showFilteredGroupsDetails={false}
            useMembersContextOnMultipleSelection={true}
            selectedMemberInitialGroupsIds={selectedMemberInitialGroupsIds}
            navigation={navigation}
        />

        <Button isDisabled={selectedMemberInitialGroupsIds.current.length === membersCtx.state.multipleSelectionGroupsIds.length}
            onPress={async () => {

                uiCtx.setDisableCustomAlertButtons(false)

                onCancelConfirmationWindow.current = () => {

                    setIsOpenConfirmationWindow(false)
                }

                onAcceptConfirmationWindow.current = async () => {

                    uiCtx.setDisableCustomAlertButtons(true)

                    // Skip initial groups
                    const res = await addGroupsToMember(
                        membersCtx.state.selectedMemberId, 
                        membersCtx.state.multipleSelectionGroupsIds.filter(
                            groupId => !selectedMemberInitialGroupsIds.current.includes(groupId)
                        )
                    )

                    if (res === true) {

                        selectedMemberInitialGroupsIds.current = membersCtx.state.multipleSelectionGroupsIds
                    }

                    // Last step to updated selectedMemberInitialGroupsIds
                    setIsOpenConfirmationWindow(false)
                }

                // Last step should be use state, to render the updated references
                setIsOpenConfirmationWindow(true)

            }}
        >
            { i18n.t('addSelectedGroups') }
        </Button>

        <Button onPress={() => {

                navigation.dispatch(StackActions.pop())
            }}>
                { i18n.t('back') }
        </Button>

        <Button onPress={() => {
                navigation.dispatch(StackActions.popToTop())
            }}>
                { i18n.t('home') }
        </Button>

        <CustomAlert
            cancelRef={cancelRefConfirmationWindow}
            type="confirmation"
            isOpen={isOpenConfirmationWindow}
            title='confirmationWindow'
            message='msgAreYouWantToAddMemberToSelectedGroups'
            onCancel={onCancelConfirmationWindow.current}
            onAccept={onAcceptConfirmationWindow.current}
        />
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default MemberAddGroupsScreen;