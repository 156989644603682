const capitalizeFirstLetterFromWords = str => {

    const arr = str.split(' ')

    return arr
        .filter(wd => wd.trim().length)
        .map(wd => wd.trim()[0].toUpperCase() + wd.trim().substr(1))    
        .join(' ') + (str.length > 1 && str[str.length - 1] === ' ' ? ' ': '')
}

const capitalizeWord = word => {

    return word.split('').map(letter => letter.toUpperCase()).join('')
}

const nextLexicographicString = startCode => {

    var strLength = startCode.length;

    var strFrontCode = startCode.slice(0, strLength-1);
    var strEndCode = startCode.slice(strLength-1, startCode.length);

    var endCode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    return endCode
}

const formatDate = date => {

    if (date instanceof Date == false) {

        return null
    }

    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) {

        month = '0' + month;
    }
    if (day.length < 2) {

        day = '0' + day;
    }

    return [day, month, year].join('.');
}

export {     
    capitalizeFirstLetterFromWords,
    capitalizeWord,
    nextLexicographicString,
    formatDate
}