import { query, where, getDocs, getDoc, collection, getFirestore, updateDoc, doc, deleteField, getCountFromServer } from "firebase/firestore"

const fetchFirestoreFunctionByName = async enoaFunction => {

    const firestore = getFirestore()

    let q =  query(
        collection(firestore, "static/members/functions")
    )
    q = query(
        q,
        where(
            "name",
            "==",
            enoaFunction
        )
    )

    try {

        const snapshot = await getDocs(q)

        if (snapshot.empty) {
            
            return null
        }

        const result = snapshot.docs.map(doc => {

            return doc.data()
        })

        // enoaFunction names are unique, only one result
        return result[0]

    } catch(error) {

        return null
    }
}

export { fetchFirestoreFunctionByName }