import React, { useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Text, AlertDialog, Button, TextArea } from 'native-base'

import { Context as LanguageContext } from '../context/LanguageContext'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as UIContext } from '../context/UIContext'

import {I18n} from 'i18n-js';
import AppTranslations from '../../src/i18n/i18n'

import Spacer from './Spacer';


const i18n = new I18n({
    ...AppTranslations
  });
  
i18n.enableFallback = true;

const CustomAlert = ({
    type,
    cancelRef,
    isOpen, 
    onCancel, 
    onAccept,
    title, 
    message,
    details
}) => {

    const authCtx = useContext(AuthContext)
    const uiCtx = useContext(UIContext)
    
    const languageCtx = useContext(LanguageContext)
    i18n.locale = languageCtx.state;

    const [showDetails, setShowDetails] = useState(false)

    if (!isOpen) {

        return null
    }

    return <Box>
        <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={ onCancel }>
            <AlertDialog.Content>
                {/* <AlertDialog.CloseButton /> */}
                <AlertDialog.Header>{i18n.t(title)}</AlertDialog.Header>
                <AlertDialog.Body>
                    
                    <Text>{i18n.t(message)}</Text>

                    {
                        type === 'information' && details ? <Box>

                            <Button onPress={() => {

                                setShowDetails(!showDetails)
                            }}>
                                {!showDetails ? i18n.t('showDetails') : i18n.t('hideDetails')}
                            </Button>

                            {
                                showDetails === true  ? 
                                    <TextArea value={details} isDisabled={true}/> :
                                    null
                            }
                        </Box> : null
                    }

                </AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button.Group space={2}>
                        <Button colorScheme="danger" onPress={ onAccept } isDisabled={uiCtx?.state?.disableCustomAlertButtons}>
                            Ok
                        </Button>

                        {type === 'confirmation' ? <Button onPress={ onCancel } isDisabled={uiCtx?.state?.disableCustomAlertButtons}>
                            {i18n.t('cancel')}
                        </Button> : null}
                    </Button.Group>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>
    </Box>
};

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    backgroundImage: {
        flex: 1
    }
})

export default CustomAlert;