import React from 'react';
import { StyleSheet } from 'react-native';
import { Box } from 'native-base'

const Spacer = ({ children }) => {
  return <Box style={styles.spacer}>{children}</Box>;
};

const styles = StyleSheet.create({
  spacer: {
    margin: 5
  }
});

export default Spacer;